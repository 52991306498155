import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchShows, updateShow } from "../lib/data";
import { Show } from "../lib/definitions";
import ProfileCard from "./ProfileCard";
import ShowAdminEdit from "./ShowAdminEdit";
import ShowAdminAdd from "./ShowAdminAdd"; // Import the Add Show component

interface VenueAdminShowsProps {
  selectedVenue: number;
  user_id: number;
}

const VenueAdminShows: React.FC<VenueAdminShowsProps> = ({ selectedVenue, user_id }) => {
  const queryClient = useQueryClient();
  
  const [showType, setShowType] = useState<'Future' | 'Past'>('Future'); // State for future or past shows

  // Fetch shows based on the selected showType
  const { data: shows, error: showsError, isLoading: showsLoading } = useQuery<Show[], Error>({
    queryKey: ['shows', selectedVenue, showType], // Include showType in the queryKey
    queryFn: () => fetchShows(null, null, null, null, null, showType, null, null, null, selectedVenue),
    enabled: !!selectedVenue,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedShowId, setSelectedShowId] = useState<number | null>(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // State to manage Add Show modal

  // Handle edit modal opening
  const handleEditShow = (show_id: number, user_id: number) => {
    setSelectedShowId(show_id);
    setIsModalOpen(true);
  };

  // Handle add show modal opening
  const handleAddShow = () => {
    setIsAddModalOpen(true);
  };

  // Invalidate queries and close the modal after a successful edit or add
  const handleModalClose = () => {
    queryClient.invalidateQueries({ queryKey: ['shows', selectedVenue, showType] }); // Invalidate the shows query
    setIsModalOpen(false); // Close the edit modal
    setIsAddModalOpen(false); // Close the add modal
  };

  // Handle inactivating a show
  const handleInactivateShow = async (show_id: number) => {
    await updateShow({ show_id, active: 0 }); // Send 0 for inactive
    queryClient.invalidateQueries({ queryKey: ['shows', selectedVenue, showType] }); // Refresh the shows list after inactivation
  };

  if (showsLoading) {
    return <p>Loading shows...</p>;
  }

  if (showsError) {
    return <p>Error loading shows: {showsError.message}</p>;
  }

  return (
    <>
      <header className="flex z-0 items-center pb-4 w-full text-zinc-950">
        <button 
          className="gap-2 self-stretch p-2 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid border-zinc-700"
          onClick={handleAddShow} // Trigger Add Show modal
        >
          Add A Show
        </button>
      </header>

      {/* Toggle between Upcoming and Past shows */}
      <nav className="flex gap-6 justify-center items-start mt-2 mb-6 w-full text-sm font-medium leading-6 text-center whitespace-nowrap text-zinc-950">
        <button
          className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${showType === 'Future' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
          onClick={() => setShowType('Future')}
        >
          Upcoming Shows
        </button>
        <button
          className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${showType === 'Past' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
          onClick={() => setShowType('Past')}
        >
          Past Shows
        </button>
      </nav>

      <div className="flex relative flex-col w-full">
        {shows && shows.length > 0 ? (
          shows.map(show => (
            show.show_id ? (  // Ensure show_id is defined
              <ProfileCard
                key={show.show_id}
                name={show.name}
                date={show.date_start}
                time={show.time_start}
                imageSrc={show.image_thumb && show.image_thumb.trim() !== '' ? show.image_thumb : 'default_show.webp'} // Use default image if image_thumb is blank
                show_id={show.show_id} // Pass show_id
                user_id={user_id} // Pass user_id
                onEdit={handleEditShow} // Pass edit handler
                deleteShow={handleInactivateShow} // Pass delete handler
              />
            ) : null  // Skip shows without a valid show_id
          ))
        ) : (
          <>
            <div className="flex z-0 mt-2 w-full rounded-md border-2 border-solid bg-zinc-300 bg-opacity-0 border-neutral-200 min-h-[212px]" />
            <p className="absolute left-2/4 z-0 text-xs font-semibold text-center -translate-x-2/4 bottom-[99px] h-[15px] text-neutral-400 translate-y-[0%] w-[321px]">
              No {showType === 'Future' ? 'upcoming' : 'past'} shows listed yet
            </p>
          </>
        )}
      </div>

      {/* Show the edit modal when isModalOpen is true */}
      {isModalOpen && selectedShowId !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md max-h-[80vh] w-full max-w-lg overflow-y-auto">
            <ShowAdminEdit
              show_id={selectedShowId}
              user_id={user_id}
              onClose={handleModalClose}  // Refresh and close modal handler
            />
          </div>
        </div>
      )}

      {/* Show the Add Show modal when isAddModalOpen is true */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-md max-h-[80vh] w-full max-w-lg overflow-y-auto">
            <ShowAdminAdd
              user_id={user_id}  // Pass the user ID to the Add Show component
              venue_id={selectedVenue}
              onClose={handleModalClose}  // Refresh and close modal handler
            />
          </div>
        </div>
      )}
    </>
  );
};

export default VenueAdminShows;