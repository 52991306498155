import React from 'react';

const TermsOfService: React.FC = () => {
  return (
    <div className="terms-container mx-auto max-w-4xl p-8 bg-white rounded-lg shadow-md">
      <h1 className="text-4xl font-bold mb-6 text-center text-black">Terms of Service</h1>
      <p className="text-lg mb-6 text-gray-600">Effective Date: 10/01/2024</p>

      <h2 className="text-2xl font-semibold mb-4 text-black">Welcome to myVibe.live!</h2>
      <p className="mb-4 text-gray-700">
        By accessing or using our platform, you agree to the following Terms of Service (ToS).
        If you do not agree to these terms, you may not use the platform.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">1. Overview</h2>
      <p className="mb-4 text-gray-700">
        myVibe.live is a platform designed to connect venues, artists, and fans. The services we provide 
        include venue and artist management tools, show promotions, and event discovery features.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">2. User Responsibilities</h2>
      <p className="mb-4 text-gray-700">
        Users must ensure that any content they post complies with local and national laws. Users may not 
        upload harmful, illegal, or copyrighted content without proper permissions. myVibe.live is not 
        liable for any user-generated content posted on the platform.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">3. Subscription Plans</h2>
      <p className="mb-4 text-gray-700">
        Venues and artists can subscribe to either free or premium plans. Subscription fees are charged 
        monthly and are non-refundable after the first 30 days. myVibe.live reserves the right to change 
        pricing or features and will notify users in advance.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">4. Account Termination</h2>
      <p className="mb-4 text-gray-700">
        Users who violate the terms of service may have their accounts suspended or terminated at our 
        discretion.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">5. Limitations of Liability</h2>
      <p className="mb-4 text-gray-700">
        myVibe.live is not responsible for any indirect, incidental, or punitive damages arising from the 
        use of our service.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">6. Changes to the Terms</h2>
      <p className="mb-4 text-gray-700">
        We reserve the right to update these terms at any time. Users will be notified of significant changes 
        via email or an in-app notification.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">Contact Us</h2>
      <p className="text-gray-700">For more information, please contact us at admin@myvibelive.com.</p>
    </div>
  );
};

export default TermsOfService;