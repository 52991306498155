import React from 'react';

interface ArtistItemSearchProps {
  name: string;
  genre: string;
  image: string;
  onClick: () => void;
}

const ArtistItemSearch: React.FC<ArtistItemSearchProps> = ({ name, genre, image, onClick }) => {
  const defaultImage = "default_thumb.webp";

  return (
    <div className="flex items-center justify-between mt-4 w-full">
      <div className="flex gap-2 items-center" onClick={onClick}>
        <img
          src={image || defaultImage}
          className="object-contain w-[42px] h-[42px] rounded-full"
          alt={`${name} artist avatar`}
        />
        <div className="text-base font-medium">{name}</div>
        <div className="text-sm text-neutral-400">{genre}</div>
      </div>
    </div>
  );
};

export default ArtistItemSearch;