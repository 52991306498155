import React, { useEffect, useState } from "react";
import { fetchUnlinkedArtists, inactivateArtist } from "../lib/data";
import { Artist } from "../lib/definitions";

const UnlinkedArtists: React.FC = () => {
  const [artists, setArtists] = useState<Artist[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchArtists = async () => {
      setLoading(true);
      try {
        const data = await fetchUnlinkedArtists();
        setArtists(data);
      } catch (error) {
        console.error("Failed to fetch unlinked artists", error);
        setError("Failed to fetch unlinked artists. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchArtists();
  }, []);

  const handleInactivate = async (artistId: number) => {
    try {
      await inactivateArtist(artistId);
      setArtists((prev) => prev.filter((artist) => artist.artist_id !== artistId));
    } catch (error) {
      console.error("Failed to inactivate artist", error);
      alert("Failed to inactivate artist. Please try again.");
    }
  };

  if (loading) return <p>Loading artists...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex flex-col">
      <h2 className="text-lg font-bold">Unlinked Artists</h2>
      {artists.length === 0 ? (
        <p>No unlinked artists found.</p>
      ) : (
        <ul className="mt-4">
          {artists.map((artist) => (
            <li
              key={artist.artist_id}
              className="flex justify-between items-center p-2 border-b"
            >
              <span>{artist.name}</span>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={() => artist.artist_id && handleInactivate(artist.artist_id)}
              >
                Inactivate
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UnlinkedArtists;