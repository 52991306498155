import React from 'react';

const VenueSubscription = () => {
  return (
    <section className="venue-subscription text-center px-4 py-8 max-w-xl mx-auto">
      {/* Logo with tagline */}
      <img 
        src="/myvibelogowithtagline.png" 
        alt="myVibe.live with Tagline" 
        className="w-full max-w-md mx-auto mb-6" 
      />

      <h1 className="text-3xl font-bold mb-6">Maximize Your Reach, Fill Every Seat!</h1>
      <p className="mb-6 text-lg leading-relaxed">
        We're addressing a common challenge in Northeast Wisconsin: finding live music events easily. 
        Our platform, <strong>myvibe.live</strong>, provides a seamless solution for discovering live shows quickly. 
        Our site helps attendees find shows and leverages tools such as sharing, notifications, directions, and social links 
        to maximize your venue's reach. We're committed to getting more people through your doors.
      </p>

      <h2 className="text-2xl font-semibold mb-4">Venue Subscription Offerings</h2>
      <div className="overflow-x-auto">
        <table className="table-auto border-collapse border border-gray-300 w-full mb-6">
          <thead className="bg-gradient-to-r from-blue-300 via-purple-300 to-pink-300">
            <tr>
              <th className="border border-gray-300 px-4 py-2">Feature</th>
              <th className="border border-gray-300 px-4 py-2">Opener</th>
              <th className="border border-gray-300 px-4 py-2">Headliner</th>
              <th className="border border-gray-300 px-4 py-2">Rockstar</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Basic show listings</td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Additional images</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Social/Site/Merch links</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Basic Analytics</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Custom Posters</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Advanced Analytics</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Featured Placement and Highlighting</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">Custom Coasters</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
            <tr>
              <td className="border border-gray-300 px-4 py-2">VIP Support</td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2"></td>
              <td className="border border-gray-300 px-4 py-2">✔</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default VenueSubscription;