import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import { fetchVenueReports } from "../lib/data"; // API call for fetching reports
import { ReportData } from "../lib/definitions"; // Define your ReportData type

Chart.register(...registerables);

interface VenueAdminReportsProps {
  selectedVenue: number;
}

const VenueAdminReports: React.FC<VenueAdminReportsProps> = ({ selectedVenue }) => {
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      try {
        const response = await fetchVenueReports(selectedVenue);
        setReportData(response);
      } catch (error) {
        console.error("Failed to fetch reports", error);
        setError("Failed to fetch reports. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (selectedVenue) {
      fetchReports();
    }
  }, [selectedVenue]);

  useEffect(() => {
    if (reportData) {
      // Initialize charts for each metric
      createChart("pageVisitsChart", "Page Visits", reportData.pageVisits);
      createChart("pageSharesChart", "Page Shares", reportData.pageShares);
      createChart("pageClicksChart", "Page Clicks", reportData.pageClicks);
      createChart("showSharesChart", "Show Shares", reportData.showShares);
      createChart("showClicksChart", "Show Clicks", reportData.showClicks);
    }
  }, [reportData]);

  // A map to track chart instances
const chartInstances: { [key: string]: Chart | null } = {};

const createChart = (
  canvasId: string,
  label: string,
  data: { rolling7Days: number[]; previous7Days: number[] }
) => {
  const ctx = (document.getElementById(canvasId) as HTMLCanvasElement)?.getContext("2d");

  if (!ctx) {
    console.error(`Canvas with ID '${canvasId}' not found.`);
    return;
  }

  // Destroy any existing chart on the same canvas
  if (chartInstances[canvasId]) {
    chartInstances[canvasId]?.destroy();
  }

  // Create a new Chart instance and store it
  chartInstances[canvasId] = new Chart(ctx, {
    type: "line",
    data: {
      labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5", "Day 6", "Day 7"],
      datasets: [
        {
          label: "Last 7 Days",
          data: data.rolling7Days,
          borderColor: "blue",
          borderWidth: 2,
          fill: false,
        },
        {
          label: "Previous 7 Days",
          data: data.previous7Days,
          borderColor: "lightblue",
          borderWidth: 2,
          borderDash: [5, 5],
          fill: false,
        },
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
        title: {
          display: true,
          text: label,
        },
      },
      scales: {
        x: {
          title: {
            display: true,
            text: "Days",
          },
        },
        y: {
          title: {
            display: true,
            text: "Counts",
          },
          beginAtZero: true,
        },
      },
    },
  });
};

  if (loading) {
    return <p>Loading reports...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!reportData) {
    return <p>No reports available for this venue.</p>;
  }

  return (
    <div className="flex flex-col">
      <div className="mt-4">
        <canvas id="pageVisitsChart" className="my-4" height="100"></canvas>
        <canvas id="pageSharesChart" className="my-4" height="100"></canvas>
        <canvas id="pageClicksChart" className="my-4" height="100"></canvas>
        <canvas id="showSharesChart" className="my-4" height="100"></canvas>
        <canvas id="showClicksChart" className="my-4" height="100"></canvas>
      </div>
    </div>
  );
};

export default VenueAdminReports;