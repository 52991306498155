import React, { useState } from 'react';

interface ArtistProfileCardProps {
  name?: string;
  date?: string;
  time?: string;
  imageSrc?: string;
}

const ArtistProfileCard: React.FC<ArtistProfileCardProps> = ({
  name = "Unknown Show",
  date = "TBA",
  time = "TBA",
  imageSrc = "default_show.webp",
}) => {

  const formattedDate = date !== "TBA" ? formatDate(date) : date;
  const formattedTime = time !== "TBA" ? formatTime(time) : time;

  return (
    <>
      <article className="flex flex-col justify-center self-stretch px-4 py-6 mb-4 rounded-md bg-zinc-950 max-w-[398px]">
        <header className="flex gap-4 items-center w-full">
          <img
            loading="lazy"
            src={imageSrc}
            className="object-contain shrink-0 self-stretch my-auto w-20 rounded-md aspect-square"
            alt={name}
          />
          <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h2 className="flex-1 shrink gap-2 self-stretch w-full text-3xl font-bold tracking-tighter leading-none text-white">
                {name}
              </h2>
              <div className="flex gap-6 items-start self-start mt-2 text-base font-medium text-zinc-400">
                <time>{formattedDate}</time>
                <time>{formattedTime}</time>
              </div>
            </div>
          </div>
        </header>
      </article>
    </>
  );
};

// Helper functions for formatting
function formatDate(dateString: string): string {
  const [year, month, day] = dateString.split('T')[0].split('-');
  const monthNames = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];
  return `${monthNames[parseInt(month) - 1]} ${day}, ${year}`;
}

function formatTime(timeString: string): string {
  if (!timeString) {
    return ''; // Handle undefined or invalid time
  }
  const timePart = timeString.split('T')[1].split('Z')[0];
  let [hour, minute] = timePart.split(':');
  let period = 'AM';
  let hourNumber = parseInt(hour);

  if (hourNumber >= 12) {
    period = 'PM';
    if (hourNumber > 12) {
      hourNumber -= 12;
    }
  }

  if (hourNumber === 0) {
    hourNumber = 12;
  }

  return `${hourNumber.toString()}:${minute} ${period}`;
}

export default ArtistProfileCard;