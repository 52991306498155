import React, { useState, useEffect } from "react";
import InputField from "./ui/InputField";
import { fetchVenueById, fetchVenueByKey, updateVenue, uploadImage } from "../lib/data"; 
import { Venue } from "../lib/definitions";
import Notification from "./ui/Notification";

interface VenueAdminProfileProps {
  selectedVenue: number;
  user_id: number; 
}

const VenueAdminProfile: React.FC<VenueAdminProfileProps> = ({ selectedVenue, user_id }) => {
  const [venue, setVenue] = useState<Venue | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false); // New state to track 
  const [initialVenue, setInitialVenue] = useState<Venue | null>(null);

  const [venueName, setVenueName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("US");
  const [bio, setBio] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYouTube] = useState("");
  const [tiktok, setTikTok] = useState("");
  const [twitter, setTwitter] = useState("");
  const [merch, setMerch] = useState("");
  const [officialLink, setOfficialLink] = useState("");
  const [coverPhotoUrl, setCoverPhotoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [notification, setNotificationState] = useState<{ message: string, type: 'success' | 'error' } | null>(null);

  const triggerNotification = (message: string, type: 'success' | 'error') => {
    setNotificationState({ message, type });
    // Automatically hide the notification after 3 seconds
    setTimeout(() => {
      setNotificationState(null);
    }, 3000);
  };

  useEffect(() => {
    const fetchVenue = async () => {
      if (selectedVenue) {
        setIsLoading(true);
        try {
          const venueData = await fetchVenueById(selectedVenue);
          setVenue(venueData);
          setInitialVenue(venueData);

          // Populate form fields when venue data is fetched
          setVenueName(venueData.name || "");
          setStreet(venueData.addr_street || "");
          setCity(venueData.addr_city || "");
          setState(venueData.addr_state || "");
          setZip(venueData.addr_zip || "");
          setCountry(venueData.addr_country || "US");
          setBio(venueData.description || "");
          setFacebook(venueData.fb_link || "");
          setInstagram(venueData.ig_link || "");
          setYouTube(venueData.yt_link || "");
          setTikTok(venueData.tiktok_link || "");
          setTwitter(venueData.twitter_link || "");
          setMerch(venueData.merch_link || "");
          setOfficialLink(venueData.official_link || "");
          setCoverPhotoUrl(venueData.image_full || "");
          setThumbnailUrl(venueData.image_thumb || "");
        } catch (error) {
          console.error("Failed to fetch venue data", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchVenue();
  }, [selectedVenue]);

  const defaultImageKey = venue?.venue_key || new Date().toISOString().replace(/[-:.]/g, '');
 
  const handleImageUpload = async (file: File, uploadPreset: string, imageKey: string) => {
    try {
      setIsUploading(true);
      const imageUrl = await uploadImage(file, imageKey, uploadPreset);
  
      if (uploadPreset === 'cover_photo') {
        setCoverPhotoUrl(imageUrl);
      } else if (uploadPreset === 'thumbnail_photo') {
        setThumbnailUrl(imageUrl);
      }
  
      triggerNotification("Image uploaded successfully!", 'success');
    } catch (error) {
      console.error("Failed to upload image", error);
      triggerNotification("Failed to upload image.", 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const handleSaveChanges = async () => {
    setIsSaving(true);  // Disable button by setting isSaving to true
    try {
      const updatedVenue = {
        venue_id: venue?.venue_id || selectedVenue, // Use venue_id or selectedVenue if venue_id is not available
        name: venueName,
        addr_street: street,
        addr_city: city,
        addr_state: state,
        addr_zip: zip,
        addr_country: country,
        description: bio,
        fb_link: facebook,
        ig_link: instagram,
        yt_link: youtube,
        tiktok_link: tiktok,
        twitter_link: twitter,
        merch_link: merch,
        official_link: officialLink,
        image_full: coverPhotoUrl,
        image_thumb: thumbnailUrl,
        active: 1,
        changed_by: user_id,
      };
  
      await updateVenue(updatedVenue);
      triggerNotification("Venue updated successfully!", 'success');
    } catch (error) {
      console.error("Failed to update venue", error);
      triggerNotification("Failed to update venue.", 'error');
    } finally {
      setIsSaving(false); 
    }
  };

  const handleCancel = () => {
    if (initialVenue) {
      setVenueName(initialVenue.name || "");
      setStreet(initialVenue.addr_street || "");
      setCity(initialVenue.addr_city || "");
      setState(initialVenue.addr_state || "");
      setZip(initialVenue.addr_zip || "");
      setCountry(initialVenue.addr_country || "");
      setBio(initialVenue.description || "");
      setFacebook(initialVenue.fb_link || "");
      setInstagram(initialVenue.ig_link || "");
      setYouTube(initialVenue.yt_link || "");
      setTikTok(initialVenue.tiktok_link || "");
      setTwitter(initialVenue.twitter_link || "");
      setMerch(initialVenue.merch_link || "");
      setOfficialLink(initialVenue.official_link || "");
      setCoverPhotoUrl(initialVenue.image_full || "");
      setThumbnailUrl(initialVenue.image_thumb || "");
    }
  };

  if (isLoading) {
    return <p>Loading venue data...</p>;
  }

  if (!venue) {
    return <p>Select a venue to view its details</p>;
  }

  return (
    <>
      <section className="flex flex-col w-full">
        <h2 className="flex-1 shrink gap-2 pt-2.5 w-full text-lg font-bold tracking-tight min-h-[56px] text-zinc-950">
          About You
        </h2>
        <InputField
          label="Venue Name*"
          value={venueName}
          onChange={(e) => setVenueName(e.target.value)}
          readOnly // This makes the input read-only
        />
        <InputField
          label="Street*"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          readOnly // This makes the input read-only
        />
        <div className="flex gap-2 items-start mt-2 w-full text-xs font-semibold whitespace-nowrap">
          <InputField
            label="City*"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            containerClassName="flex flex-col min-w-[240px] w-[282px]"
            readOnly // This makes the input read-only
          />
          <InputField
            label="State*"
            value={state}
            onChange={(e) => setState(e.target.value)}
            containerClassName="flex flex-col flex-1 shrink basis-0"
            readOnly // This makes the input read-only
          />
          <InputField
            label="Zip*"
            value={zip}
            onChange={(e) => setZip(e.target.value)}
            containerClassName="flex flex-col w-[170px]"
            readOnly // This makes the input read-only
          />
        </div>
      </section>

      <section className="flex flex-col mt-6 w-full">
        <div className="flex flex-col mt-2 w-full">
          <label htmlFor="venueBio" className="text-xs font-semibold text-zinc-950">
            Venue Description
          </label>
          <textarea
            id="venueBio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            className="mt-2 p-2 min-h-[250px] text-base font-medium bg-white rounded-md border border-solid border-zinc-700"
            placeholder="Write a brief description of the venue"
          />
        </div>

        <div className="flex relative flex-col w-full">
        <div className="flex z-0 items-center mt-4 w-full text-zinc-950">
          <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Cover Photo</h3>
        </div>
        <p className="z-0 mt-2 text-xs font-semibold text-neutral-400">
          We recommend an image of at least 1920x1080 for your cover photo
        </p>
        <div className="flex z-0 mt-2 w-full rounded-md border-2 border-solid bg-zinc-300 bg-opacity-0 border-neutral-200 min-h-[212px]">
        <img
          loading="lazy"
          src={coverPhotoUrl || "upload_default_cover.png"}
          className=""
          alt="Event Photo"
        />
        </div>
        {/* Hidden input for selecting the cover photo */}
        <input
          type="file"
          accept="image/*"
          className="hidden"
          id="coverPhotoInput"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target.files[0];
              const venueId = "yourVenueIdHere"; // You need to dynamically pass your venue ID
              handleImageUpload(file, 'cover_photo', defaultImageKey); // Specify 'cover' as the image type
            }
          }}
        />
        
        {/* Button to trigger file selection for cover photo */}
        <button
          className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
          onClick={() => document.getElementById('coverPhotoInput')?.click()}
        >
          Select Cover Photo
        </button>

        {isUploading && <p className="mt-2 text-sm text-indigo-500">Uploading cover photo...</p>}
      </div>

      <div className="flex flex-col mt-2 w-full">
        <div className="flex items-center pb-2 w-full text-zinc-950">
          <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Thumbnail</h3>
        </div>
        <div className="flex gap-2 items-center mt-2 w-full text-xs font-semibold text-neutral-400">
          <img
            loading="lazy"
            src={thumbnailUrl || "upload_default_thumb.png"}
            className="object-contain shrink-0 self-stretch my-auto w-20 aspect-square rounded-[50%]"
            alt="Event Thumbnail"
          />
          <p className="self-stretch my-auto w-[242px]">We recommend an image of at least 512x512 for your venue thumbnail</p>
        </div>

        {/* Hidden input for selecting the thumbnail */}
        <input
          type="file"
          accept="image/*"
          className="hidden"
          id="thumbnailInput"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target.files[0];
              const venueId = "yourVenueIdHere"; // You need to dynamically pass your venue ID
              handleImageUpload(file, 'thumbnail_photo', defaultImageKey); 
            }
          }}
        />

        {/* Button to trigger file selection for thumbnail */}
        <button
          className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
          onClick={() => document.getElementById('thumbnailInput')?.click()}
        >
          Select Thumbnail
        </button>

        {isUploading && <p className="mt-2 text-sm text-indigo-500">Uploading thumbnail...</p>}
      </div>
      </section>

      <section className="mt-2 w-full">
        <h2 className="flex-1 shrink self-stretch pb-4 mt-2 w-full text-lg font-bold tracking-tight text-zinc-950">Social Links</h2>
        <InputField label='Facebook' value={facebook} onChange={(e) => setFacebook(e.target.value)} />
        <InputField label='Instagram' value={instagram} onChange={(e) => setInstagram(e.target.value)} />
        <InputField label='TikTok' value={tiktok} onChange={(e) => setTikTok(e.target.value)} />
        <InputField label='YouTube' value={youtube} onChange={(e) => setYouTube(e.target.value)} />
        <InputField label='Twitter' value={twitter} onChange={(e) => setTwitter(e.target.value)} />
      </section>

      <section className="flex flex-col mt-6 w-full text-xs font-semibold">
        <h2 className="flex-1 shrink gap-2 pt-2.5 w-full text-lg font-bold tracking-tight min-h-[56px] text-zinc-950">Additional Links</h2>
        <InputField label='Official' value={officialLink} onChange={(e) => setOfficialLink(e.target.value)} />
        <InputField label='Merchandise' value={merch} onChange={(e) => setMerch(e.target.value)} />
      </section>

      <div className="flex gap-10 justify-between items-center mt-6 w-full text-sm font-medium leading-6 text-center">
        <button
          className="gap-2 self-stretch px-6 py-2 my-auto rounded-md border border-solid border-zinc-700 text-zinc-950"
          onClick={handleCancel}
        >
          Cancel Changes
        </button>
        <button
          onClick={handleSaveChanges}
          className={`gap-2 self-stretch px-6 py-2 my-auto text-white rounded-md border border-solid bg-zinc-950 border-zinc-700 ${
            isSaving ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isSaving}  // Disable button while saving
        >
          {isSaving ? "Saving..." : "Save Changes"}
        </button>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotificationState(null)}
        />
      )}
    </>
  );
};

export default VenueAdminProfile;