import React, { useState } from "react";

interface SuperAdminNavProps {
  activeTab: string;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const SuperAdminNav: React.FC<SuperAdminNavProps> = ({ activeTab, setActiveTab }) => {
  return (
    <nav className="flex gap-6 justify-center items-start mt-6 w-full text-sm font-medium leading-6 text-center whitespace-nowrap text-zinc-950">
      <button
        className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${activeTab === 'Profile' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
        onClick={() => setActiveTab('Profile')}
      >
        Add Venue
      </button>
      <button
        className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${activeTab === 'Shows' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
        onClick={() => setActiveTab('Shows')}
      >
        Artist Match
      </button>
    </nav>
  );
};

export default SuperAdminNav;