import React, { useEffect, useState } from 'react';
import VenueAdmin from './VenueAdmin';  // Import VenueAdmin component

// Define the shape of the user object
interface User {
  user_id: number;
  user_type: 'artist' | 'venue';  // Specify valid values
}

const Dashboard: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);  // Explicitly type the user state
  const [loading, setLoading] = useState(true);

  // Get the token from the query string or local storage
  const token = new URLSearchParams(window.location.search).get('token') || localStorage.getItem('token');

  useEffect(() => {
    if (token) {
      // Store token in local storage or send it with a request to verify
      localStorage.setItem('token', token);
      
      // Fetch the user details from your API
      fetch('/api/auth/me', {
        method: 'GET',
        headers: { 'Authorization': `Bearer ${token}` }
      })
      .then(res => res.json())
      .then((data: User) => {  // Cast the response to the User type
        setUser(data);  // Set user data
        setLoading(false);
      })
      .catch(err => {
        console.error('Error fetching user data', err);
        setLoading(false);
      });
    } else {
      // Handle case where there is no token (redirect to login)
      window.location.href = '/login';
    }
  }, [token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Error: No user data available</div>;
  }

  return (
    <div>
        <VenueAdmin user_id={user.user_id} />
    </div>
  );
};

export default Dashboard;