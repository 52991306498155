import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Login = ({ defaultAccessToken = '', defaultMessage = '' }) => {
  const location = useLocation();
  const [accessToken, setAccessToken] = useState(defaultAccessToken);
  const [message, setMessage] = useState(defaultMessage); 
  const { loginWithRedirect, logout, getAccessTokenSilently, error } = useAuth0();

  // Fetch access_token and message from the URL query string
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tokenFromUrl = queryParams.get('access_token');
    const messageFromUrl = queryParams.get('message');

    if (tokenFromUrl) {
      setAccessToken(tokenFromUrl); // Store access_token from the URL
    }

    if (messageFromUrl) {
      setMessage(decodeURIComponent(messageFromUrl)); 
    }
  }, [location]);

  // Handle OAuth login with the access_token
  const handleOAuthLogin = (provider) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    window.location.href = `${apiBaseUrl}/api/auth/${provider}?access_token=${accessToken}`;
  };


  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-800 to-black text-white">
      {/* Logo */}
      <div className="mb-6">
        <img src="/myvibelogowithtagline.png" alt="Logo" className="w-132 h-32 mx-auto" />
      </div>

      {/* Display any message from the query params */}
      {message && (
        <div className="mb-4 text-lg text-red-500">
          {message}
        </div>
      )}

      {/* Main title */}
      <h1 className="text-5xl font-bold mb-6 text-center">Manage Your Profile</h1>

      {/* Access Token Input */}
      <div className="mb-4 mt-2">
        <input
          type="text"
          value={accessToken}
          onChange={(e) => setAccessToken(e.target.value)}
          placeholder="Enter your access code"
          className="w-full py-3 px-4 mb-4 text-gray-700 font-semibold rounded-md"
        />
      </div>

      {/* Social login buttons */}
      <div className="w-full max-w-xs">
        <button
          onClick={() => handleOAuthLogin('auth0')}
          className="w-full py-3 px-4 mb-4 bg-blue-400 text-white font-semibold rounded-md hover:bg-blue-500"
        >
          <span className="text-lg">Login</span>
        </button>
      </div>
      <div>Need an access code? Message us on our social sites to get one!</div>
      <div className="flex gap-4 mt-4">
        <a
        href="https://www.facebook.com/profile.php?id=61565715673369"
        className="flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        >
        <img
            src="icon-facebook-color.png" 
            alt="Facebook"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
        />
        </a>
        <a
        href="https://www.instagram.com/myvibelive/"
        className="flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        >
        <img
            src="icon-instagram-color.jpg" 
            alt="Instagram"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
        />
        </a>  
        <a
        href="https://www.tiktok.com/@myvibe.live"
        className="flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        >
        <img
            src="icon-tiktok-color.png" 
            alt="TikTok"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
        />
        </a>               
      </div>
      
      {/* Terms and Conditions */}
      <p className="mt-6 text-sm text-gray-400">
        By continuing you agree to our <a href="/termsofservice" className="underline">Terms & Service</a>
      </p>
    </div>
  );
};

export default Login;