import React, { useEffect, useRef, useState } from "react";

declare global {
  interface Window {
    google: any;
  }
}

interface AutoCompleteProps {
  value: string;
  setLocation: (city: string, state: string, zip?: string) => void; // ✅ Now supports ZIP codes
  setValue: React.Dispatch<React.SetStateAction<string>>; // Ensures state sync
}

const AutoComplete: React.FC<AutoCompleteProps> = ({ value, setLocation, setValue }) => {
  const autocompleteRef = useRef<HTMLInputElement | null>(null);
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value); // Sync with external state
  }, [value]);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
    if (!apiKey) {
      console.error("Google API key is missing. Set REACT_APP_GOOGLE_GEOLOCATION_KEY in your .env file.");
      return;
    }

    // Load Google Places API only once
    if (!document.querySelector("#google-maps-script")) {
      const scriptTag = document.createElement("script");
      scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
      scriptTag.async = true;
      scriptTag.defer = true;
      scriptTag.id = "google-maps-script";
      scriptTag.onload = initializeAutocomplete;
      document.body.appendChild(scriptTag);
    } else {
      initializeAutocomplete();
    }
  }, []);

  const initializeAutocomplete = () => {
    if (!window.google || !autocompleteRef.current) return;
  
    const autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteRef.current,
      {
        types: ["geocode"], // ✅ Allow any geographic location (city, state, zip)
        componentRestrictions: { country: "us" }, // Restrict to US
      }
    );
  
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (!place.address_components) return;
  
      const city = place.address_components.find((comp) =>
        comp.types.includes("locality")
      )?.long_name || "";

      const state = place.address_components.find((comp) =>
        comp.types.includes("administrative_area_level_1")
      )?.short_name || "";

      const zip = place.address_components.find((comp) =>
        comp.types.includes("postal_code")
      )?.long_name || ""; // ✅ Extract ZIP code

      if (city || state || zip) {
        setLocation(city, state, zip);
        setInputValue(`${city ? `${city}, ` : ""}${state}${zip ? ` ${zip}` : ""}`);
      }
    });
  };

  return (
    <input
      ref={autocompleteRef}
      type="text"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder="Enter a city, state, or ZIP code"
      className="w-full px-4 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-500 focus:border-indigo-500 shadow-sm"
    />
  );
};

export default AutoComplete;