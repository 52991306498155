import React, { useEffect, useState } from "react";
import { fetchVenueReports } from "../lib/data"; // API call for fetching reports
import { ReportData } from "../lib/definitions"; // Define your ReportData type

interface VenueAdminReportsProps {
  selectedVenue: number;
}

const VenueAdminReports: React.FC<VenueAdminReportsProps> = ({ selectedVenue }) => {
  const [reportData, setReportData] = useState<ReportData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      try {
        const response = await fetchVenueReports(selectedVenue);
        setReportData(response);
      } catch (error) {
        console.error("Failed to fetch reports", error);
        setError("Failed to fetch reports. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (selectedVenue) {
      fetchReports();
    }
  }, [selectedVenue]);

  if (loading) {
    return <p>Loading reports...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!reportData) {
    return <p>No reports available for this venue.</p>;
  }

  return (
    <div className="flex flex-col">
      <h2 className="text-lg font-bold">Analytics</h2>
      <div className="mt-4">
        <p><strong>Total Visits:</strong> {reportData.totalVisits}</p>
        <p><strong>Show Clicks:</strong> {reportData.showClicks}</p>
        <p><strong>Google Maps Clicks:</strong> {reportData.googleMapsClicks}</p>
        <p><strong>Social Media Clicks:</strong> {reportData.socialMediaClicks}</p>
      </div>
    </div>
  );
};

export default VenueAdminReports;