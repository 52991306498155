import React, { useReducer, useState, useEffect, useMemo } from "react";
import { FaChevronDown } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import {
  fetchShows,
  fetchItinerary,
  fetchGenres,
  fetchSpotifyPlaylists,
  fetchPlaylistArtists,
  removeFromItinerary,
  addToItinerary,
  resetItinerary
} from "../lib/data";
import Itinerary from "../components/Itinerary";
import { Popover, PopoverTrigger, PopoverContent } from "../components/ui/popover";
import { Genre } from "../lib/definitions";
import { jwtDecode } from "jwt-decode";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import FestivalShowCard from "../components/FestivalShowCard";
  
interface ButtonProps {
  variant?: "outline" | "underline";
  children: React.ReactNode;
  onClick?: () => void;
  className?: string;
}

// Reusable Button Component (Now Inside NavigationButtons)
const Button: React.FC<ButtonProps> = ({
  variant = "outline",
  children,
  onClick,
  className = "",
}) => {
  const baseStyles =
    "text-sm font-medium leading-6 text-zinc-950 p-2 rounded-md overflow-hidden gap-2 self-stretch";

  const variantStyles = {
    outline: "border border-solid border-[#383340]",
    underline:
      "underline decoration-auto decoration-solid underline-offset-auto",
  };

  return (
    <button
      onClick={onClick}
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
    >
      {children}
    </button>
  );
};

const getFestivalDates = (start: string, end: string): string[] => {
  const dates: string[] = [];
  let currentDate = new Date(start + "T00:00:00"); // Keep it in local time
  const endDate = new Date(end + "T00:00:00");

  while (currentDate <= endDate) {
    // ✅ Format correctly to YYYY-MM-DD to avoid UTC conversion issues
    const formattedDate = currentDate.toISOString().split("T")[0]; 
    dates.push(formattedDate);

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

interface CustomJwtPayload {
  email: string;
  vibe: string;
}

// 🎯 **Define State Type**
interface State {
  selectedLocation: string | null;
  selectedVenue: number | null;
  selectedDate: string | null;
  selectedGenre: string | null;
  selectedGenreId: number | null;
  selectedPlaylistId: string | null;
  selectedPlaylistName: string | null;
  selectedVibe: string;
  selectedArtists: number[];
  selectedVenues: number[];
  selectedShows: string[]; 
  selectedArtistsDetails: any[]; // Stores detailed artist info
  selectedVenuesDetails: any[]; // Stores detailed venue info  
  isGenrePopoverOpen: boolean;
  isPageLoading: boolean;
  isSelection: boolean;
  email: string | null;
  genres: Genre[];
  playlists: { id: string; name: string }[];
  fetchedArtists: { id: string }[];
  allShowsWithArtists: any[];  // ✅ Add this property
  allShowsWithVenues: any[];   // ✅ Add this property
  allShowsWithArtistsVenues: any[];
}

// 🎯 **Initial State**
const initialState: State = {
  selectedLocation: null,
  selectedVenue: null,  
  selectedDate: null,
  selectedGenre: null,
  selectedGenreId: null,
  selectedPlaylistId: null,
  selectedPlaylistName: null,
  selectedVibe: "vibe",
  selectedArtists: [],
  selectedVenues: [],
  selectedShows: [],
  selectedArtistsDetails: [],
  selectedVenuesDetails: [],  
  isGenrePopoverOpen: false,
  isPageLoading: false,
  isSelection: true,
  email: null,
  genres: [],
  playlists: [],
  fetchedArtists: [],
  allShowsWithArtists: [],
  allShowsWithVenues: [],
  allShowsWithArtistsVenues: [],
};

// 🎯 Reducer Function
type Action =
  | { type: "TOGGLE_SHOW"; payload: string }
  | { type: "REMOVE_SHOW"; payload: string }
  | { type: "SET_DATE"; payload: string }  
  | { type: "SET_GENRE"; payload: { description: string | null; id: number | null } }
  | { type: "SET_PLAYLIST"; payload: { playlistId: string; playlistName: string } }
  | { type: "TOGGLE_LOADING"; payload: boolean }
  | { type: "TOGGLE_GENRE_POPOVER"; payload: boolean }
  | { type: "TOGGLE_SELECTION_MODE" }
  | { type: "SET_EMAIL"; payload: string }
  | { type: "SET_GENRES"; payload: Genre[] }
  | { type: "SET_PLAYLISTS"; payload: { id: string; name: string }[] }
  | { type: "SET_FETCHED_ARTISTS"; payload: { id: string }[] }
  | { type: "SET_SELECTED_SHOWS"; payload: string[] }  
  | { type: "SET_ALL_SHOWS_WITH_ARTISTS"; payload: any[] } 
  | { type: "SET_ALL_SHOWS_WITH_VENUES"; payload: any[] }
  | { type: "SET_ALL_SHOWS_WITH_ARTISTS_VENUES"; payload: any[] }
  | { type: "TOGGLE_ARTIST"; payload: { artistId: number; allShows: any[] } }
  | { type: "TOGGLE_VENUE"; payload: number }
  | { type: "RESET_SELECTION" }
  | { type: "SET_VIBE"; payload: string}
  | { type: "SET_LOCATION"; payload: string}
  | { type: "SET_VENUE"; payload: number | null };

  const reducer = (state: State, action: Action): State => {
    let newState = state;
  
    switch (action.type) {
      case "SET_LOCATION":
        return { 
          ...state, 
          selectedLocation: action.payload, // ✅ Now directly storing as "New York, NY"
          selectedVenue: null, 
          selectedVenues: [], 
          selectedVenuesDetails: [],
        };
      
      case "SET_VENUE": {      
        const venueDetails = state.allShowsWithVenues.find(v => v.venue_id === action.payload);
      
        return {
          ...state,
          selectedVenue: action.payload,
          selectedLocation: null, // ✅ Clear city/state when selecting a venue
          selectedVenuesDetails: venueDetails ? [venueDetails] : [], // ✅ Store venue details
        };
      }
      
      case "TOGGLE_VENUE": {
        const venueId = action.payload;
        
        return { 
          ...state, 
          selectedVenue: venueId,
          selectedLocation: null, // ✅ Ensure city/state is cleared when venue is selected
          selectedVenuesDetails: state.allShowsWithVenues.filter(v => v.venue_id === venueId),
        };
      }

      case "SET_ALL_SHOWS_WITH_ARTISTS_VENUES":
        newState = { ...state, allShowsWithArtistsVenues: action.payload };
        break;

      case "SET_ALL_SHOWS_WITH_ARTISTS":
        newState = { ...state, allShowsWithArtists: action.payload };
        break;
  
        case "SET_ALL_SHOWS_WITH_VENUES": {
          return { ...state, allShowsWithVenues: action.payload };
        }
  
      case "SET_PLAYLISTS":
        newState = { ...state, playlists: action.payload };
        break;

      case "SET_DATE":
          newState = { ...state, selectedDate: action.payload };
          break;
  
      case "SET_PLAYLIST":
        newState = {
          ...state,
          selectedVibe: "spotify",
          selectedPlaylistId: action.payload.playlistId,
          selectedPlaylistName: action.payload.playlistName,
          isGenrePopoverOpen: false,
        };
        break;
  
      case "SET_GENRE":
        newState = {
          ...state,
          selectedGenre: action.payload.description,
          selectedGenreId: action.payload.id,
          selectedPlaylistId: null,
          selectedPlaylistName: null,
        };
        break;
  
      case "TOGGLE_LOADING":
        newState = { ...state, isPageLoading: action.payload };
        break;
  
      case "TOGGLE_GENRE_POPOVER":
        newState = { ...state, isGenrePopoverOpen: action.payload };
        break;
      
      case "TOGGLE_SHOW":
        const updatedShows = state.selectedShows.includes(action.payload)
          ? state.selectedShows.filter((id) => id !== action.payload)
          : [...state.selectedShows, action.payload];
      
        newState = { ...state, selectedShows: updatedShows };
        break; // ✅ Ensure the break is here

      case "REMOVE_SHOW":
        newState = {
          ...state,
          selectedShows: state.selectedShows.filter((key) => key !== action.payload), // ✅ Remove the showKey
        };
        break;

      case "TOGGLE_SELECTION_MODE":
        newState = { ...state, isSelection: !state.isSelection };
        break;
  
      case "SET_EMAIL":
        newState = { ...state, email: action.payload };
        break;
  
      case "SET_GENRES":
        newState = { ...state, genres: action.payload };
        break;
  
      case "SET_VIBE":
        newState = {
          ...state,
          selectedVibe: action.payload,
          selectedPlaylistId: null,
          selectedPlaylistName: null,
          selectedGenre: null,
          selectedGenreId: null,
          playlists: action.payload === "vibe" ? [] : state.playlists,
          selectedArtists: [...state.selectedArtists], // Preserve selected artists
        };
        break;
  
      case "SET_FETCHED_ARTISTS":
        newState = { ...state, fetchedArtists: action.payload };
        break;

      case "SET_SELECTED_SHOWS":
        newState = { ...state, selectedShows: action.payload };
        break;
  
      case "TOGGLE_ARTIST": {
        const updatedArtists = state.selectedArtists.includes(action.payload.artistId)
          ? state.selectedArtists.filter((id) => id !== action.payload.artistId)
          : [...state.selectedArtists, action.payload.artistId];
      
        const updatedArtistsDetails = updatedArtists.map((id) =>
          state.selectedArtistsDetails.find((artist) => artist.artist_id === id) ||
          action.payload.allShows.flatMap((show) => show.artists || []).find((artist) => artist.artist_id === id)
        ).filter(Boolean);
      
        localStorage.setItem("selectedArtists", JSON.stringify(updatedArtists));
      
        newState = { 
          ...state, 
          selectedArtists: updatedArtists,
          selectedArtistsDetails: updatedArtistsDetails
        };
        break;
      }
  
      case "RESET_SELECTION":
        console.warn("🚨 Resetting selection state...");
        newState = { 
          ...state, 
          selectedArtists: [], 
          selectedVenues: [], 
          selectedShows: [], 
          selectedArtistsDetails: [], 
          selectedVenuesDetails: [], 
          allShowsWithArtists: [], 
          allShowsWithVenues: [], 
          allShowsWithArtistsVenues: [] 
        };
        break;
  
      default:
        console.warn("⚠️ Unknown action type:", (action as Action).type); // ✅ Explicit cast
        return state; // Ensure state remains unchanged on unknown actions  
    }
  
    return newState;
  };

  export type { Action };

const initializeState = (): State => ({
  ...initialState,
  selectedVibe: localStorage.getItem("vibe") || "vibe",  // ✅ Check if this is causing an issue
  selectedGenre: localStorage.getItem("selectedGenre"),
  selectedGenreId: localStorage.getItem("selectedGenreId")
    ? parseInt(localStorage.getItem("selectedGenreId") || "0", 10)
    : null,
  selectedPlaylistId: localStorage.getItem("selectedPlaylistId"),
  selectedPlaylistName: localStorage.getItem("selectedPlaylistName"),
});

const FestivalPage: React.FC = () => {

  // 🏷 Memoized itinerary key
  const itineraryKey = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let key = urlParams.get("itinerary_key") || localStorage.getItem("itineraryKey");

    if (!key) {
      key = Math.random().toString(36).substring(2, 12); // Generate a random 10-character alphanumeric string
      localStorage.setItem("itineraryKey", key);
    }

    return key;
  }, []);

  useEffect(() => {
    fetchItinerary(itineraryKey)
      .then((itinerary) => {
        const showKeys = itinerary.map((show) => show.show_key); // ✅ Extract show keys
        dispatch({ type: "SET_SELECTED_SHOWS", payload: showKeys }); // ✅ Update state
      })
      .catch((error) => console.error("❌ Error fetching itinerary:", error));
  }, [itineraryKey]); // ✅ Runs when itineraryKey changes

  useEffect(() => {
    const token =
      new URLSearchParams(window.location.search).get("token") ||
      sessionStorage.getItem("authToken");
  
    if (token) {
      sessionStorage.setItem("authToken", token); // Store token in sessionStorage
      try {
        const decoded = jwtDecode<CustomJwtPayload>(token);
        dispatch({ type: "SET_EMAIL", payload: decoded.email }); // Dispatch email
      } catch (error) {
        console.error("Failed to decode JWT:", error);
      }
    } else {
      console.warn("No token found in query parameters or sessionStorage.");
    }
  }, []);

  useEffect(() => {
    const storedArtists = JSON.parse(localStorage.getItem("selectedArtists") || "[]");
    
    if (storedArtists.length > 0) {
      dispatch({ type: "SET_FETCHED_ARTISTS", payload: storedArtists });
    }
  }, []);

  // 🎯 Fetch Genres & Playlists
  useEffect(() => {
    const cachedGenres = localStorage.getItem("genres");
  
    if (cachedGenres) {
      dispatch({ type: "SET_GENRES", payload: JSON.parse(cachedGenres) });
    } else {
      fetchGenres()
        .then((genres) => {
          localStorage.setItem("genres", JSON.stringify(genres));
          dispatch({ type: "SET_GENRES", payload: genres });
        })
        .catch((error) => console.error("❌ Error fetching genres:", error));
    }
  }, []);

  useEffect(() => {
    const savedGenre = localStorage.getItem("selectedGenre");
    const savedPlaylistId = localStorage.getItem("selectedPlaylistId");
    const savedPlaylistName = localStorage.getItem("selectedPlaylistName");
  
    if (savedGenre) {
      dispatch({
        type: "SET_GENRE",
        payload: { description: savedGenre, id: null }, // Adjust ID as necessary
      });
    }
  
    if (savedPlaylistId && savedPlaylistName) {
      dispatch({
        type: "SET_PLAYLIST",
        payload: { playlistId: savedPlaylistId, playlistName: savedPlaylistName },
      });
    }
  }, []);

  useEffect(() => {
    const storedArtists = JSON.parse(localStorage.getItem("selectedArtists") || "[]");
    if (storedArtists.length > 0) {
      dispatch({ type: "SET_FETCHED_ARTISTS", payload: storedArtists });
      dispatch({ type: "SET_ALL_SHOWS_WITH_ARTISTS_VENUES", payload: storedArtists }); // ✅ Restore state
    }
  }, []);


  const festivalKey = useMemo(() => {
    return new URLSearchParams(window.location.search).get("festival_key") || "DEFAULT_FESTIVAL_KEY";
  }, []);

  const [state, dispatch] = useReducer(reducer, undefined, initializeState);

  const mode = useMemo(() => {
    return new URLSearchParams(window.location.search).get("mode") || "selection";
  }, [window.location.search]);

  const { data: shows = [], isLoading: showsLoading, refetch: refetchShows, isFetching: showsFetching } = useQuery({
    queryKey: ["shows", itineraryKey], // ✅ Only dependent on itineraryKey
    queryFn: async () => {
      if (!itineraryKey) return []; // ✅ Prevent unnecessary calls if itineraryKey is missing
  
      const result = await fetchShows(
        null, null, null, null, null, null, null, null, null, null, null, null, null, itineraryKey
      );

      return result;
    },
    enabled: !!itineraryKey, // ✅ Only run if itineraryKey exists
    initialData: [],
    staleTime: 60000,  
    refetchOnWindowFocus: false,
  });

  const { data: allShows = [], isLoading: allShowsLoading, refetch: refetchAllShows, isFetching: allShowsFetching } = useQuery({
    queryKey: ["allShows", festivalKey], 
    queryFn: async () => {  
      if (!festivalKey) {
        console.warn("⚠️ No festivalKey provided, skipping fetch.");
        return [];
      }
  
      try {
        const result = await fetchShows(
          null, null, null, null, null, null, null, null, null, null, null, null, festivalKey
        );
  
        if (!Array.isArray(result)) {
          console.error("❌ Unexpected API Response - Expected Array but got:", result);
          return [];
        }
  
        if (result.length === 0) {
          console.warn("⚠️ API returned an empty array for allShows.");
        }
  
        return [...result];  // 🔥 Ensure the array is spread (React sometimes ignores non-spread state)
      } catch (error) {
        console.error("❌ Error fetching allShows:", error);
        return [];
      }
    },
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });
  
  const { 
    data: filteredAllShows = [], 
    isLoading: artistsLoading, 
    refetch: refetchArtistsVenues, 
    isFetching: allShowsArtistsVenuesFetching 
  } = useQuery({
    queryKey: ["filteredAllShows", festivalKey, state.selectedGenreId, state.selectedPlaylistId, state.selectedDate, state.selectedVenue, state.selectedLocation],
    queryFn: async () => { 
      try {
        const result = await fetchShows(
          null, null, null, 
          state.selectedGenreId || null, 
          null, state.selectedDate, null, 
          null, null, state.selectedVenue ?? null, null,
          state.selectedPlaylistId || null, 
          festivalKey, null, null, null, null, null, null, null, state.selectedLocation
        );

        return result;
      } catch (error) {
        console.error("❌ Error in fetchShows:", error);
        return [];
      }
    },
    initialData: [],
    refetchOnWindowFocus: false,  
    enabled: state.selectedGenreId !== undefined || state.selectedPlaylistId !== undefined || state.selectedDate !== undefined || state.selectedVenue !== undefined || state.selectedLocation !== undefined,
  });

  // Sync selectedVibe to localStorage
  useEffect(() => {
    localStorage.setItem("vibe", state.selectedVibe);
  }, [state.selectedVibe]);

  // Sync selectedGenre to localStorage
  useEffect(() => {
    if (state.selectedGenre) {
      localStorage.setItem("selectedGenre", state.selectedGenre);
    } else {
      localStorage.removeItem("selectedGenre");
    }
  }, [state.selectedGenre]);

  // Sync selectedGenreId to localStorage
  useEffect(() => {
    if (state.selectedGenreId !== null) {
      localStorage.setItem("selectedGenreId", state.selectedGenreId.toString());
    } else {
      localStorage.removeItem("selectedGenreId");
    }
  }, [state.selectedGenreId]);

  // Sync playlist selection to localStorage
  useEffect(() => {
    const storedId = localStorage.getItem("selectedPlaylistId");
    const storedName = localStorage.getItem("selectedPlaylistName");

    if (state.selectedPlaylistId && state.selectedPlaylistName) {
      if (storedId !== state.selectedPlaylistId || storedName !== state.selectedPlaylistName) {
        localStorage.setItem("selectedPlaylistId", state.selectedPlaylistId);
        localStorage.setItem("selectedPlaylistName", state.selectedPlaylistName);
      }
    }
    else {
      localStorage.removeItem("selectedPlaylistId");
      localStorage.removeItem("selectedPlaylistName");
    }

  }, [state.selectedPlaylistId, state.selectedPlaylistName]);

  useEffect(() => {
    dispatch({ type: "TOGGLE_LOADING", payload: true });
  
    if (state.selectedPlaylistId && state.email) {
  
      fetchPlaylistArtists(state.email, state.selectedPlaylistId)
        .then((artistsFromPlaylist) => {
          dispatch({ type: "SET_FETCHED_ARTISTS", payload: artistsFromPlaylist });
        })
        .catch((error) => console.error("❌ Error fetching artists:", error));
    } else {
      dispatch({ type: "SET_FETCHED_ARTISTS", payload: [] }); // Reset when no playlist is selected
    }
  
    dispatch({ type: "TOGGLE_LOADING", payload: false });
  }, [state.selectedPlaylistId, state.email]);

  const isLoading = useMemo(() => {
    return (
      showsLoading ||
      allShowsLoading ||
      artistsLoading ||
      showsFetching ||
      allShowsFetching ||
      allShowsArtistsVenuesFetching
    );
  }, [showsLoading, allShowsLoading, artistsLoading, showsFetching, allShowsFetching, allShowsArtistsVenuesFetching]);

  useEffect(() => {
    dispatch({ type: "TOGGLE_LOADING", payload: isLoading });
  }, [isLoading]);

  
  useEffect(() => {  
    const vibe = localStorage.getItem("vibe") || "vibe";
    if (state.selectedVibe === "spotify" && state.email) {
      fetchSpotifyPlaylists(state.email)
        .then((playlists) => {
          dispatch({ type: "SET_PLAYLISTS", payload: playlists });
        })
        .catch((error) => console.error("❌ Error fetching Spotify playlists:", error));
    }
  }, [state.selectedVibe, state.email]);

  useEffect(() => {
    if (state.selectedGenre) {
      localStorage.setItem("selectedGenre", state.selectedGenre);
    }
  }, [state.selectedGenre]);

  useEffect(() => {
    if (state.selectedVibe === "spotify" && state.email) {
      fetchSpotifyPlaylists(state.email)
        .then((playlists) => {
          dispatch({ type: "SET_PLAYLISTS", payload: playlists });
        })
        .catch((error) => console.error("❌ Error fetching Spotify playlists:", error));
    }
  }, [state.selectedVibe, state.email]);

  useEffect(() => {
    if (state.selectedPlaylistId || state.selectedGenreId) {
      dispatch({ type: "TOGGLE_LOADING", payload: true }); // ✅ Show loading indicator
  
      // ✅ Only refresh the data without affecting `isSelection`
      refetchAllShows()
        .then(() => dispatch({ type: "TOGGLE_LOADING", payload: false }))
        .catch((error) => {
          console.error("❌ Error refreshing artists:", error);
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        });
    }
  }, [state.selectedGenreId, state.selectedPlaylistId, refetchAllShows]);

  useEffect(() => {
    if (!showsLoading && !allShowsLoading) {
      dispatch({ type: "TOGGLE_LOADING", payload: true });
  
      Promise.all([refetchShows(), refetchArtistsVenues()])
        .finally(() => {
          dispatch({ type: "TOGGLE_LOADING", payload: false });
        });
    }
  }, [state.selectedGenreId, state.selectedPlaylistId, refetchShows, refetchArtistsVenues, showsLoading, allShowsLoading]);

  const toggleSelectionMode = async () => {
    if (state.isPageLoading) return;
  
    dispatch({ type: "TOGGLE_LOADING", payload: true });
  
    try {
      if (state.isSelection) {  
        await new Promise((resolve) => setTimeout(resolve, 0)); // Ensure state is stabilized

      } else {
        await Promise.all([refetchArtistsVenues()]);
      }
  
      dispatch({ type: "TOGGLE_SELECTION_MODE" });
    } catch (error) {
      console.error("❌ Error toggling selection mode:", error);
    } finally {
      dispatch({ type: "TOGGLE_LOADING", payload: false });
    }
  };

  const handleGenreSelect = (description: string | null, id: number | null) => {
    dispatch({ type: "SET_VIBE", payload: "vibe" }); // ✅ Set Vibe mode
    dispatch({ type: "SET_GENRE", payload: { description, id } }); // ✅ Update genre selection
  
    setTimeout(() => dispatch({ type: "TOGGLE_GENRE_POPOVER", payload: false }), 100);
  };

  const handlePlaylistSelect = (playlistId: string, playlistName: string) => {
    dispatch({ type: "SET_PLAYLIST", payload: { playlistId, playlistName } });
    dispatch({ type: "TOGGLE_GENRE_POPOVER", payload: false }); // Close the popover
  };

  const handleSpotifyRedirect = () => {
    dispatch({ type: "SET_VIBE", payload: "spotify" }); // Set vibe before redirect
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/api/auth/spotify?redirect=${encodeURIComponent(
      "festival"
    )}&festival_key=${encodeURIComponent(festivalKey)}`;
  };

  const handleVenueSelect = (venueId: number) => {
    dispatch({
      type: "TOGGLE_VENUE",
      payload: venueId,  
    });
  
    refetchArtistsVenues(); // 🔄 Refresh results based on venue selection
  };

  const formatDateWithSuffix = (dateString: string | null) => {
    if (!dateString) return "";
    
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return ""; // Handle invalid dates
  
    const day = date.getDate();
    const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
  
    // Function to get the ordinal suffix (st, nd, rd, th)
    const getOrdinalSuffix = (day: number) => {
      if (day >= 11 && day <= 13) return "th"; // Handle 11th, 12th, 13th
      const lastDigit = day % 10;
      return lastDigit === 1 ? "st" : lastDigit === 2 ? "nd" : lastDigit === 3 ? "rd" : "th";
    };
  
    return `${month} ${day}`;
  };

  const [selectedCount, setSelectedCount] = useState(0); // ✅ Track selection count

  const handleCardClick = async (showKey: string | undefined, showId: number | undefined) => {
    if (!showId) return;
    
    const isRemoving = state.selectedShows.includes(showKey || "");
  
    // ✅ Optimistically update UI
    dispatch({ type: isRemoving ? "REMOVE_SHOW" : "TOGGLE_SHOW", payload: showKey || "" });
  
    try {
      if (isRemoving) {
        await removeFromItinerary(showId, itineraryKey);
  
        // ✅ Update UI state immediately
        dispatch({ type: "SET_SELECTED_SHOWS", payload: state.selectedShows.filter((key) => key !== showKey) });
      } else {
        await addToItinerary(itineraryKey, showId);
      }
  
      // ✅ Refetch the itinerary and update state
      await refetchShows();
  
      const updatedItinerary = await fetchItinerary(itineraryKey);
      dispatch({ type: "SET_SELECTED_SHOWS", payload: updatedItinerary.map((show) => show.show_key) });
    } catch (error) {
      console.error("❌ Error updating itinerary:", error);
    }
  };

  const handleClearItinerary = async () => {
    dispatch({ type: "RESET_SELECTION" });
  
    try {
      await resetItinerary(itineraryKey);
  
      // ✅ Refetch itinerary after clearing
      refetchShows();
    } catch (error) {
      console.error("❌ Error clearing itinerary:", error);
    }
  };

  const updateSelectedShows = (showKey: string) => {
    dispatch({ type: "TOGGLE_SHOW", payload: showKey });
  };

  const removeSelectedShow = (showKey: string) => {
    dispatch({ type: "REMOVE_SHOW", payload: showKey });
  };

  // Safely retrieve festival start and end dates
  const festivalStart = formatDateWithSuffix(allShows[0]?.festival?.[0]?.date_start ?? "");
  const festivalEnd = formatDateWithSuffix(allShows[0]?.festival?.[0]?.date_end ?? "");
  const festivalStartDate = allShows[0]?.festival?.[0]?.date_start ?? ""
  const festivalEndDate = allShows[0]?.festival?.[0]?.date_end ?? ""

  const availableDates = useMemo(() => {
    if (!festivalStartDate || !festivalEndDate) return [];
    return getFestivalDates(festivalStartDate, festivalEndDate);
  }, [festivalStartDate, festivalEndDate]);

  const locations = useMemo(() => {
    const locationMap = new Map<
      string,
      { city: string; state: string; venues: any[] }
    >();
  
    allShows.forEach((show) => {
      if (!show.venues || show.venues.length === 0) return;
  
      show.venues.forEach((venue) => {
        if (!venue.addr_city || !venue.addr_state) return;
  
        const key = `${venue.addr_city}, ${venue.addr_state}`;
  
        if (!locationMap.has(key)) {
          locationMap.set(key, { city: venue.addr_city, state: venue.addr_state, venues: [] });
        }
  
        const locationEntry = locationMap.get(key);
        if (locationEntry && !locationEntry.venues.some((v) => v.venue_id === venue.venue_id)) {
          locationEntry.venues.push(venue); // Store full venue details
        }
      });
    });
  
    return Array.from(locationMap.values()).sort((a, b) => a.city.localeCompare(b.city));
  }, [allShows]);

  return (
    <>
      <MainHeader />
  
      {state.isPageLoading ? (
        <div className="flex justify-center items-center min-h-[calc(100vh-8rem)] bg-gray-100">
          <img
            src="logo-animate-gray.gif"
            alt="Loading..."
            className="max-w-[20rem] md:max-w-[25rem] lg:max-w-[30rem] object-contain"
          />
        </div>
      ) : (
        <div className="p-6 bg-gray-100 min-h-[calc(100vh-8rem)]">
          {(mode === "itinerary" || !state.isSelection) && (
            <header className="flex flex-wrap gap-2 items-center px-2 py-4 max-w-[762px]">
              <h1 className="flex-1 text-3xl font-bold tracking-tighter text-zinc-950 max-md:max-w-full">
                My Festival Itinerary
              </h1>
            </header>
          )}
  
          {(mode === "itinerary" || !state.isSelection) ? (
            <Itinerary
              isLoading={state.isPageLoading}
              shows={shows}
              allShows={allShows}
              itineraryKey={itineraryKey ?? ""}
              festivalKey={festivalKey}
              refetch={refetchShows}
              playlist={state.selectedPlaylistId || ""}
              email={state.email}
              toggleSelectionMode={toggleSelectionMode}
              updateSelectedShows={updateSelectedShows}
              removeSelectedShow={removeSelectedShow}
              dispatch={dispatch}
            />
          ) : (
            <>
              {/* Header Navigation */}
              <nav className="w-full max-w-[600px] mx-auto flex justify-between mb-4 px-2">
                <a href="/search">
                  <Button variant="underline">FAQ</Button>
                </a>
                <a href="/festival-map">
                  <Button variant="underline">Festival Map</Button>
                </a>
              </nav>
  
              {/* Festival Info */}
              <header className="flex flex-col p-4 mx-auto max-w-[600px]">
                <div className="flex flex-col items-center w-full">
                  <img
                    src="MoM logo.png"
                    alt="Festival Logo"
                    className="w-full max-w-[600px] h-auto rounded-lg shadow-md"
                  />
                  <div className="flex flex-col gap-2 items-start w-full pt-4">
                    <div className="text-3xl font-bold tracking-tighter text-zinc-950">
                      {allShows[0]?.festival?.[0]?.name ?? "Festival Name"}
                    </div>
                    <div className="text-xl font-semibold tracking-tight text-zinc-800">
                      {festivalStart} - {festivalEnd}
                    </div>
                    <div className="mt-1 text-lg text-zinc-950">
                      {allShows[0]?.festival?.[0]?.location ?? "Festival Location"}
                    </div>
                    <p className="pt-3 text-base font-medium text-zinc-950">
                      {allShows[0]?.festival?.[0]?.description ?? "Festival description goes here."}
                    </p>
                  </div>
                </div>
              </header>
  
              {/* Itinerary Builder */}
              <div className="p-2 bg-gray-100 gap-4 justify-center mx-auto max-w-[600px]">
                <div className="text-xl font-semibold text-zinc-800 mb-2">Itinerary Builder</div>
  
                {/* Selectors */}
                <div className="flex flex-wrap md:flex-nowrap items-center gap-4 mb-4 justify-center mx-auto max-w-[600px]">
                  {/* 🎶 Vibe Selector */}
                  <Popover
                    open={state.isGenrePopoverOpen}
                    onOpenChange={(open) => dispatch({ type: "TOGGLE_GENRE_POPOVER", payload: open })}
                  >
                    <PopoverTrigger className="px-2 py-1 text-sm font-medium flex items-center justify-between rounded-md border border-[#383340] text-zinc-950 w-[90px] bg-white hover:bg-gray-200">
                      <span className="truncate">{state.selectedGenre || "Vibe"}</span>
                      <FaChevronDown className="text-gray-500 text-xs ml-1" />
                    </PopoverTrigger>
                    <PopoverContent className="w-[225px] max-h-[300px] overflow-y-auto text-left bg-white shadow-lg rounded-md border border-gray-300">
                      <div
                        className="text-blue-500 hover:underline cursor-pointer px-3 py-2"
                        onClick={() => dispatch({ type: "SET_GENRE", payload: { description: null, id: null } })}
                      >
                        Show All Vibes
                      </div>
                      {state.genres.length > 0 ? (
                        state.genres.map((genre) => (
                          <div
                            key={genre.genre_id}
                            onClick={() => handleGenreSelect(genre.description ?? null, genre.genre_id ?? null)}
                            className="cursor-pointer hover:bg-gray-100 rounded-md px-3 py-1"
                          >
                            {genre.description}
                          </div>
                        ))
                      ) : (
                        <p className="text-gray-500 pl-3">No genres found.</p>
                      )}
                    </PopoverContent>
                  </Popover>
  
                  {/* 📅 Date Selector */}
                  <Popover>
                    <PopoverTrigger className="px-2 py-1 text-sm font-medium flex items-center justify-between rounded-md border border-[#383340] text-zinc-950 w-[90px] bg-white hover:bg-gray-200">
                      <span className="truncate">
                        {state.selectedDate
                          ? new Date(`${state.selectedDate}T12:00:00Z`).toLocaleDateString("en-US", {
                              weekday: "long",
                              month: "short",
                              day: "numeric",
                            })
                          : "Date"}
                      </span>
                      <FaChevronDown className="text-gray-500 text-xs ml-1" />
                    </PopoverTrigger>
                    <PopoverContent className="w-[225px] max-h-[300px] overflow-y-auto text-left bg-white shadow-lg rounded-md border border-gray-300">
                      <div
                        className="text-blue-500 hover:underline cursor-pointer px-3 py-2"
                        onClick={() => dispatch({ type: "SET_DATE", payload: "" })}
                      >
                        Show All Dates
                      </div>
                      {availableDates.map((date) => {
                        const localDate = new Date(date + "T12:00:00Z");
                        return (
                          <div
                            key={date}
                            onClick={() => dispatch({ type: "SET_DATE", payload: date })}
                            className={`cursor-pointer px-3 py-1 hover:bg-gray-100 rounded-md transition ${
                              state.selectedDate === date ? "font-bold text-zinc-900" : "text-gray-700"
                            }`}
                          >
                            {localDate.toLocaleDateString(undefined, {
                              weekday: "long",
                              month: "short",
                              day: "numeric",
                            })}
                          </div>
                        );
                      })}
                    </PopoverContent>
                  </Popover>
  
                  {/* 📍 Location Selector */}
                  <Popover>
                    <PopoverTrigger className="px-2 py-1 text-sm font-medium flex items-center justify-between rounded-md border border-[#383340] text-zinc-950 w-[90px] bg-white hover:bg-gray-200">
                      <span className="truncate">
                        {state.selectedVenue !== null
                          ? (() => {
                              const venue = allShows
                                .flatMap((show) => show.venues || [])
                                .find((v) => v.venue_id === state.selectedVenue);
                              return venue?.name || "Unknown Venue";
                            })()
                          : state.selectedLocation || "Location"}
                      </span>
                      <FaChevronDown className="text-gray-500 text-xs ml-1" />
                    </PopoverTrigger>
                    <PopoverContent className="w-[275px] max-h-[300px] overflow-y-auto text-left bg-white shadow-lg rounded-md border border-gray-300">
                      <div
                        className="text-blue-500 hover:underline cursor-pointer px-3 py-2"
                        onClick={() => dispatch({ type: "SET_LOCATION", payload: null as any })}
                      >
                        Show All Locations
                      </div>
                      {locations.map(({ city, state, venues }) => (
                        <div key={`${city}, ${state}`} className="border-b border-gray-200 pb-1 mb-1">
                          <div
                            className="cursor-pointer font-bold text-zinc-950 hover:bg-gray-100 rounded-md px-3 py-1"
                            onClick={() =>
                              dispatch({ type: "SET_LOCATION", payload: `${city}, ${state}` })
                            }
                          >
                            {city}, {state}
                          </div>
                          {venues.map((venue) => (
                            <div
                              key={venue.venue_id}
                              className="cursor-pointer text-gray-700 hover:bg-gray-100 rounded-md pl-6 px-3 py-1"
                              onClick={() => handleVenueSelect(venue.venue_id)}
                            >
                              {venue.name}
                            </div>
                          ))}
                        </div>
                      ))}
                    </PopoverContent>
                  </Popover>
                </div>
  
                {/* Itinerary Actions 
                <div className="flex flex-wrap justify-center gap-4 mb-4">
                  <button
                    onClick={handleClearItinerary}
                    className="px-4 py-2 text-sm font-medium rounded-md border border-[#383340] text-zinc-950 w-[150px] bg-white hover:bg-gray-200"
                  >
                    Retrieve Itinerary
                  </button>
                  <button
                    onClick={handleClearItinerary}
                    className="px-4 py-2 text-sm font-medium rounded-md border border-[#383340] text-zinc-950 w-[140px] bg-white hover:bg-gray-200"
                  >
                    Merge Itinerary
                  </button>
                </div>
  */}
  
                <div className="flex flex-wrap justify-center gap-4 mb-4">
                  <button
                    onClick={toggleSelectionMode}
                    disabled={state.selectedShows.length === 0}
                    className={`px-4 py-2 text-sm font-medium text-white rounded-md border border-[#383340] bg-zinc-950 hover:bg-zinc-900 min-w-[160px] ${
                      state.selectedShows.length === 0 ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  >
                    {state.isSelection
                      ? `Create Itinerary (${state.selectedShows.length})`
                      : `Select Artists & Venues (${state.selectedShows.length})`}
                  </button>
                  <button
                    onClick={handleClearItinerary}
                    className="px-4 py-2 text-sm font-medium rounded-md border border-[#383340] text-zinc-950 w-[130px] bg-white hover:bg-gray-200"
                  >
                    Clear Itinerary
                  </button>
                </div>
  
                {/* Shows Grid */}
                <div className="grid gap-2 max-w-[600px] mx-auto px-2">
                  {filteredAllShows.map((show, index) => {
                    const rawDate = show.date_start?.split("T")[0] ?? null;
                    const showDate = rawDate ? new Date(rawDate) : null;
                    const isValidDate = showDate && !isNaN(showDate.getTime());
  
                    const formattedDate = isValidDate
                      ? showDate.toLocaleDateString("en-US", { weekday: "long", month: "short", day: "numeric" })
                      : "Invalid Date";
  
                    const isFirstShow = index === 0;
                    const previousDate = isFirstShow
                      ? null
                      : filteredAllShows[index - 1].date_start?.split("T")[0];
                    const isNewDate = isFirstShow || previousDate !== rawDate;
  
                    return (
                      <React.Fragment key={show.show_id}>
                        {isNewDate && isValidDate && (
                          <div className="text-lg font-bold text-zinc-900 mt-2">{formattedDate}</div>
                        )}
                        <FestivalShowCard
                          show={show}
                          onClick={() => handleCardClick(show.show_key, show.show_id)}
                          isSelected={state.selectedShows.includes(show.show_key ?? "")}
                        />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      )}
  
      <Footer />
    </>
  );
};

export default FestivalPage;