import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import SuperAdminHeader from "../components/SuperAdminHeader";
import SuperAdminNav from "../components/SuperAdminNav";
import ArtistMatchComponent from "../components/ArtistMatch";
import VenueAdminAddVenue from "../components/VenueAdminAddVenue";
import Footer from "../components/Footer";

const parseJwt = (token: string) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Failed to parse JWT:", error);
    return null;
  }
};

const AdminSuper: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('Profile');
  const [userId, setUserId] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve token from query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  // Redirect to login if token is missing
  useEffect(() => {
    if (!token) {
      navigate("/login?message=Invalid Login");
    } else {
      const decoded = parseJwt(token);
      if (decoded && decoded.user_id) {
        setUserId(decoded.user_id);
      } else {
        navigate("/login?message=Invalid Token");
      }
    }
  }, [token, navigate]);

  return (
    <main className="flex overflow-hidden flex-col pb-8 mx-auto w-full bg-white">
      <SuperAdminHeader />
      <SuperAdminNav activeTab={activeTab} setActiveTab={setActiveTab} />
      <section className="flex flex-col self-center px-4 pb-4 mt-6 w-full max-w-[430px]">
        {activeTab === 'Profile' ? (
          <VenueAdminAddVenue user_id={userId || 0} />  // Defaulting user_id to 0 if null
        ) : (
          <ArtistMatchComponent />
        )}
      </section>
      <Footer />
    </main>
  );
};

export default AdminSuper;