import React from 'react';

interface ArtistItemProps {
  name: string;
  genre: string;
  image: string;
  onClick: () => void;
  onRemove?: () => void; // Add a prop for removing the artist
}

const ArtistItem: React.FC<ArtistItemProps> = ({ name, genre, image, onClick, onRemove }) => {
  const defaultImage = "default_thumb.webp";

  return (
    <div className="flex items-center justify-between mt-4 w-full">
      <div className="flex gap-2 items-center" onClick={onClick}>
        <img
          src={image || defaultImage}
          className="object-contain w-[42px] h-[42px] rounded-full"
          alt={`${name} artist avatar`}
        />
        <div className="text-base font-medium">{name}</div>
        <div className="text-sm text-neutral-400">{genre}</div>
      </div>

      {onRemove && (
        <button 
          onClick={onRemove} 
          className="text-sm font-medium leading-6 text-center text-zinc-950"
        >
          Remove
        </button>
      )}
    </div>
  );
};

export default ArtistItem;