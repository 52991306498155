import React, { useState, useEffect } from 'react';
import { fetchArtistById } from '../lib/data';
import { Artist } from '../lib/definitions';
import axios from 'axios';

interface ArtistAdminHeaderProps {
  artists: Artist[];
  selectedArtist: number;  // Change to number for artist_id
  setSelectedArtist: (artistId: number) => void; // Update to use artist_id
  user_id: number;
}

const ArtistAdminHeader: React.FC<ArtistAdminHeaderProps> = ({ artists, selectedArtist, setSelectedArtist, user_id }) => {

  const [artist, setArtist] = useState<Artist | null>(null); // Store email state
  const [showCancelModal, setShowCancelModal] = useState(false); // State to control modal visibility

  useEffect(() => {
    const fetchArtist = async () => {
      try {
        const artistData = await fetchArtistById(selectedArtist);
        setArtist(artistData);
      } catch (error) {
        console.error('Failed to fetch artist:', error);
      }
    };

    fetchArtist(); // Call the async function inside useEffect
  }, [selectedArtist]);

  const handleCheckout = async (planId: string) => {
    try {
      // Step 1: Fetch the email for the given user_id
      const emailResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user/${user_id}/email`);
      const email = emailResponse.data.email;
      
      // Ensure the artist object and artist_key exist
      const object_key = artist?.artist_key;
  
      if (!email) {
        throw new Error('Email not found for the given user ID');
      }
  
      if (!object_key) {
        throw new Error('Artist key not found');
      }
      
      // Step 2: Proceed with the Chargebee checkout
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/checkout/create-checkout`, {
        email,    
        planId,
        object_key,   // Pass the object_key here
      });
  
      // Redirect to Chargebee checkout page
      window.location.href = response.data.hosted_page_url;
    } catch (error) {
      console.error('Checkout failed:', error);
    }
  };

  const handleCancelSubscription = async () => {

    try {  
      const subscriptionId = artist?.sub_id;
  
      if (!subscriptionId) {
        throw new Error('Subscription ID not found');
      }
      
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/checkout/cancel-subscription`, {
        subscriptionId,  // Pass the object_key here
      });
  
      // Check if the response has the redirect URL
      if (response.data && response.data.redirectUrl) {
        // Redirect to the URL specified in the response
        window.location.href = response.data.redirectUrl;
      } else {
        alert('Subscription canceled successfully, but no redirect URL provided.');
      }

    } catch (error) {
      console.error('Subscription cancellation failed:', error);
      alert('Error canceling subscription: ' + (error));
    }
  };

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
  };

  return (
    <>
      <header className="flex items-center justify-between px-4 py-6 w-full bg-zinc-950">
        {/* Logo and Artist Profile Information */}
        <div className="flex flex-col items-start flex-1 min-w-[240px]">
          <a href="/">
            <img
              loading="lazy"
              src="myvibelive logo.png"
              className="object-contain aspect-[1.18] w-[47px]"
              alt="Logo"
            />
          </a>
          <h1 className="mt-2 text-3xl font-bold tracking-tighter leading-none text-white">
            Artist Profile
          </h1>
          <p className="mt-2 max-w-full text-xs font-medium leading-4 text-zinc-400">
            Configure your artist profile and how you're displayed on myVibe.live!
          </p>
        </div>

        {/* Subscription and Actions */}
        <div className="flex flex-col items-end space-y-2">
          <div className="text-white text-xs font-medium">
            Subscription: {artist?.sub_level}
          </div>
          {artist?.sub_level === "Headliner" ? (
            <button
              onClick={openCancelModal}
              className="text-white text-xs font-medium border border-white rounded-md px-3 py-1 hover:bg-white hover:text-black transition"
            >
              Cancel Subscription
            </button>
          ) : (
            <button
              onClick={() => handleCheckout("artist_headliner-USD-Monthly")}
              className="text-white text-xs font-medium border border-white rounded-md px-3 py-1 hover:bg-white hover:text-black transition"
            >
              Sign up for Headliner
            </button>
          )}
          <div className="text-white text-xs font-medium">Contact: admin@myvibelive.com</div>
          <a href="/">
            <div className="w-15 text-white text-xs mt-1 font-medium mb-1 border border-white rounded-md px-2 py-1 text-center">
              Log Off
            </div>
          </a>
        </div>
      </header>

      {/* Dropdown for selecting an artist */}
      <div className="flex flex-col mt-4 w-64 ml-4">
        <label htmlFor="artistDropdown" className="text-sm font-semibold text-gray-700 mb-2">
          Select Artist
        </label>
        {artists.length === 0 ? (
          <p className="text-gray-500 text-sm italic">No artists available</p>
        ) : (
          <div className="relative">
            <select
              id="artistDropdown"
              value={selectedArtist}
              onChange={(e) => setSelectedArtist(Number(e.target.value))} // Convert value to number
              className="w-full p-2 bg-white text-gray-700 rounded-md border border-gray-300 shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 ease-in-out"
            >
              <option value="" disabled className="text-gray-400">
                Choose an artist...
              </option>
              {artists.map((artist) => (
                <option key={artist.artist_id} value={artist.artist_id}>
                  {artist.name}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
              </svg>
            </div>
          </div>
        )}
      </div>

      {showCancelModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-lg font-semibold mb-4">Are you sure you want to cancel?</h2>
            <p className="text-gray-600 mb-6">This action cannot be undone.</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={closeCancelModal}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition"
              >
                No, Keep Subscription
              </button>
              <button
                onClick={() => {
                  handleCancelSubscription();
                  closeCancelModal();
                }}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
              >
                Yes, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArtistAdminHeader;