"use client";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Show, Venue, Genre, Artist } from "../lib/definitions";

interface ShowFestivalCardProps {
  show: Show;
  venue: Venue[];
  genres: Genre[];
  artists: Artist[];
  playlist: string | null;
  email: string | null;
}

const ShowFestivalCard: React.FC<ShowFestivalCardProps> = ({ show, venue, genres, artists, playlist, email }) => {
  const navigate = useNavigate();

  return (
    <article className="relative overflow-hidden bg-zinc-950 text-white rounded-xl max-w-[796px] shadow-md mb-6">
      <EventHeader show={show} navigate={navigate} />
      <div className="flex flex-col">
        <EventDetails venue={venue} genres={genres} artists={artists} />
        <ActionButtons playlist={playlist} email={email} />
      </div>
    </article>
  );
};

const EventHeader: React.FC<{ show: Show; navigate: (path: string) => void }> = ({ show, navigate }) => {
  return (
    <header className="relative flex flex-col justify-center p-4 w-full bg-zinc-950 min-h-14 max-md:max-w-full border-b border-zinc-800">
      {/* Festival Banner */}
      {show.festival_key && (
        <div
          className="absolute -top-2 left-4 bg-blue-600 text-white text-xs font-semibold py-1 px-3 rounded-lg shadow-md cursor-pointer hover:bg-blue-700 transition z-10"
          onClick={() => navigate(`/festival/${show.festival_key}`)}
        >
          🎉 Part of Mile of Music 12
        </div>
      )}

      <div className="flex flex-wrap gap-4 items-center w-full bg-white rounded p-3 max-md:max-w-full">
        <img
          src={show.image_thumb || "https://cdn.builder.io/api/v1/image/assets/TEMP/2b7b200be082c66b97e1425a3554d016dda75e20aa641d425374c7dd876df394"}
          className="object-contain shrink-0 self-stretch my-auto aspect-square w-[72px]"
          alt="Festival logo"
        />
        <div className="flex flex-col flex-1 shrink justify-center self-stretch pr-4 my-auto min-h-[72px] min-w-60 text-zinc-950">
          <h1 className="text-lg font-bold tracking-tight">Mile of Music 12</h1>
          <p className="text-base font-medium">Apr 23 - 26th</p>
        </div>
        <a
          href="/festival?festival_key=F9eSe023J3"
          className="px-6 py-2 text-sm font-medium text-white bg-zinc-950 border border-solid border-[#383340] rounded-md hover:bg-zinc-800"
        >
          Create Itinerary
        </a>
      </div>
    </header>
  );
};

const EventDetails: React.FC<{ venue: Venue[]; genres: Genre[]; artists: Artist[] }> = ({ venue, genres, artists }) => {
  return (
    <section className="flex flex-col p-4 w-full max-md:max-w-full">
      <div className="w-full max-md:max-w-full">
        <div className="flex flex-col justify-center w-full max-md:max-w-full">
          <div className="flex flex-wrap gap-0.5 items-start w-full whitespace-nowrap max-md:max-w-full">
            <h2 className="flex-1 text-3xl font-bold tracking-tighter leading-none text-white">
              {artists.map((artist) => artist.name).join(", ")}
            </h2>
            <p className="text-base font-semibold text-right text-zinc-400">
              {genres.map((genre) => genre.description).join(", ")}
            </p>
          </div>
          <p className="text-xs font-semibold text-white">
            Performing at {venue.map((v) => v.name).join(", ")}
          </p>
        </div>
        <div className="flex gap-6 items-start mt-2 w-full text-base font-semibold max-md:max-w-full">
          <div className="flex flex-col flex-1 justify-center w-full min-w-60 max-md:max-w-full">
            <address className="text-zinc-400 not-italic">{venue.map((v) => v.addr_city).join(", ")}</address>
          </div>
        </div>
      </div>
    </section>
  );
};

const ActionButtons: React.FC<{ playlist: string | null; email: string | null }> = ({ playlist, email }) => {
  return (
    <div className="flex gap-2 items-start self-start mt-6 p-4">
      <button className="flex gap-2 items-center p-2 w-10 h-10 rounded-md border border-solid border-[#383340]">
        <div className="flex w-6 min-h-6" />
      </button>
      <button className="flex gap-2 items-center p-2 w-10 h-10 rounded-md border border-solid border-[#383340]">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/09b90e96d43beecbc63e65605345a852a5077ecb8fd5e503b1bbba213868d10b"
          className="object-contain w-6 aspect-square"
          alt="Action icon"
        />
      </button>
      {playlist && (
        <button
          onClick={() => window.open(playlist, "_blank")}
          className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
        >
          Open Playlist
        </button>
      )}
      {email && (
        <button
          onClick={() => window.open(`mailto:${email}`, "_blank")}
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Contact Artist
        </button>
      )}
    </div>
  );
};

export default ShowFestivalCard;