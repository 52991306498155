import axios from 'axios';
import { Genre, Show, ArtistVenueSearchResults, Artist, Venue, ArtistMatch, ReportData } from './definitions';

const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://myvibeliveapi.azurewebsites.net'; // https://myvibeliveapi.azurewebsites.net http://localhost:8080
const cloudinaryUrl = 'https://api.cloudinary.com/v1_1/dipflkbjl/image/upload'; // Cloudinary upload endpoint

// Send a contact message
export const sendContactMessage = async (data: { name: string; email: string; message: string }) => {
  try {
    const response = await axios.post(`${baseUrl}/api/contact/`, data);
    return response.data;
  } catch (error) {
    console.error('Failed to send contact message:', error);
    throw new Error('Failed to send contact message.');
  }
};

// Fetch unlinked artists
export const fetchUnlinkedArtists = async () => {
  try {
    const response = await axios.get<Artist[]>(`${baseUrl}/api/artist/unlinked-artists`); 
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch unlinked artists`, error);
    throw new Error("Failed to fetch unlinked artists."); // Ensure proper error handling
  }
};

// Inactivate an artist
export const inactivateArtist = async (artistId: number) => {
  try {
    const response = await axios.post(`${baseUrl}/api/artist/inactivate-artist?artistId=${artistId}`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch unlinked artists`, error);
    throw new Error("Failed to fetch unlinked artists."); // Ensure proper error handling
  }
};

export const fetchVenueReports = async (venueId: number): Promise<ReportData> => {
  try {
    const response = await axios.get<ReportData>(`${baseUrl}/api/analytics/venue/${venueId}/reports`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch venue reports for venue ID: ${venueId}`, error);
    throw new Error("Failed to fetch venue reports."); // Ensure proper error handling
  }
};

export const fetchArtistReports = async (artistId: number): Promise<ReportData> => {
  try {
    const response = await axios.get<ReportData>(`${baseUrl}/api/analytics/artist/${artistId}/reports`);
    return response.data;
  } catch (error) {
    console.error(`Failed to fetch artist reports for artist ID: ${artistId}`, error);
    throw new Error("Failed to fetch artist reports."); // Ensure proper error handling
  }
};

// Add the new logEvent function
export const logEvent = async (
  user_id: number | null, 
  action_type: string, 
  entity_id: number, 
  entity_type: string,
  additional_info: string | null = null // Optional additional information
): Promise<void> => {
  try {
    // Make a POST request to the analytics API
    await axios.post(`${baseUrl}/api/analytics/record`, {
      user_id,         // ID of the user performing the action (can be null for anonymous users)
      action_type,     // Action being performed (e.g., 'Visit')
      entity_id,       // ID of the entity being interacted with (e.g., the artist ID)
      entity_type,     // Type of entity (e.g., 'Artist')
      additional_info  // Optional additional details
    });
  } catch (error) {
    console.error('Error logging event:', error);
  }
};

export const uploadImage = async (
  file: File,
  imageKey: string,
  uploadPreset: string
): Promise<string> => {
  try {
    if (!file) {
      throw new Error("File is required for upload");
    }

    // Call the backend API to get the Cloudinary signature, timestamp, and API key
    const signatureResponse = await axios.post(`${baseUrl}/api/cloudinary/get-cloudinary-signature`, {
      imageKey,
      uploadPreset, // Pass the upload preset to the backend
    });

    const { signature, timestamp, api_key, public_id } = signatureResponse.data;

    // Prepare the form data to send to Cloudinary
    const formData = new FormData();
    formData.append('file', file); // The actual file being uploaded
    formData.append('upload_preset', uploadPreset); // Your upload preset for cover or thumbnail
    formData.append('public_id', public_id); // Custom file name for Cloudinary
    formData.append('api_key', api_key); // The API key from the signature response
    formData.append('timestamp', timestamp.toString()); // The timestamp from the signature response
    formData.append('signature', signature); // The signature from the signature response

    // Upload the image to Cloudinary
    const response = await axios.post(cloudinaryUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    // The response contains the file URL
    const fileUrl = response.data.secure_url;

    return fileUrl; // Return the uploaded image URL directly
  } catch (error) {
    console.error("Error uploading image to Cloudinary:", error);
    throw new Error("Failed to upload image to Cloudinary");
  }
};

export const createVenue = async (venue: Venue): Promise<Venue> => {
  try {
    // Make the POST request to create a new venue
    const { data } = await axios.post<Venue>(`${baseUrl}/api/venue`, venue);
    return data; // Return the newly created venue data
  } catch (error) {
    console.error('Error creating venue:', error);
    throw new Error('Failed to create venue');
  }
};

export const updateVenue = async (venue: Venue): Promise<Venue> => {
  try {
    // Make the PUT request to update the venue by venue_id
    const { data } = await axios.put<Venue>(`${baseUrl}/api/venue/${venue.venue_id}`, venue);
    return data; // Return the updated venue data
  } catch (error) {
    console.error('Error updating venue:', error);
    throw new Error('Failed to update venue');
  }
};

export const updateArtist = async (artist: Artist): Promise<Artist> => {
  try {
    // Include the artist object along with genres, defaulting to an empty array if undefined
    const artistData = {
      ...artist,
      genres: (artist.genres || []).map((genre) => genre.genre_id),  // Ensure genres is an array
    };

    // Make the PUT request to update the artist by artist_id
    const { data } = await axios.put<Artist>(`${baseUrl}/api/artist/${artist.artist_id}`, artistData);

    return data; // Return the updated artist data
  } catch (error) {
    console.error('Error updating artist:', error);
    throw new Error('Failed to update artist');
  }
};

export const fetchArtistVenueSearch = async (query: string): Promise<ArtistVenueSearchResults[]> => {
  const params = new URLSearchParams();
  params.append('query', query);

  try {
    const { data } = await axios.get<ArtistVenueSearchResults[]>(`${baseUrl}/api/venueAndArtist?${params.toString()}`);
    return data;
  } catch (error) {
    console.error('Error fetching artists and venues:', error);
    return []; // Return an empty array to satisfy the return type
  }
};

export const fetchArtist = async (artist_key: string): Promise<Artist> => {
  try { 
    const { data } = await axios.get<Artist>(`${baseUrl}/api/artist?artist_key=${artist_key}`);
    return data;
  } catch (error) {
    console.error('Error fetching artist:', error);
    throw new Error('Failed to fetch artist');
  }
};

export const fetchVenueByKey = async (venue_key: string): Promise<Venue> => {
  if (!venue_key) {
    throw new Error("venue_key must be provided");
  }

  const response = await axios.get<Venue>(`${baseUrl}/api/venue/by-key?venue_key=${venue_key}`);
  return response.data;
};

// Fetch artist by artist ID
export const fetchArtistById = async (artist_id: number): Promise<Artist> => {
  if (!artist_id) {
    throw new Error("artist_id must be provided");
  }

  const response = await axios.get<Artist>(`${baseUrl}/api/artist/by-id?artist_id=${artist_id}`);
  return response.data;
};

// Fetch artists by user ID
export const fetchArtistsByUserId = async (user_id: number): Promise<Artist[]> => {
  if (!user_id) {
    throw new Error("user ID must be provided");
  }

  const response = await axios.get<Artist[]>(`${baseUrl}/api/artist/by-userid?user_id=${user_id}`);
  return response.data;
};


export const fetchVenueById = async (venue_id: number): Promise<Venue> => {
  if (!venue_id) {
    throw new Error("venue_id must be provided");
  }

  const response = await axios.get<Venue>(`${baseUrl}/api/venue/by-id?venue_id=${venue_id}`);
  return response.data;
};

export const fetchVenuesByUserId = async (user_id: number): Promise<Venue[]> => {
  if (!user_id) {
    throw new Error("user ID must be provided");
  }

  const response = await axios.get<Venue[]>(`${baseUrl}/api/venue/by-userid?user_id=${user_id}`);
  return response.data;
};

export const fetchArtistMatches = async (): Promise<ArtistMatch[]> => {
  try { 
    const { data } = await axios.get<ArtistMatch[]>(`${baseUrl}/api/artist/matches`);
    return data;
  } catch (error) {
    console.error('Error fetching matches:', error);
    // Handle the error according to your application's needs
    if (axios.isAxiosError(error)) {
      // Handle Axios-specific error
      throw new Error(error.response?.data?.message || 'Failed to fetch matches');
    } else {
      // Handle non-Axios errors
      throw new Error('An unexpected error occurred');
    }
  }
};

export const fetchGenres = async (): Promise<Genre[]> => {
  try { 
    const { data } = await axios.get<Genre[]>(`${baseUrl}/api/genre`);
    return data;
  } catch (error) {
    console.error('Error fetching genres:', error);
    // Handle the error according to your application's needs
    throw new Error('Failed to fetch genres');
  }
};

export const fetchShows = async (
  show_key?: string | null, 
  artist_key?: string | null, 
  venue_key?: string | null, 
  selectedGenreId?: number | null, 
  selectedDistance?: string | null, 
  selectedTimePeriod?: string | null, 
  user_lat?: string | null, 
  user_lon?: string | null,
  show_id?: number | null,
  venue_id?: number | null,
  artist_id?: number | null  
): Promise<Show[]> => {
  try {
    // Build the query parameters
    const params = new URLSearchParams();
    
    if (show_key) params.append('show_key', show_key);
    if (artist_key) params.append('artist_key', artist_key);
    if (venue_key) params.append('venue_key', venue_key);
    if (venue_id) params.append('venue_id', venue_id.toString()); 
    if (artist_id) params.append('artist_id', artist_id.toString()); 
    if (selectedGenreId) params.append('genre_id', selectedGenreId.toString());
    if (selectedDistance) params.append('location', selectedDistance);
    if (selectedTimePeriod) params.append('date', selectedTimePeriod);
    if (user_lat) params.append('user_lat', user_lat);
    if (user_lon) params.append('user_lon', user_lon);
    if (show_id) params.append('show_id', show_id.toString());

    // Send the request with query string
    const { data } = await axios.get<Show[]>(`${baseUrl}/api/show?${params.toString()}`);
    return data;
  } catch (error) {
    console.error('Error fetching shows:', error);
    throw new Error('Failed to fetch shows');
  }
};

export const fetchShowById = async (showId: number): Promise<Show> => {
  try {
    const { data } = await axios.get<Show>(`${baseUrl}/api/show/${showId}`);
    return data;
  } catch (error) {
    console.error('Error fetching show:', error);
    throw new Error('Failed to fetch show');
  }
};

export const updateShow = async (show: {
  show_id: number;
  active: number;
  name?: string;
  description?: string;
  date_start?: string;
  date_end?: string;
  time_start?: string;
  image_full?: string;
  image_thumb?: string;
  price?: string;
  ticket_link?: string;
  flight_link?: string;
  official_link?: string;
  fb_link?: string;
  ig_link?: string;
  user_id?: number;
}): Promise<Show> => {
  try {
    const { show_id, ...updatedFields } = show;
    const { data } = await axios.put<Show>(`${baseUrl}/api/show/${show_id}`, updatedFields);
    
    return data;
  } catch (error) {
    // Check if the error is an AxiosError with a response
    if (axios.isAxiosError(error) && error.response) {
      console.error('Axios error:', error.response.data);
    } else if (error instanceof Error) {
      // If it's a regular error, print the message
      console.error('Error updating show:', error.message);
    } else {
      console.error('Unknown error', error);
    }
    
    throw new Error('Failed to update show');
  }
};

export const addShow = async (show: Show): Promise<Show> => {
  try {
    const { data } = await axios.post<Show>(`${baseUrl}/api/show`, show); // Changed to POST for adding a new show
    return data; // Return the newly added show data
  } catch (error) {
    console.error('Error adding show:', error);
    throw new Error('Failed to add show');
  }
};

export const deleteShow = async (show_id: number): Promise<void> => {
  try {
    // Sending a PATCH or PUT request to update the active field to 0
    await axios.put(`${baseUrl}/api/show`, { show_id, active: 0 }); // Send show_id in the body
  } catch (error) {
    console.error('Error deleting (deactivating) show:', error);
    throw new Error('Failed to deactivate show');
  }
};