"use client";

import React, { useState } from "react";
import { Show, Genre } from "../lib/definitions";

/* 🔹 Current Show Component */
interface CurrentShowProps {
  time: string;
  period: string;
  venue: string;
  location: string;
}

export const CurrentShow: React.FC<CurrentShowProps> = ({
  time,
  period,
  venue,
  location,
}) => (
  <article className="flex items-center gap-4 p-3 w-full rounded-lg bg-zinc-900 text-white shadow-md">
    <div className="flex flex-col items-center">
      <time className="text-3xl font-bold leading-tight">{time}</time>
      <span className="text-sm font-medium">{period}</span>
    </div>
    <div className="flex flex-col flex-1">
      <h2 className="text-base font-semibold">{venue}</h2>
      <p className="text-xs text-zinc-400">{location}</p>
    </div>
  </article>
);

/* 🔹 Show Option Component */
interface ShowOptionProps {
  artist: string;
  genre: string;
  venue: string;
  time: string;
  onSelect: () => void;
}

export const ShowOption: React.FC<ShowOptionProps> = ({
  artist,
  genre,
  venue,
  time,
  onSelect,
}) => (
  <div className="flex items-center justify-between p-3 bg-gray-100 rounded-md">
    {/* Artist & Genre (Allow wrapping) */}
    <div className="flex flex-col text-left w-[120px] min-w-[120px]">
      <h3 className="text-sm font-semibold leading-tight break-words">{artist}</h3>
      <p className="text-xs text-gray-500 break-words">{genre}</p>
    </div>

    {/* Time & Venue (Aligned, More Space) */}
    <div className="flex flex-col items-start text-left w-[90px] min-w-[90px]"> 
      <p className="text-xs font-semibold text-gray-700">{formatTime(time)}</p>
      <p className="text-xs text-gray-600 whitespace-normal">{venue}</p>
    </div>

    {/* Select Show Button (Smaller & Text on Two Lines) */}
    <button
      onClick={onSelect}
      className="px-2 py-1 text-xs font-medium text-white bg-zinc-900 rounded-md text-center leading-tight w-[60px] min-w-[60px]"
    >
      Select <br /> Show
    </button>
  </div>
);

interface ShowListProps {
  shows: Show[];
  onSelectShow: (show: Show) => void;
}

export const ShowList: React.FC<ShowListProps> = ({ shows, onSelectShow }) => (
  <section className="p-3 bg-gray-200 rounded-lg space-y-2">
    {shows.map((show) => (
      <ShowOption
        key={show.show_id}
        artist={show.name || ""}
        genre={parseGenres(show.genres)}
        venue={show.venues?.[0]?.name ?? "Unknown Venue"}
        time={show.time_start || ""}
        onSelect={() => onSelectShow(show)}
      />
    ))}
  </section>
);

/* 🔹 Itinerary Swap Show Component */
interface SwapShowProps {
  availableShows: Show[];
  selectedShow: Show | null; // ✅ Add this line
  setSelectedShow: React.Dispatch<React.SetStateAction<Show | null>>; // ✅ Also include this if you're updating state
  onSelectShow: (show: Show) => void;
  onCancel: () => void;
}

const ItinerarySwapShow: React.FC<SwapShowProps> = ({
  availableShows,
  selectedShow,
  setSelectedShow,
  onSelectShow,
  onCancel,
}) => {
  const [currentSelectedShow, setCurrentSelectedShow] = useState<Show | null>(selectedShow);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 overflow-y-auto">
      <div className="bg-white p-5 rounded-lg shadow-xl max-w-sm w-full max-h-[80vh] overflow-y-auto">
        <h2 className="text-2xl font-bold text-zinc-900 text-center">
          {selectedShow ? "Swap Show" : "Add Show"}
        </h2>
        <p className="text-xs text-gray-600 text-center mb-3">
          Select which show you’d like to {selectedShow ? "swap" : "add"}
        </p>

        {selectedShow && (
          <div className="mb-3">
            <CurrentShow
              time={formatTime(selectedShow.time_start ?? "").split(" ")[0]}
              period={formatTime(selectedShow.time_start ?? "").split(" ")[1]}
              venue={selectedShow.name ?? "Unknown Show"}
              location={selectedShow.venues?.[0]?.name ?? "Unknown Venue"}
            />
          </div>
        )}

        {/* ✅ Display a message when no shows are available */}
        {availableShows.length === 0 ? (
          <p className="text-center text-gray-500 my-4">No shows available</p>
        ) : (
          <div className="max-h-60 overflow-y-auto">
            <ShowList
              shows={availableShows}
              onSelectShow={(show) => {
                setCurrentSelectedShow(show);
                setSelectedShow(show); // Let useEffect handle the swap
              }}
            />
          </div>
        )}

        <div className="mt-4">
          <button
            className="w-full py-2 text-sm font-medium text-gray-800 border border-gray-400 rounded-md"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

/* 🔹 Helper Functions */
const formatTime = (time: string) => {
  if (!time) return "TBD"; // Handle missing time

  try {
    const dateObj = new Date(time);
    
    // Extract local time components
    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour format

    return `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  } catch (error) {
    console.error("⚠️ Error parsing time:", time, error);
    return "Invalid Time";
  }
};

const parseGenres = (genres: string | Genre[] | undefined): string => {
  if (!genres) return "Unknown Genre";
  if (typeof genres === "string") return genres;

  try {
    return Array.isArray(genres)
      ? genres.map((g) => (typeof g === "string" ? g : g.description)).join(", ")
      : "Unknown Genre";
  } catch {
    return "Unknown Genre";
  }
};

export default ItinerarySwapShow;