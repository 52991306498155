export const getBrowserLocation = async (): Promise<{ lat: number; lon: number }> => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            });
          },
          (error) => {
            reject(new Error("Failed to retrieve browser location: " + error.message));
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };
  
  export const getIpBasedLocation = async (): Promise<{ lat: number; lon: number }> => {
    try {
      const ip = await getPublicIp();
      const apiKey = process.env.REACT_APP_MAXMIND_KEY;
      const response = await fetch(`https://geoip.maxmind.com/geoip/v2.1/city/${ip}?key=${apiKey}`);
  
      if (!response.ok) {
        throw new Error("Failed to retrieve IP-based location");
      }
  
      const locationData = await response.json();
      return {
        lat: locationData.location.latitude,
        lon: locationData.location.longitude,
      };
    } catch (error) {
      throw new Error("IP-based Geolocation Error: " + (error instanceof Error ? error.message : "Unknown error occurred"));
    }
  };
  
  export const getLocationFromGoogle = async (): Promise<{ lat: number; lon: number }> => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(`https://www.googleapis.com/geolocation/v1/geolocate?key=${apiKey}`, { method: "POST" });
  
      if (!response.ok) {
        throw new Error("Failed to retrieve location from Google API");
      }
  
      const data = await response.json();
      if (data.location) {
        return { lat: data.location.lat, lon: data.location.lng };
      } else {
        throw new Error("Google Geolocation API did not return location");
      }
    } catch (error) {
      throw new Error("Google Geolocation API Error: " + (error instanceof Error ? error.message : "Unknown error occurred"));
    }
  };
  
  export const getPublicIp = async (): Promise<string> => {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  };
  
  // **Main function to try all methods to get location**
  export const getLocation = async (): Promise<{ lat: string; lon: string; method: string }> => {
    try {
      const browserLocation = await getBrowserLocation();
      return { lat: browserLocation.lat.toString(), lon: browserLocation.lon.toString(), method: "Browser" };
    } catch (error) {
      console.error("Error retrieving browser location:", error);
    }
  
    try {
      const googleLocation = await getLocationFromGoogle();
      return { lat: googleLocation.lat.toString(), lon: googleLocation.lon.toString(), method: "Google" };
    } catch (error) {
      console.error("Error retrieving Google location:", error);
    }
  
    try {
      const ipLocation = await getIpBasedLocation();
      return { lat: ipLocation.lat.toString(), lon: ipLocation.lon.toString(), method: "IP" };
    } catch (error) {
      console.error("Error retrieving IP-based location:", error);
    }
  
    throw new Error("Failed to determine location");
  };