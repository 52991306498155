import React from "react";
import ShowHeader from "./ShowHeader";
import ArtistLineup from "./ArtistLineup";
import { Show, Venue, Genre, Artist } from "../lib/definitions";


interface ShowCardProps {
  name: string;
  date: string;
  time: string;
  show: Show;
  venue: Venue[];
  genres: Genre[];
  artists: Artist[];
}

const ShowCard: React.FC<ShowCardProps> = ({
  name,
  date,
  time,
  show,
  venue,
  genres,
  artists,
}) => {
  return (
    <section className="mb-6 flex overflow-hidden flex-col w-full bg-white rounded-2xl  max-h-[600px]">
      <div className="flex flex-col px-4 py-6 w-full bg-zinc-950">
        <ShowHeader name={name} date={date} time={time} show={show} venue={venue} genres={genres}/>
      </div>
      <ArtistLineup artists={artists} show_image_thumb={show.image_thumb} />
    </section>
  );
};

export default ShowCard;
