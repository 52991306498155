import React from "react";

const NoShows: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center text-center py-12">
      {/* Image */}
      <img
        src="no_shows.webp" // Update the path to the correct location of the image
        alt="No shows available"
        className="w-full max-w-xs h-auto rounded-lg"
      />

      {/* Message */}
      <h2 className="text-2xl font-bold text-zinc-950 mt-6">No shows in your area</h2>
      <p className="text-sm text-zinc-400 mt-2 max-w-lg">
        Follow us and we'll let you know when we're in your area!
      </p>

      {/* Social links */}
      <div className="flex gap-4 mt-4">
        <a
        href="https://www.facebook.com/profile.php?id=61565715673369"
        className="flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        >
        <img
            src="icon-facebook-color.png" 
            alt="Facebook"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
        />
        </a>
        <a
        href="https://www.instagram.com/myvibelive/"
        className="flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        >
        <img
            src="icon-instagram-color.jpg" 
            alt="Instagram"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
        />
        </a>  
        <a
        href="https://www.tiktok.com/@myvibe.live"
        className="flex items-center"
        target="_blank"
        rel="noopener noreferrer"
        >
        <img
            src="icon-tiktok-color.png" 
            alt="TikTok"
            className="w-8 h-8 mr-2 rounded-full" // Adjust size and spacing
        />
        </a>               
      </div>
    </div>
  );
};

export default NoShows;