import React from 'react';

interface TimeFieldProps {
  label: string;
  value: string;
  onChange: (value: string | null) => void;
  containerClassName?: string;
}

const TimeField: React.FC<TimeFieldProps> = ({
  label,
  value,
  onChange,
  containerClassName = "",
}) => {
  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChange(newValue || null); // Pass the new value or null if cleared
  };

  return (
    <div className={`flex flex-col mt-2 w-full ${containerClassName}`}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <div className="w-full pt-2">
        <input
          type="time"
          value={value}  // Pass the value directly, no need to reformat here
          onChange={handleTimeChange}  // Handle the change event
          className="w-full p-2 rounded-md border border-gray-700"
        />
      </div>
    </div>
  );
};

export default TimeField;