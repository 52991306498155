import React, { useState, useEffect } from "react";
import { FaShareAlt, FaCalendarPlus, FaSave, FaMusic } from "react-icons/fa";
import { FaLock, FaLockOpen, FaTrash, FaRecycle } from "react-icons/fa";
import { Show } from "../lib/definitions";
import { removeFromItinerary, toggleLock, fetchPlaylistArtists, addToItinerary, fetchItinerary, resetItinerary } from "../lib/data";
import { ItineraryAddShow } from "./ItineraryAddShow";
import { ItineraryDisplayShow } from "./ItineraryDisplayShow";
import ItinerarySwapShow from "./ItinerarySwapShow";
import { useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Action } from "../festival/festival";


const ItineraryDateNav: React.FC<{ activeDay: string; setActiveDay: (day: string) => void; uniqueDays: string[] }> = ({
  activeDay,
  setActiveDay,
  uniqueDays,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = 150; // Adjust scroll speed
      scrollRef.current.scrollBy({ left: direction === "left" ? -scrollAmount : scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="relative flex items-center w-full">
      {/* Left Scroll Button */}
      <button
        onClick={() => scroll("left")}
        className="p-2 mx-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-300"
        aria-label="Scroll left"
      >
        <FaChevronLeft />
      </button>

      {/* Scrollable Date List */}
      <nav
        ref={scrollRef}
        className="flex gap-2 px-2 pt-2  mb-2 overflow-x-auto no-scrollbar w-full"
        style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }} // Hide scrollbar
      >
        {uniqueDays.map((day, index) => {
          const adjustedDate = new Date(day + "T12:00:00"); // Normalize timezone display
          const formattedDate = adjustedDate.toLocaleDateString("en-US", {
            weekday: "short",
            month: "short",
            day: "numeric",
          });

          return (
            <button
              key={index}
              onClick={() => setActiveDay(day)}
              className={`whitespace-nowrap px-3 py-2 text-sm font-medium rounded-lg transition-colors
                ${activeDay === day ? "bg-zinc-950 text-white" : "text-zinc-700 hover:bg-gray-200"}`}
              aria-pressed={activeDay === day}
            >
              {formattedDate}
            </button>
          );
        })}
      </nav>

      {/* Right Scroll Button */}
      <button
        onClick={() => scroll("right")}
        className="p-2 mx-2 rounded-full bg-gray-100 text-gray-600 hover:bg-gray-300"
        aria-label="Scroll right"
      >
        <FaChevronRight />
      </button>
    </div>
  );
};

interface ItineraryProps {
  itineraryKey: string;
  festivalKey?: string;
  shows: Show[];
  allShows: Show[];
  refetch: () => void;
  playlist: string;
  email: string | null;
  isLoading: boolean;
  toggleSelectionMode: () => void;
  updateSelectedShows: (showKey: string) => void; 
  removeSelectedShow: (showKey: string) => void;
  dispatch: React.Dispatch<Action>; 
}

const Itinerary: React.FC<ItineraryProps> = ({ 
  shows, 
  allShows, 
  itineraryKey, 
  festivalKey, 
  refetch, 
  playlist, 
  email, 
  toggleSelectionMode,
  updateSelectedShows,
  removeSelectedShow,
  dispatch
}) => {
  // Get all unique festival days (even if no shows exist)
  const uniqueDays: string[] = Array.from(
    new Set(
      allShows
        .map((show) => {
          if (!show.date_start) {
            console.warn("⚠️ Skipping show with missing date_start:", show);
            return null;
          }
  
          const date = new Date(show.date_start);
          if (isNaN(date.getTime())) {
            console.warn("⚠️ Skipping show with invalid date:", show.date_start);
            return null;
          }
  
          return date.toISOString().split("T")[0]; // ✅ Reliable formatting
        })
        .filter((day): day is string => day !== null) // ✅ Remove null values
    )
  ).sort((a, b) => new Date(a).getTime() - new Date(b).getTime()); // ✅ Ensure chronological order

  const [localShows, setLocalShows] = useState<Show[]>([]);
  const [selectedGapIndex, setSelectedGapIndex] = useState<number | null>(null);
  const [fetchedArtists, setFetchedArtists] = useState<{ id: string }[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedShow, setSelectedShow] = useState<Show | null>(null);
  const [swapShow, setSwapShow] = useState<Show | null>(null);  
  const [availableShows, setAvailableShows] = useState<Show[]>([]);
  const [previousShow, setPreviousShow] = useState<Show | null>(null);
  const [validShows, setValidShows] = useState<Show[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Initialize loading state
  const activeDayRef = useRef<string>(uniqueDays[0] ?? "");
  const [activeDay, _setActiveDay] = useState<string>(uniqueDays[0] ?? "");
  
  // Function to update both the ref and state
  const setActiveDay = (day: string) => {
    activeDayRef.current = day;
    _setActiveDay(day);
    localStorage.setItem("activeDay", day); // ✅ Persist in localStorage
  };

  useEffect(() => {
    console.log("👀 Checking for ready state", { selectedShow, previousShow });
  
    if (selectedShow && previousShow) {
      handleSelectShow();
    }
  }, [selectedShow, previousShow]);

  useEffect(() => {
    if (uniqueDays.length === 0) return;
  
    const storedActiveDay = localStorage.getItem("activeDay");
  
    if (storedActiveDay && uniqueDays.includes(storedActiveDay)) {
      setActiveDay(storedActiveDay);
    } else if (!uniqueDays.includes(activeDayRef.current)) {
      setActiveDay(uniqueDays[0]);
    }
  }, [uniqueDays]); // ✅ Does not depend on activeDay to avoid unnecessary resets

  useEffect(() => {
    const updatedShows = validShows.filter((show) => {
      const showDate = new Date(show.date_start ?? "").toISOString().split("T")[0];
      return showDate === activeDay;
    });
    
    if (JSON.stringify(updatedShows) !== JSON.stringify(localShows)) {
      setLocalShows([...updatedShows]); // Force state update
    }
  }, [activeDay, validShows]);

  useEffect(() => {
    setIsLoading(true);
    if (playlist && email) {     
      fetchPlaylistArtists(email, playlist)
        .then((artistsFromPlaylist) => {
          setFetchedArtists(artistsFromPlaylist);
        })
        .catch((error) => console.error("❌ Error fetching artists:", error));
    }
    setIsLoading(false);
  }, [playlist, email]);

  useEffect(() => {
    setLocalShows([...shows]); // ✅ Update state immediately when `shows` updates
  }, [shows]);

  // Helper function to generate a Google Calendar event link
  const createGoogleCalendarLink = (filteredShows: Show[]) => {
    if (!filteredShows.length) {
      console.error("❌ No shows provided for Google Calendar link.");
      return "#";
    }

    // Safely get festival details
    const festivalStart = shows.length > 0 && shows[0]?.festival?.[0]?.date_start
      ? new Date(shows[0].festival[0].date_start)
      : null;

    const festivalEnd = shows.length > 0 && shows[0]?.festival?.[0]?.date_end
      ? new Date(shows[0].festival[0].date_end)
      : null;

    if (!festivalStart || !festivalEnd || isNaN(festivalStart.getTime()) || isNaN(festivalEnd.getTime())) {
      console.error("❌ Invalid festival start or end date.");
      return "#";
    }

    // Format for Google Calendar (YYYYMMDD/YYYYMMDD for all-day events)
    const formatGoogleDate = (date: Date) => date.toISOString().split("T")[0].replace(/-/g, "");

    const formattedStart = formatGoogleDate(festivalStart);
    const formattedEnd = formatGoogleDate(festivalEnd);

    // Safely get festival name
    const festivalName = shows.length > 0 && shows[0]?.festival?.[0]?.name
      ? shows[0].festival[0].name
      : "My Itinerary";

    // Safely get festival_key
    const festivalKey = shows.length > 0 && shows[0]?.festival?.[0]?.festival_key
      ? shows[0].festival[0].festival_key
      : "";

    // Safely get location
    const location = shows.length > 0 && shows[0]?.festival?.[0]?.location
      ? shows[0].festival[0].location
      : "Various Venues";

    // Create itinerary URL
    const itineraryUrl = `https://myvibelive-qa-fgc0athbe4g4b9fv.canadacentral-01.azurewebsites.net/festival?${festivalKey ? `festival_key=${festivalKey}&` : ""}mode=itinerary&itinerarykey=${itineraryKey}`;

    // Updated event details with itinerary link and festival name
    const eventName = encodeURIComponent(`${festivalName}`);
    const details = encodeURIComponent(
      `🎤 **My ${festivalName} Live Music Lineup is Set!**\n\n` +
      `Check out my itinerary for ${festivalName} and discover the shows I’ll be attending. ` +
      `View it here: ${itineraryUrl}`
    );

    // Google Calendar all-day event format: dates=YYYYMMDD/YYYYMMDD
    const calendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${eventName}&dates=${formattedStart}/${formattedEnd}&details=${details}&location=${encodeURIComponent(location)}`;

    return calendarUrl;
  };

  const festivalName = shows.length > 0 && shows[0]?.festival?.[0]?.name 
  ? shows[0].festival[0].name 
  : "myVibe.live";

  // Function to handle sharing itinerary
  const handleShare = async (itineraryKey: string) => {
    const shareUrl = `https://myvibelive-qa-fgc0athbe4g4b9fv.canadacentral-01.azurewebsites.net/festival?festival_key=${shows[0]?.festival?.[0]?.festival_key}&mode=itinerary&itinerary_key=${itineraryKey}`;

    if (navigator.share) {
      try {
        await navigator.share({
          title: `Check out my "${festivalName}" itinerary!`,
          text: "Here are the shows I'm planning to attend:",
          url: shareUrl,
        });
      } catch (error) {
        console.error("Error sharing itinerary:", error);
      }
    } else {
      // Fallback: Copy link to clipboard
      navigator.clipboard.writeText(shareUrl);
      alert("Link copied to clipboard!");
    }
  };

  // Optimize sorting performance
  const filteredShows = localShows.sort((a, b) => 
    new Date(a.time_start || "").getTime() - new Date(b.time_start || "").getTime()
  );
  
  const safeDate = (value?: string | null): Date | null => {
    if (!value) return null;
    const date = new Date(value);
    if (isNaN(date.getTime())) {
      console.warn("❌ Invalid date value in safeDate:", value);
      return null;
    }
    return new Date(date.toISOString()); // ✅ Normalize to UTC
  };

  const createDateTime = (date?: string, time?: string): Date | null => {
    if (!date) return null;
    const cleanedDate = date.split("T")[0];
  
    // Handle missing time
    const cleanedTime = time?.includes(":") ? time.split("T").pop()?.replace("Z", "") : "00:00:00";
  
    if (!cleanedTime) {
      console.warn("❌ createDateTime failed to extract valid time:", { time });
      return null;
    }
  
    const dateTimeString = `${cleanedDate}T${cleanedTime}Z`;
    const dateTime = new Date(dateTimeString);
  
    if (isNaN(dateTime.getTime())) {
      console.warn("❌ createDateTime failed to create a valid Date object:", { dateTimeString });
      return null;
    }
  
    return dateTime;
  };

  const handleDelete = async (showId: number | undefined, showKey?: string) => {
    if (!showId) return;
  
    setIsLoading(true);
  
    try {
      // ✅ Optimistically remove from UI first
      setLocalShows((prev) => prev.filter((show) => show.show_id !== showId));
      setValidShows((prev) => prev.filter((show) => show.show_id !== showId));
  
      if (showKey) {
        removeSelectedShow(showKey);
      }
  
      // ✅ Remove from the backend
      await removeFromItinerary(showId, itineraryKey);
  
      // ✅ Force a UI refresh
      await refetch();
    } catch (error) {
      console.error("❌ Error removing show:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLockToggle = async (showId: number | undefined) => {
    if (!showId) return;
  
    setIsLoading(true);
    try {
      const showToUpdate = validShows.find(show => show.show_id === showId);
      if (!showToUpdate) return;
  
      const newLockState = !showToUpdate.locked;
      await toggleLock(itineraryKey, showId, newLockState, refetch);
  
      setValidShows(
        validShows.map(show =>
          show.show_id === showId ? { ...show, locked: newLockState } : show
        )
      );
    } catch (error) {
      console.error("Error updating lock status:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const [showResetModal, setShowResetModal] = useState(false);

  const handleResetAll = async () => {
    if (!itineraryKey) return;
  
    setIsLoading(true);
    try {
      // ✅ Remove all shows from backend
      await resetItinerary(itineraryKey);
  
      // ✅ Clear state immediately
      setLocalShows([]);
      setValidShows([]);
  
      // ✅ Refetch from backend
      await refetch();
    } catch (error) {
      console.error("❌ Error resetting itinerary:", error);
    } finally {
      setIsLoading(false);
      setShowResetModal(false); // Close modal after resetting
    }
  };
  
  useEffect(() => {
    if (selectedShow && previousShow) {
      handleSelectShow();
    }
  }, [selectedShow, previousShow]);

  const handleSaveItinerary = async () => {}

  const handleCreatePlaylist = async () => {}

  const handleChange = (showId: number | undefined) => {
    if (!showId) return;

    const showToReplace = localShows.find((show) => show.show_id === showId);
    if (!showToReplace) {
        console.error("❌ Show not found in localShows.");
        return;
    }
    
    // ✅ Update previousShow immediately and then open modal
    setPreviousShow(showToReplace);
    setSwapShow({
        show_id: showToReplace.show_id ?? 0,
        name: showToReplace.name ?? "Unknown Show",
        time_start: showToReplace.time_start ?? "00:00:00",
        venues: showToReplace.venues?.map((venue) => ({
            name: venue.name ?? "Unknown Venue",
        })) ?? [],
    });


    const selectedShowIndex = localShows.findIndex((show) => show.show_id === showId);
    if (selectedShowIndex === -1) {
        console.error("❌ Selected show not found in localShows.");
        return;
    }

    let gapStart: Date | null = null;
    let gapEnd: Date | null = null;

    if (selectedShowIndex === 0) {
        gapStart = createDateTime(localShows[selectedShowIndex].date_start, localShows[selectedShowIndex].time_start);
        gapEnd = createDateTime(localShows[selectedShowIndex].date_start, localShows[selectedShowIndex].time_end);
    } else if (selectedShowIndex === localShows.length - 1) {
        gapStart = createDateTime(localShows[selectedShowIndex].date_start, localShows[selectedShowIndex].time_start);
        gapEnd = createDateTime(localShows[selectedShowIndex].date_start, localShows[selectedShowIndex].time_end);
    } else {
        gapStart = createDateTime(
            localShows[selectedShowIndex - 1]?.date_start,
            localShows[selectedShowIndex - 1]?.time_end
        );
        gapEnd = createDateTime(
            localShows[selectedShowIndex + 1]?.date_start,
            localShows[selectedShowIndex + 1]?.time_start
        );
    }

    if (!gapStart || !gapEnd) {
        console.error("❌ Invalid gap boundaries.");
        return;
    }

    const available = allShows
        .filter((show) => {
            const showStart = createDateTime(show.date_start, show.time_start);
            const showEnd = createDateTime(show.date_start, show.time_end);

            return (
                showStart &&
                showEnd &&
                safeDate(show.date_start)?.toISOString().split("T")[0] === activeDay &&
                showStart >= gapStart &&
                showEnd <= gapEnd &&
                !localShows.some((s) => s.show_id === show.show_id)
            );
        })
        .sort((a, b) => {
            const startA = new Date(a.time_start || "").getTime();
            const startB = new Date(b.time_start || "").getTime();
            return startA - startB;
        });

    setAvailableShows(available);
  };

  const handleSelectShow = async () => {
    // ✅ Ensure `previousShow` is set before proceeding
    if (!selectedShow || !previousShow) {
      console.warn("❌ handleSelectShow aborted - Missing selectedShow or previousShow", { selectedShow, previousShow });
      return;
    }

    setIsLoading(true);

    try {
        await removeFromItinerary(previousShow.show_id || 0, itineraryKey);

        setLocalShows((prev) => prev.filter((show) => show.show_id !== previousShow.show_id));
        setValidShows((prev) => prev.filter((show) => show.show_id !== previousShow.show_id));

        dispatch({
            type: "REMOVE_SHOW",
            payload: previousShow.show_key ?? "",
        });

        await addToItinerary(itineraryKey, selectedShow.show_id ?? 0);

        setLocalShows((prev) => [...prev, selectedShow]);
        setValidShows((prev) => [...prev, selectedShow]);

        dispatch({
            type: "TOGGLE_SHOW",
            payload: selectedShow.show_key ?? "",
        });

        await refetch();

        const updatedItinerary = await fetchItinerary(itineraryKey);

        dispatch({
            type: "SET_SELECTED_SHOWS",
            payload: updatedItinerary.map((show) => show.show_key),
        });

        setValidShows(updatedItinerary);
        setLocalShows(updatedItinerary);

    } catch (error) {
        console.error("❌ Error swapping show:", error);
    } finally {
        setShowModal(false);
        setPreviousShow(null);
        setSelectedShow(null);
        setIsLoading(false);
    }
  };

  const handleOpenAddShowModal = (index: number, isGap: boolean) => {
    setSwapShow(null);
  
    let gapStart: Date | null = null;
    let gapEnd: Date | null = null;
  
    if (filteredShows.length === 0) {
      gapStart = new Date(activeDay + "T00:00:00");
      gapEnd = new Date(activeDay + "T23:59:59");
    } else if (index === 0) {
      gapEnd = createDateTime(filteredShows[0]?.date_start, filteredShows[0]?.time_start);
      gapStart = new Date(activeDay + "T00:00:00");
    } else if (index === filteredShows.length) {
      gapStart = createDateTime(filteredShows[filteredShows.length - 1]?.date_start, filteredShows[filteredShows.length - 1]?.time_end);
      gapEnd = new Date(activeDay + "T23:59:59");
    } else {
      gapStart = createDateTime(filteredShows[index - 1]?.date_start, filteredShows[index - 1]?.time_end);
      gapEnd = createDateTime(filteredShows[index]?.date_start, filteredShows[index]?.time_start);
    }
  
    if (gapStart && gapEnd) {
      const available = allShows.filter((show) => {
        const showStart = createDateTime(show.date_start, show.time_start);
        const showEnd = createDateTime(show.date_start, show.time_end);
  
        return (
          showStart &&
          showEnd &&
          safeDate(show.date_start)?.toISOString().split("T")[0] === activeDay &&
          showStart >= gapStart &&
          showEnd <= gapEnd &&
          !localShows.some((s) => s.show_id === show.show_id)
        );
      });
  
      setAvailableShows(available);
    }
  
    setShowModal(true);
  };
  
  const formatTime = (time: string) => {
    const timeMatch = time.match(/T(\d{2}):(\d{2}):/);
    if (!timeMatch) return "TBD";

    let hours = parseInt(timeMatch[1], 10);
    const minutes = timeMatch[2];
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const loadItinerary = async () => {
    setIsLoading(true);
    try {
      const fetchedItinerary = await fetchItinerary(itineraryKey);
      
      const updatedShows = shows.map((show) => ({
        ...show,
        locked: fetchedItinerary.some(
          (itin) => itin.show_id === show.show_id && itin.locked
        ),
      }));

      setValidShows([...updatedShows]); // ✅ Force state update
    } catch (error) {
      console.error("❌ Error fetching itinerary:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shows.length > 0 && validShows.length === 0) {
      loadItinerary();
    }
  }, [shows]);
  

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img
          src="logo-animate-gray.gif"
          alt="Loading..."
          className="max-w-[20rem] md:max-w-[25rem] lg:max-w-[30rem] object-contain"
        />
      </div>
    );
  }

  return (
    <main className="px-4 py-6 mx-auto my-0 bg-white rounded-2xl max-w-[762px] max-md:px-3 max-md:py-5 max-sm:px-2 max-sm:py-4 max-sm:rounded-xl">
      <header className="flex items-center gap-3 pr-4 mb-2 max-sm:gap-2 max-sm:flex-row max-sm:items-center">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/9a300a1121387a34bc2491d8805516015ca3651f"
          alt="Mile of Music Logo"
          className="rounded h-12 w-12 max-sm:h-10 max-sm:w-10"
        />
        <div className="flex flex-col leading-tight">
          <h1 className="text-base font-bold tracking-tight text-zinc-950">
          {shows.length > 0 && shows[0]?.festival?.[0]?.name}
          </h1>
          <p className="text-sm font-medium text-zinc-950">
            {shows.length > 0 && shows[0]?.festival?.[0]?.date_start && shows[0]?.festival?.[0]?.date_end
              ? `${new Date(shows[0].festival[0].date_start).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })} - ${new Date(shows[0].festival[0].date_end).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                })}`
              : "Festival Dates"}
          </p>
        </div>
      </header>

      {/* ✅ "Back to Artist Selection" Button (Moved Here) */}
      <div className="mb-2">
      <button
        onClick={toggleSelectionMode}
        className="flex items-center gap-2 p-2 mt-4 text-sm font-medium rounded-md border border-solid 
                  cursor-pointer border-zinc-700 text-zinc-950 hover:bg-gray-200 transition"
      >
        <FaChevronLeft /> Show Selection
      </button>
      </div>

      <div className="max-w-2xl mx-auto px-1 py-2">
        <div className="space-y-4">
          {/* Date Tabs */}
          <ItineraryDateNav activeDay={activeDay} setActiveDay={setActiveDay} uniqueDays={uniqueDays} />
  
          {/* ✅ Check for available shows BEFORE the first show */}
          {(() => {
            if (filteredShows.length === 0) return null; // ✅ Ensure there's at least one show

            const firstShowStart = createDateTime(filteredShows[0].date_start, filteredShows[0].time_start);
            if (!firstShowStart) return null;

            const availableBeforeFirstShow = allShows.filter((s) => {
              const showStart = createDateTime(s.date_start, s.time_start);
              return showStart && showStart < firstShowStart && safeDate(s.date_start)?.toISOString().split("T")[0] === activeDay;
            });

            return availableBeforeFirstShow.length > 0 ? (
              <ItineraryAddShow key="add-show-start-day" onClick={() => handleOpenAddShowModal(0, true)} />
            ) : null;
          })()}

          {/* ✅ Show List with Add Show Buttons */}
          {filteredShows.reduce<React.ReactNode[]>((acc, show, index) => {
            const previousShowEnd = index > 0 ? createDateTime(filteredShows[index - 1].date_start, filteredShows[index - 1].time_end) : null;
            const currentShowStart = createDateTime(show.date_start, show.time_start);

            // ✅ Check if there are available shows **in this time gap**
            const availableGapShows = allShows.filter((s) => {
              const showStart = createDateTime(s.date_start, s.time_start);
              const showEnd = createDateTime(s.date_start, s.time_end);

              return (
                showStart &&
                showEnd &&
                safeDate(s.date_start)?.toISOString().split("T")[0] === activeDay &&
                previousShowEnd &&
                currentShowStart &&
                showStart >= previousShowEnd &&
                showEnd <= currentShowStart &&
                !localShows.some((ls) => ls.show_id === s.show_id)
              );
            });

            // ✅ Only show "Add Show" button if there are available shows in this gap
            if (previousShowEnd && currentShowStart && availableGapShows.length > 0) {
              acc.push(
                <ItineraryAddShow key={`add-show-${index}`} onClick={() => handleOpenAddShowModal(index, true)} />
              );
            }

            // ✅ Display the show
            acc.push(
              <ItineraryDisplayShow
                key={`show-${show.show_id}`}
                time={{
                  hour: formatTime(show.time_start ?? "").split(" ")[0], // Extract hour
                  period: formatTime(show.time_start ?? "").split(" ")[1], // Extract AM/PM
                }}
                venue={{
                  name: show.name || "",
                  detail: show.venues?.[0]?.name ?? "Unknown Venue",
                }}
                locked={show.locked || false}
                onLockToggle={() => handleLockToggle(show.show_id)}
                onDelete={() => handleDelete(show.show_id, show.show_key)}
                onChange={() => handleChange(show.show_id)}
                show={show}
              />
            );

            return acc;
          }, [])}

          {/* ✅ Add Show Button AFTER Last Show - Only if there are available shows in this last gap */}
          {(() => {
            if (filteredShows.length === 0) return null;

            const lastShow = filteredShows[filteredShows.length - 1];
            const lastShowEnd = createDateTime(lastShow.date_start, lastShow.time_end);
            
            // ✅ Find available shows **on the same day** after the last show
            const availableEndGapShows = allShows.filter((s) => {
              const showStart = createDateTime(s.date_start, s.time_start);
              const showDate = safeDate(s.date_start)?.toISOString().split("T")[0]; // Get only the date part

              return (
                showStart &&
                lastShowEnd &&
                showDate === activeDay && // ✅ Ensure the show is still on the same day
                showStart >= lastShowEnd && // ✅ Ensure it's after the last show
                showStart.getHours() < 23 // ✅ Ensure it's before midnight
              );
            });

            return availableEndGapShows.length > 0 ? (
              <ItineraryAddShow key="add-show-after-last" onClick={() => handleOpenAddShowModal(filteredShows.length, true)} />
            ) : null;
          })()}

          {/* ✅ If no shows exist for the day, show the "Add Show" button **only if shows are available** */}
          {(() => {
            const availableShowsToday = allShows.filter((s) => safeDate(s.date_start)?.toISOString().split("T")[0] === activeDay);
            return filteredShows.length === 0 && availableShowsToday.length > 0 ? (
              <ItineraryAddShow onClick={() => handleOpenAddShowModal(0, true)} />
            ) : null;
          })()}
  
            <footer className="flex flex-wrap justify-between gap-4 mt-6">
            {/* Share Button */}
            <button
              onClick={() => handleShare(itineraryKey)}
              className="w-[140px] text-center flex items-center gap-2 p-2 text-sm font-medium rounded-md border border-solid cursor-pointer border-zinc-700 text-zinc-950"
            >
              <FaShareAlt />
              Share Itinerary
            </button>

            {/* Save Itinerary Button 
            <button
              onClick={handleSaveItinerary} // ✅ Function to save itinerary
              className="w-[145px] text-center flex items-center gap-2 p-2 text-sm font-medium rounded-md border border-solid bg-zinc-950 text-white"
            >
              <FaSave />
              Save Itinerary
            </button>
            */}
            
            {/* Create Playlist Button 
            <button
              onClick={handleCreatePlaylist} // ✅ Function to create Spotify playlist
              className="w-[140px] text-center flex items-center gap-2 p-2 text-sm font-medium rounded-md border border-solid cursor-pointer border-zinc-700 text-zinc-950"
            >
              <FaMusic />
              Create Playlist
            </button>*/}

            {/* Google Calendar Button */}
            <a
              href={createGoogleCalendarLink(shows)}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 p-2 text-sm font-medium rounded-md border border-solid cursor-pointer border-zinc-700 text-zinc-950"
              onClick={(e) => {
                const calendarUrl = createGoogleCalendarLink(filteredShows);
                if (calendarUrl === "#") {
                  e.preventDefault(); // Prevent navigation if URL is invalid
                  alert("Error generating calendar event. Please check show details.");
                }
              }}
            >
              <FaCalendarPlus />
              Add to Calendar
            </a>

          </footer>
        </div>
    
        {/* Reset Confirmation Modal */}
        {showResetModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-lg font-bold mb-4 text-center">Confirm Reset</h2>
              <p className="text-center text-gray-700">
                Are you sure you want to reset your entire itinerary? <br />
                <strong>This action cannot be undone.</strong>
              </p>
              <div className="mt-6 flex justify-center gap-4">
                <button
                  className="px-4 py-2 bg-gray-300 rounded-md"
                  onClick={() => setShowResetModal(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition"
                  onClick={handleResetAll}
                >
                  Confirm Reset
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Show Selection Modal */}
        {showModal && (
          <ItinerarySwapShow
            availableShows={availableShows}
            selectedShow={swapShow}
            setSelectedShow={setSelectedShow}
            onSelectShow={handleSelectShow}
            onCancel={() => setShowModal(false)}
          />
        )}
      </div>
    </main>
  );
};

export default Itinerary;
