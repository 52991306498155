import React, { useState, useEffect } from "react";
import { fetchGenres } from "../lib/data";

interface GenreOption {
  genre_id: number;
  description: string;
}

interface ArtistCreateModalProps {
  onAddArtist: (artist: {
    name: string;
    contact_info: string;
    genres: { genre_id: number; description: string }[];
  }) => void;
  onClose: () => void;
}

const ArtistCreateModal: React.FC<ArtistCreateModalProps> = ({ onAddArtist, onClose }) => {
  const [artistName, setArtistName] = useState<string>("");
  const [contactInfo, setContactInfo] = useState<string>(""); // State for contact info
  const [selectedGenres, setSelectedGenres] = useState<GenreOption[]>([]);
  const [genres, setGenres] = useState<GenreOption[]>([]);

  useEffect(() => {
    const loadGenres = async () => {
      const genresData = await fetchGenres();
      setGenres(
        genresData
          .filter((genre: any) => genre.genre_id && genre.description)
          .map((genre: any) => ({
            genre_id: genre.genre_id!,
            description: genre.description!,
          }))
      );
    };
    loadGenres();
  }, []);

  const handleGenreClick = (genre: GenreOption) => {
    setSelectedGenres((prevSelected) =>
      prevSelected.some((g) => g.genre_id === genre.genre_id)
        ? prevSelected.filter((g) => g.genre_id !== genre.genre_id)
        : [...prevSelected, genre]
    );
  };

  const handleSubmit = () => {
    if (!artistName || artistName.trim() === "") {
      alert("Artist name is required.");
      return;
    }

    if (selectedGenres.length === 0) {
      alert("Please select at least one genre.");
      return;
    }

    const genreData = selectedGenres.map((genre) => ({
      genre_id: genre.genre_id,
      description: genre.description,
    }));

    const newArtist = {
      name: artistName,
      contact_info: contactInfo, // Add contact info here
      genres: genreData,
    };

    onAddArtist(newArtist);
    onClose();
  };

  const isSubmitDisabled = artistName.trim() === "" || selectedGenres.length === 0;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg w-full max-w-md">
        <h2 className="text-2xl mb-4">Create New Artist</h2>
        <input
          type="text"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          placeholder="Artist Name"
          className="border border-gray-300 rounded-lg p-2 w-full mb-4"
        />

        <div className="flex flex-wrap gap-2 mb-4">
          {genres.map((genre) => (
            <span
              key={genre.genre_id}
              className={`cursor-pointer px-3 py-1 border rounded-full ${
                selectedGenres.some((g) => g.genre_id === genre.genre_id)
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => handleGenreClick(genre)}
            >
              {genre.description}
            </span>
          ))}
        </div>

        <textarea
          id="contactInfo"
          value={contactInfo}
          onChange={(e) => setContactInfo(e.target.value)}
          placeholder="Contact Info"
          className="mt-2 mb-2 p-2 min-w-full text-base font-medium bg-white rounded-md border border-solid border-zinc-700"
        />

        <button
          onClick={handleSubmit}
          className={`w-full py-2 mb-2 ${
            isSubmitDisabled
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-blue-500 text-white"
          } rounded-md`}
          disabled={isSubmitDisabled}
        >
          Add Artist
        </button>
        <button onClick={onClose} className="w-full py-2 bg-gray-300 rounded-md">
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ArtistCreateModal;