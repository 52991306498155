import React from 'react';

interface InputFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  readOnly?: boolean;
  placeholder?: string;
  containerClassName?: string;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  value,
  onChange,
  readOnly = false,
  placeholder = "",
  containerClassName = "",
}) => {
  return (
    <div className={`flex flex-col mt-2 w-full ${containerClassName}`}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        className="flex-1 shrink gap-2 self-stretch p-2 mt-2 w-full text-base font-medium bg-white rounded-md border border-solid border-zinc-700 min-h-[40px]"
      />
    </div>
  );
};

export default InputField;

