import React from "react";
import { useNavigate } from "react-router-dom";
import ShowHeader from "./ShowHeader";
import ArtistLineup from "./ArtistLineup";
import { Show, Venue, Genre, Artist } from "../lib/definitions";

interface ShowCardProps {
  show: Show;
  venue: Venue[];
  genres: Genre[];
  artists: Artist[];
  playlist: string | null;  // Allow null for playlist
  email: string | null;  // Allow null for email
}

const ShowCard: React.FC<ShowCardProps> = ({
  show,
  venue,
  genres,
  artists,
  playlist,
  email,  // Accept email as a prop
}) => {
  const navigate = useNavigate(); // Initialize navigation

  return (
    <section className="relative mb-6 flex overflow-hidden flex-col w-full bg-white rounded-2xl max-h-[600px] shadow-md">
      {/* Festival Banner */}
      {show.festival_key && (
        <div
          className="absolute top-0 left-0 w-full bg-blue-600 text-white text-center p-2 text-sm font-bold cursor-pointer hover:bg-blue-700 transition z-10"
          onClick={() => navigate(`/festival/${show.festival_key}`)}
        >
          🎉 This show is part of a festival! Click to view lineup.
        </div>
      )}

      {/* Add padding to avoid overlap when festival is present */}
      <div
        className={`flex flex-col px-4 py-6 w-full bg-zinc-950 transition-all ${
          show.festival_key ? "pt-14" : ""
        }`}
      >
        <ShowHeader show={show} venue={venue} genres={genres} /> {/* Pass first venue */}
      </div>

      {/* Pass playlist and email to ArtistLineup */}
      <ArtistLineup artists={artists} show_image_thumb={show.image_thumb} playlist={playlist} email={email} />
    </section>
  );
};

export default ShowCard;