'use client';  // Ensure this is at the top

import { useState, useEffect } from 'react';
import axios from 'axios'; // Assuming axios is used for API calls
import SearchBar from '../components/SearchBar';
import ArtistInfo from '../components/ArtistInfo';
import ShowCard from '../components/ShowCard';
import Footer from '../components/Footer';
import { useQuery } from '@tanstack/react-query';
import { fetchArtist, fetchShows, logEvent } from '../lib/data';
import type { Artist, Show, Venue, Genre } from '../lib/definitions';

function ArtistPage() {
  const [activeTab, setActiveTab] = useState<'upcoming' | 'past'>('upcoming'); // Manage tab state

  const searchParams = new URLSearchParams(window.location.search);
  const artistkey = searchParams.get('artistkey');

  // Fetch artist details
  const { data: artist, error: artistError, isLoading: artistLoading } = useQuery<Artist, Error>({
    queryKey: ['artist', artistkey],
    queryFn: () => fetchArtist(artistkey!),
    enabled: !!artistkey,
  });

  // Fetch upcoming or past shows based on activeTab state
  const { data: shows, error: showsError, isLoading: showsLoading } = useQuery<Show[], Error>({
    queryKey: ['shows', artistkey, activeTab], // Include activeTab in the queryKey
    queryFn: () => fetchShows(null, artistkey, null, null, null, activeTab === 'past' ? 'Past' : 'Future'),
    enabled: !!artistkey,
  });

  // Inside your component after successfully fetching the artist data
  useEffect(() => {
    if (artist?.artist_id !== undefined) {
      logEvent(null, 'Visit', artist.artist_id, 'Artist');
    }
  }, [artist]); // This useEffect runs when the artist data is successfully

  if (artistLoading || showsLoading) {
    return (
      <>
        <div className="flex flex-col items-center justify-center space-y-4 min-h-screen">
          <img 
            src="logo-animate.gif" 
            alt="Animated Logo" 
            className="max-w-[20rem] md:max-w-[25rem] lg:max-w-[30rem] object-contain" 
          />
        </div>
        <Footer />
      </>
    );
  }

  if (artistError || showsError) {
    return <div>Error loading data. Please try again later.</div>;
  }

  return (
    <div className="flex flex-col items-center mx-auto w-full bg-neutral-200">
      <header className="flex overflow-visible flex-col px-4 pt-8 pb-4 w-full font-medium leading-6 text-center bg-zinc-950">
        <SearchBar />
      </header>

      <div className="flex flex-col w-full max-w-[750px] mx-auto bg-neutral-200">
        {artist && <ArtistInfo artist={artist} />}

        <section className="flex flex-col w-full px-4 pt-4 mt-4">
          {/* Styled Tabs for Upcoming Shows and Past Shows */}
          <nav className="flex gap-6 justify-center items-start mt-6 w-full text-sm font-medium leading-6 text-center whitespace-nowrap text-zinc-950">
            <button
              className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${activeTab === 'upcoming' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
              onClick={() => setActiveTab('upcoming')}
            >
              Upcoming Shows
            </button>
            <button
              className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${activeTab === 'past' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
              onClick={() => setActiveTab('past')}
            >
              Past Shows
            </button>
          </nav>

          {showsLoading ? (
            <p>Loading shows...</p>
          ) : showsError ? (
            <p>Error loading shows</p>
          ) : (
            <div className="flex flex-col self-center pt-8 w-full max-w-[750px]">
              {shows?.map(show => {
                const venues: Venue[] = Array.isArray(show.venues) ? show.venues : [];
                const genres: Genre[] = Array.isArray(show.genres) ? show.genres : [];
                const artists: Artist[] = Array.isArray(show.artists) ? show.artists : [];

                return (
                  <ShowCard
                    key={show.show_id}
                    name={show.name || ''}
                    date={show.date_start || ''}
                    time={show.time_start || ''}
                    show={show}
                    venue={venues}
                    genres={genres}
                    artists={artists}
                  />
                );
              })}
            </div>
          )}
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default ArtistPage;