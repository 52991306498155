import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import App from './app';
import Artist from './artist/artist';
import VenueAdmin from './components/VenueAdmin';
import ArtistAdmin from './components/ArtistAdmin';
import AdminSuper from './admin/adminsuper';
import TermsOfService from './admin/termsofservice';
import PrivacyPolicy from './admin/privacypolicy';
import ContactUs from './admin/contactus';
import AboutUs from './admin/aboutus';
import VenueSubscription from './admin/venuesubscription';
import Venue from './venue/venue';
import Login from './login/login';
import Dashboard from './components/Dashboard';
import reportWebVitals from './lib/reportWebVitals';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import ErrorBoundary from './components/ErrorBoundary';
import { Auth0Provider } from '@auth0/auth0-react';

const queryClient = new QueryClient();
const TRACKING_ID = "G-5K6EGN20P7"; 

// Function to track page views
const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);
};

function Main() {
  // Initialize page tracking
  usePageTracking();

  return (
    <Routes>
      <Route path="/superadmin" element={<AdminSuper />} />
      <Route path="/artist" element={<Artist />} />
      <Route path="/venue" element={<Venue />} />
      <Route path="/venueadmin" element={<VenueAdmin />} />
      <Route path="/artistadmin" element={<ArtistAdmin />} />
      <Route path="/login" element={<Login />} />
      <Route path="/venuesub" element={<VenueSubscription />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/termsofservice" element={<TermsOfService />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/aboutus" element={<AboutUs />} />
      <Route path="/" element={<App />} />
    </Routes>
  );
}

window.onpageshow = function(event) {
  if (event.persisted) {
    window.location.reload();
  }
};

const root = ReactDOM.createRoot(document.getElementById('root')); // Updated to use createRoot
root.render(
  <ErrorBoundary fallback={<h1><a href="/">Go Home</a></h1>}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <Main />
        </Router>
      </QueryClientProvider>
    </Auth0Provider>
  </ErrorBoundary>
);  // Render the Main component

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();