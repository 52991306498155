import React, { useState } from "react";
import InputField from "./ui/InputField";
import { createVenue, uploadImage } from "../lib/data"; 
import { Venue } from "../lib/definitions";
import Notification from "./ui/Notification";

interface VenueAdminAddVenueProps {
  user_id: number; 
}

const VenueAdminAddVenue: React.FC<VenueAdminAddVenueProps> = ({ user_id }) => {
  const [venueName, setVenueName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("US");
  const [bio, setBio] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYouTube] = useState("");
  const [tiktok, setTikTok] = useState("");
  const [twitter, setTwitter] = useState("");
  const [merch, setMerch] = useState("");
  const [officialLink, setOfficialLink] = useState("");
  const [coverPhotoUrl, setCoverPhotoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [notification, setNotificationState] = useState<{ message: string, type: 'success' | 'error' } | null>(null);

  const triggerNotification = (message: string, type: 'success' | 'error') => {
    setNotificationState({ message, type });
    setTimeout(() => {
      setNotificationState(null);
    }, 3000);
  };

  const defaultImageKey = new Date().toISOString().replace(/[-:.]/g, '');

  const handleImageUpload = async (file: File, uploadPreset: string, imageKey: string) => {
    try {
      setIsUploading(true);
      const imageUrl = await uploadImage(file, imageKey, uploadPreset);
  
      if (uploadPreset === 'cover_photo') {
        setCoverPhotoUrl(imageUrl);
      } else if (uploadPreset === 'thumbnail_photo') {
        setThumbnailUrl(imageUrl);
      }
  
      triggerNotification("Image uploaded successfully!", 'success');
    } catch (error) {
      console.error("Failed to upload image", error);
      triggerNotification("Failed to upload image.", 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const newVenue = {
        name: venueName,
        addr_street: street,
        addr_city: city,
        addr_state: state,
        addr_zip: zip,
        addr_country: country,
        description: bio,
        fb_link: facebook,
        ig_link: instagram,
        yt_link: youtube,
        tiktok_link: tiktok,
        twitter_link: twitter,
        merch_link: merch,
        official_link: officialLink,
        image_full: coverPhotoUrl,
        image_thumb: thumbnailUrl,
        created_by: user_id,
      };
      await createVenue(newVenue);
      triggerNotification("Venue created successfully!", 'success');
    } catch (error) {
      console.error("Failed to create venue", error);
      triggerNotification("Failed to create venue.", 'error');
    }
  };

  const handleCancel = () => {
    setVenueName("");
    setStreet("");
    setCity("");
    setState("");
    setZip("");
    setCountry("US");
    setBio("");
    setFacebook("");
    setInstagram("");
    setYouTube("");
    setTikTok("");
    setTwitter("");
    setMerch("");
    setOfficialLink("");
    setCoverPhotoUrl("");
    setThumbnailUrl("");
  };

  return (
    <>
      <section className="flex flex-col w-full">
        <h2 className="flex-1 shrink gap-2 pt-2.5 w-full text-lg font-bold tracking-tight min-h-[56px] text-zinc-950">
          About You
        </h2>
        <InputField label="Venue Name*" value={venueName} onChange={(e) => setVenueName(e.target.value)} />
        <InputField label="Street*" value={street} onChange={(e) => setStreet(e.target.value)} />
        <div className="flex gap-2 items-start mt-2 w-full text-xs font-semibold whitespace-nowrap">
          <InputField label="City*" value={city} onChange={(e) => setCity(e.target.value)} containerClassName="flex flex-col min-w-[240px] w-[282px]" />
          <InputField label="State*" value={state} onChange={(e) => setState(e.target.value)} containerClassName="flex flex-col flex-1 shrink basis-0" />
          <InputField label="Zip*" value={zip} onChange={(e) => setZip(e.target.value)} containerClassName="flex flex-col w-[170px]" />
        </div>
      </section>

      <section className="flex flex-col mt-6 w-full">
        <div className="flex flex-col mt-2 w-full">
          <label htmlFor="venueBio" className="text-xs font-semibold text-zinc-950">
            Venue Bio
          </label>
          <textarea
            id="venueBio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            className="mt-2 p-2 min-h-[250px] text-base font-medium bg-white rounded-md border border-solid border-zinc-700"
            placeholder="Write a brief description of the venue"
          />
        </div>

        <div className="flex relative flex-col mt-2 w-full">
          <div className="flex z-0 items-center pb-4 w-full text-zinc-950">
            <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Cover Photo</h3>
          </div>
          <p className="z-0 mt-2 text-xs font-semibold text-neutral-400">
            We recommend an image of at least 1920x1080 for your cover photo
          </p>
          <div className="flex z-0 mt-2 w-full rounded-md border-2 border-solid bg-zinc-300 bg-opacity-0 border-neutral-200 min-h-[212px]">
            <img
              loading="lazy"
              src={coverPhotoUrl || "https://via.placeholder.com/1920x1080"}
              className=""
              alt="Event Photo"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="coverPhotoInput"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                handleImageUpload(file, 'cover_photo', defaultImageKey);
              }
            }}
          />
          <button
            className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
            onClick={() => document.getElementById('coverPhotoInput')?.click()}
          >
            Select Cover Photo
          </button>

          {isUploading && <p className="mt-2 text-sm text-indigo-500">Uploading cover photo...</p>}
        </div>

        <div className="flex flex-col mt-2 w-full">
          <div className="flex items-center pb-4 w-full text-zinc-950">
            <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Thumbnail</h3>
          </div>
          <div className="flex gap-2 items-center mt-2 w-full text-xs font-semibold text-neutral-400">
            <img
              loading="lazy"
              src={thumbnailUrl || "https://via.placeholder.com/512"}
              className="object-contain shrink-0 self-stretch my-auto w-20 aspect-square rounded-[50%]"
              alt="Event Thumbnail"
            />
            <p className="self-stretch my-auto w-[242px]">We recommend an image of at least 512x512 for your artist thumbnail</p>
          </div>

          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="thumbnailInput"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                handleImageUpload(file, 'thumbnail_photo', defaultImageKey);
              }
            }}
          />

        {/* Button to trigger file selection for thumbnail */}
        <button
          className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
          onClick={() => document.getElementById('thumbnailInput')?.click()}
        >
          Select Thumbnail
        </button>

        {isUploading && <p className="mt-2 text-sm text-indigo-500">Uploading thumbnail...</p>}
      </div>
      </section>

      <section className="mt-2 w-full">
        <h2 className="flex-1 shrink self-stretch pb-4 mt-2 w-full text-lg font-bold tracking-tight text-zinc-950">Social Links</h2>
        <InputField label='Facebook' value={facebook} onChange={(e) => setFacebook(e.target.value)} />
        <InputField label='Instagram' value={instagram} onChange={(e) => setInstagram(e.target.value)} />
        <InputField label='TikTok' value={tiktok} onChange={(e) => setTikTok(e.target.value)} />
        <InputField label='YouTube' value={youtube} onChange={(e) => setYouTube(e.target.value)} />
        <InputField label='Twitter' value={twitter} onChange={(e) => setTwitter(e.target.value)} />
      </section>

      <section className="flex flex-col mt-6 w-full text-xs font-semibold">
        <h2 className="flex-1 shrink gap-2 pt-2.5 w-full text-lg font-bold tracking-tight min-h-[56px] text-zinc-950">Additional Links</h2>
        <InputField label='Official' value={officialLink} onChange={(e) => setOfficialLink(e.target.value)} />
        <InputField label='Merchandise' value={merch} onChange={(e) => setMerch(e.target.value)} />
      </section>

      <div className="flex gap-10 justify-between items-center mt-6 w-full text-sm font-medium leading-6 text-center">
        <button className="gap-2 self-stretch px-6 py-2 my-auto rounded-md border border-solid border-zinc-700 text-zinc-950" onClick={handleCancel}>
          Cancel Changes
        </button>
        <button onClick={handleSaveChanges} className="gap-2 self-stretch px-6 py-2 my-auto text-white rounded-md border border-solid bg-zinc-950 border-zinc-700">
          Save Changes
        </button>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotificationState(null)}
        />
      )}
    </>
  );
};

export default VenueAdminAddVenue;