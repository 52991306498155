import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLngExpression } from "leaflet";

// Sample Data
const festivalStages = [
  { id: 1, name: "Main Stage", lat: 37.775, lon: -122.418 },
  { id: 2, name: "Indie Stage", lat: 37.774, lon: -122.419 },
];

const festivalAmenities = [
  { id: 1, name: "Food Court", lat: 37.7745, lon: -122.4185 },
  { id: 2, name: "Restrooms", lat: 37.7755, lon: -122.4195 },
];

// Component to Set View
const SetView = ({ center, zoom }: { center: LatLngExpression; zoom: number }) => {
  const map = useMap();
  map.setView(center, zoom);
  return null;
};

const FestivalMap = () => {
  const defaultCenter: LatLngExpression =
    festivalStages.length > 0
      ? ([festivalStages[0].lat, festivalStages[0].lon] as LatLngExpression)
      : ([37.7749, -122.4194] as LatLngExpression); // Fallback

  return (
    <MapContainer style={{ height: "500px", width: "100%" }}>
      {/* Set initial map view */}
      <SetView center={defaultCenter} zoom={15} />

      {/* ✅ Explicitly Define Props for TileLayer */}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        {...({ attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' } as any)}
      />

      {/* Stage Markers */}
      {festivalStages.map((stage) => (
        <Marker key={stage.id} position={[stage.lat, stage.lon] as LatLngExpression}>
          <Popup>{stage.name}</Popup>
        </Marker>
      ))}

      {/* Amenities Markers */}
      {festivalAmenities.map((amenity) => (
        <Marker key={amenity.id} position={[amenity.lat, amenity.lon] as LatLngExpression}>
          <Popup>{amenity.name}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default FestivalMap;