import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchArtistMatches } from '../lib/data';
import { ArtistMatch } from '../lib/definitions';

const ArtistMatchComponent: React.FC = () => {
  const { data: matches, error, isLoading } = useQuery<ArtistMatch[], Error>({
    queryKey: ['artistMatches'],
    queryFn: fetchArtistMatches,
  });

  if (isLoading) return <p className="text-center text-blue-600">Loading artist matches...</p>;
  if (error) return <p className="text-center text-red-600">Error: {error.message}</p>;

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold text-gray-800 text-center mb-6">Artist Matches</h1>
      {matches && matches.length > 0 ? (
        <ul className="space-y-4">
          {matches.map((match) => (
            <li key={`${match.artist1}-${match.artist2}`} className="p-4 bg-gray-100 rounded-lg shadow-md">
              <span className="font-semibold text-gray-800">{match.artist1}</span>{' '}
              <span className="text-sm text-gray-500">(ID: {match.artist1_id})</span>{' '}
              <span className="text-gray-600">matches with</span>{' '}
              <span className="font-semibold text-gray-800">{match.artist2}</span>{' '}
              <span className="text-sm text-gray-500">(ID: {match.artist2_id})</span>{' '}
              <span className="text-gray-600">- Score: {match.score}</span>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-600">No matches found.</p>
      )}
    </div>
  );
};

export default ArtistMatchComponent;