import * as React from "react";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import NotificationArtist from "./NotificationArtist"; // Import the NotificationArtist component
import { Artist, Genre } from "../lib/definitions";
import { logEvent } from "../lib/data"; // Import the logEvent function

type ArtistInfoProps = {
  artist: Artist;
};

const ArtistInfo: React.FC<ArtistInfoProps> = ({ artist }) => {
  const [showModal, setShowModal] = useState(false); // Modal state

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this artist!",
          text: `Have a look at ${artist.name}!`,
          url: window.location.href,
        });
        logEvent(null, "Share", artist.artist_id ?? 0, "Artist");
      } catch (error) {
        console.error("Sharing failed", error);
      }
    } else {
      console.warn("Web Share API is not supported in this browser.");
    }
  };

  const handleLinkClick = async (url: string, linkType: string) => {
    logEvent(null, "Click", artist.artist_id ?? 0, "Artist", linkType);
    window.open(url, "_blank", "noopener noreferrer");
  };

  const genres: Genre[] =
    typeof artist.genres === "string" ? JSON.parse(artist.genres) : [];
  const isWebShareSupported = typeof navigator.share === "function"; // Check if Web Share API is supported

  return (
    <section className="flex flex-col w-full bg-white">
      <div className="flex flex-col px-4 py-6 w-full bg-zinc-950">
        <div className="flex flex-col w-full">
          <div className="flex gap-2 items-center w-full text-3xl font-bold tracking-tighter leading-none text-white">
            <h1 className="flex-1 shrink my-auto">{artist.name}</h1>
          </div>
          <div className="mt-1 text-base font-semibold text-zinc-400">
            {genres?.length ? (
              genres.map((genre, index) => (
                <span
                  key={index}
                  className="mt-1 text-base mr-2 font-semibold text-zinc-400"
                >
                  {genre.description}
                </span>
              ))
            ) : (
              <span>No genres available</span>
            )}
          </div>
        </div>

        {artist.image_full && (
          <img
            loading="lazy"
            src={artist.image_full}
            className="w-full aspect-[1.46] rounded-2xl object-contain"
            alt={`Image of ${artist.name}`}
          />
        )}

        <div className="text-base font-medium leading-6 text-neutral-400 prose prose-invert">
          <div dangerouslySetInnerHTML={{ __html: artist.bio ?? "" }} />
        </div>

        {/* Action Buttons */}
        <div className="flex gap-4 items-start mt-6 w-full text-sm font-medium leading-6 text-center text-white whitespace-nowrap">
          {/* Other Links (Only if not "Opener") */}
          {artist.sub_level !== "Opener" && (
            <>
            {artist.fb_link && (
              <button
                onClick={() => handleLinkClick(artist.fb_link || "", "Facebook")}
                className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
              >
                <img src="icon-facebook.svg" alt="fb icon" className="w-6 aspect-square" />
              </button>
            )}
            {artist.ig_link && (
              <button
                onClick={() => handleLinkClick(artist.ig_link || "", "Instagram")}
                className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
              >
                <img src="icon-instagram.svg" alt="ig icon" className="w-6 aspect-square" />
              </button>
            )}
            {artist.twitter_link && (
              <button
                onClick={() => handleLinkClick(artist.twitter_link || "", "Twitter")}
                className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
              >
                <img src="icon-twitter.svg" alt="twitter icon" className="w-6 aspect-square" />
              </button>
            )}
            {artist.tiktok_link && (
              <button
                onClick={() => handleLinkClick(artist.tiktok_link || "", "TikTok")}
                className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
              >
                <img src="icon-tiktok.svg" alt="tiktok icon" className="w-6 aspect-square" />
              </button>
            )}
            {artist.official_link && (
              <button
                onClick={() => handleLinkClick(artist.official_link || "", "Official")}
                className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
              >
                <img src="icon-website.svg" alt="website icon" className="w-6 aspect-square" />
              </button>
            )}
            {artist.spotify_link && (
              <button
                onClick={() => handleLinkClick(artist.spotify_link || "", "Spotify")}
                className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
              >
                <img src="icon-spotify.svg" alt="spotify icon" className="w-6 aspect-square" />
              </button>
            )}
            {artist.merch_link && (
              <button
                onClick={() => handleLinkClick(artist.merch_link || "", "Merch")}
                className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
              >
                <img src="icon-merch.svg" alt="merch icon" className="w-6 aspect-square" />
              </button>
            )}
          </>
          )}

          {/* Share Button (Only if supported) */}
          {isWebShareSupported && (
            <button
              onClick={handleShare}
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img
                src="icon-share.svg"
                alt="share icon"
                className="w-6 aspect-square"
              />
            </button>
          )}

          {/* Notification Button */}
          <button
            onClick={() => setShowModal(true)}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img
              src="icon-notification.png"
              alt="Notifications"
              className="w-6 aspect-square"
            />
          </button>
        </div>
      </div>

      {/* Notification Modal */}
      {showModal && (
        <div
          className="fixed inset-0 bg-zinc-950 bg-opacity-50 flex justify-center items-center z-50"
          style={{ backdropFilter: "blur(5px)" }}
        >

            <NotificationArtist
              artist_id={artist.artist_id ?? 0}
              artist_name={artist.name ?? ""}
              closeModal={() => setShowModal(false)}
            />

        </div>
      )}
    </section>
  );
};

export default ArtistInfo;