import React from 'react';

const AboutUs = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-gray-800 to-black text-white">
      {/* Logo */}
      <div className="mb-6">
        <img src="/myvibelogowithtagline.png" alt="Logo" className="w-40 h-auto" />
      </div>

      {/* Heading */}
      <h1 className="text-5xl font-bold mb-6 text-center">About Us</h1>

      {/* Content */}
      <div className="max-w-3xl text-center space-y-6 text-lg">
        <p>
          Welcome to <span className="font-bold text-blue-400">myVibeLive</span>, your ultimate destination for discovering
          live music and connecting with artists and venues. Our mission is to create a seamless experience for music
          lovers by bringing shows, genres, and venues together in one place.
        </p>
        <p>
          Whether you're an artist looking to showcase your talent or a venue aiming to reach new audiences, 
          myVibeLive is here to help you make meaningful connections and create unforgettable experiences.
        </p>
        <p>
          Join us in celebrating the power of live music and explore the vibes in your area!
        </p>
      </div>

      {/* Team Section */}
      <div className="mt-12 max-w-4xl">
        <h2 className="text-3xl font-bold mb-4 text-center">Meet Our Team</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Team Member Card */}
          <div className="flex flex-col items-center">
            <img src="/team-member1.jpg" alt="Team Member 1" className="w-24 h-24 rounded-full mb-4" />
            <h3 className="text-xl font-semibold">Matthew Geiger</h3>
            <p className="text-sm text-gray-400">Founder & Developer</p>
          </div>
          <div className="flex flex-col items-center">
            <img src="/team-member2.jpg" alt="Team Member 2" className="w-24 h-24 rounded-full mb-4" />
            <h3 className="text-xl font-semibold">Marketing Director</h3>
            <p className="text-sm text-gray-400">Creative & Outreach</p>
          </div>
          <div className="flex flex-col items-center">
            <img src="/team-member3.jpg" alt="Team Member 3" className="w-24 h-24 rounded-full mb-4" />
            <h3 className="text-xl font-semibold">Support Team</h3>
            <p className="text-sm text-gray-400">User Success & Support</p>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-12">
        <p className="text-sm text-gray-400">
          Interested in partnering with us? Reach out via our <a href="/contactus" className="text-blue-400 underline">Contact Us</a> page.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;