import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchShows, updateShow } from "../lib/data";
import { Show } from "../lib/definitions";
import ArtistProfileCard from './ArtistProfileCard';

interface ArtistAdminShowsProps {
  selectedArtist: number;
  user_id: number;
}

const ArtistAdminShows: React.FC<ArtistAdminShowsProps> = ({ selectedArtist, user_id }) => {
  
  const [showType, setShowType] = useState<'Future' | 'Past'>('Future'); // State for future or past shows

  // Fetch shows based on the selected showType
  const { data: shows, error: showsError, isLoading: showsLoading } = useQuery<Show[], Error>({
    queryKey: ['shows', selectedArtist, showType], // Include showType in the queryKey
    queryFn: () => fetchShows(null, null, null, null, null, showType, null, null, null, null, selectedArtist),
    enabled: !!selectedArtist,
  });

  if (showsLoading) {
    return <p>Loading shows...</p>;
  }

  if (showsError) {
    return <p>Error loading shows: {showsError.message}</p>;
  }

  return (
    <>
      <header className="flex z-0 items-center pb-4 w-full text-zinc-950">
      </header>

      {/* Toggle between Upcoming and Past shows */}
      <nav className="flex gap-6 justify-center items-start mt-2 mb-6 w-full text-sm font-medium leading-6 text-center whitespace-nowrap text-zinc-950">
        <button
          className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${showType === 'Future' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
          onClick={() => setShowType('Future')}
        >
          Upcoming Shows
        </button>
        <button
          className={`flex-1 shrink gap-2 self-stretch px-4 py-2 ${showType === 'Past' ? 'border-b-2 border-solid border-b-zinc-950' : ''}`}
          onClick={() => setShowType('Past')}
        >
          Past Shows
        </button>
      </nav>

      <div className="flex relative flex-col w-full">
        {shows && shows.length > 0 ? (
          shows.map(show => (
            show.show_id ? (  // Ensure show_id is defined
              <ArtistProfileCard
                key={show.show_id}
                name={show.venues?.[0]?.name ?? 'Unknown Venue'}
                date={show.date_start}
                time={show.time_start}
                imageSrc={show.image_thumb && show.image_thumb.trim() !== '' ? show.image_thumb : 'default_show.webp'} // Use default image if image_thumb is blank
              />
            ) : null  // Skip shows without a valid show_id
          ))
        ) : (
          <>
            <div className="flex z-0 mt-2 w-full rounded-md border-2 border-solid bg-zinc-300 bg-opacity-0 border-neutral-200 min-h-[212px]" />
            <p className="absolute left-2/4 z-0 text-xs font-semibold text-center -translate-x-2/4 bottom-[99px] h-[15px] text-neutral-400 translate-y-[0%] w-[321px]">
              No {showType === 'Future' ? 'upcoming' : 'past'} shows listed yet
            </p>
          </>
        )}
      </div>




    </>
  );
};

export default ArtistAdminShows;