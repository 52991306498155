import React from "react";

const SuperAdminHeader: React.FC = () => {
  return (
    <header className="flex gap-6 items-center px-4 py-6 w-full bg-zinc-950">
      <div className="flex flex-col flex-1 shrink items-start self-stretch my-auto w-full basis-0 min-w-[240px]">
        <img
          loading="lazy"
          src="logo.png"
          className="object-contain aspect-[1.18] w-[47px]"
          alt=""
        />
        <h1 className="flex-1 shrink gap-2 self-stretch mt-2 w-full text-3xl font-bold tracking-tighter leading-none text-white">
          Super Admin
        </h1>
        <p className="gap-6 mt-2 max-w-full text-xs font-medium leading-4 text-zinc-400 w-[429px]">
          Configure myVibe.live!
        </p>
      </div>
    </header>
  );
};

export default SuperAdminHeader;
