import React, { useEffect, useState } from "react";
import ArtistAdminHeader from "./ArtistAdminHeader";  
import ArtistAdminNav from "./ArtistAdminNav";
import ArtistAdminProfile from "./ArtistAdminProfile";  // Updated
import ArtistAdminShows from "./ArtistAdminShows";    // Updated
import ArtistAdminReports from "./ArtistAdminReports"; // Updated
import Footer from "./Footer";
import { fetchArtistsByUserId } from "../lib/data";    // Updated
import { Artist } from "../lib/definitions";          // Updated
import * as jwt_decode from 'jwt-decode';
import { useLocation } from 'react-router-dom';

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

const ArtistAdmin: React.FC = () => { // Updated
  const [activeTab, setActiveTab] = useState<string>('Shows');
  const [artists, setArtists] = useState<Artist[]>([]);  // Updated
  const [selectedArtist, setSelectedArtist] = useState<number | null>(null); // Use number for artist_id
  const [loading, setLoading] = useState<boolean>(true); // Track loading state
  const [error, setError] = useState<string | null>(null); // Track errors

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  let user_id: number = 0;

  if (token) {
    // Decode the token to get user_id
    const decoded: any = parseJwt(token);
    user_id = decoded.user_id;
  }

  // Fetch the list of artists based on the user ID when the component mounts
  useEffect(() => {
    const fetchArtists = async () => { // Updated function
      setLoading(true);
      try {
        const response = await fetchArtistsByUserId(user_id); // Fetch artists
        setArtists(response);
  
        // Automatically select the first artist if available
        if (response.length > 0) {
          setSelectedArtist(response[0].artist_id ?? null); // Use artist_id, fallback to null if undefined
        } else {
          setSelectedArtist(null); // Ensure it's cleared if no artists
        }
      } catch (error) {
        console.error("Failed to fetch artists", error);
        setError("Failed to fetch artists. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (user_id) {
      fetchArtists();
    }
  }, [user_id]);


  // Handle loading state
  if (loading) {
    return <p>Loading artists...</p>;
  }

  // Handle error state
  if (error) {
    return <p>{error}</p>;
  }

  // Handle no artists case
  if (artists.length === 0) {
    return <p>No artists available for this user.</p>;
  }

  return (
    <main className="flex overflow-hidden flex-col pb-8 mx-auto w-full bg-white">
      <ArtistAdminHeader
        artists={artists}  // Pass artists instead of venues
        selectedArtist={selectedArtist ?? 0} // Ensure it's a number or 0
        setSelectedArtist={(artistId: number) => setSelectedArtist(artistId ?? null)} // Ensure the fallback to null
        user_id={user_id}
      />
      <ArtistAdminNav activeTab={activeTab} setActiveTab={setActiveTab} />
      <section className="flex flex-col self-center px-4 pb-4 mt-6 w-full max-w-[430px]">
        {activeTab === 'Shows' ? (
          <ArtistAdminShows selectedArtist={selectedArtist ?? 0} user_id={user_id} /> // Updated
        ) : activeTab === 'Reports' ? (
          <ArtistAdminReports selectedArtist={selectedArtist ?? 0} /> // Updated
        ) : (
          <ArtistAdminProfile selectedArtist={selectedArtist ?? 0} user_id={user_id} /> // Updated
        )}
      </section>
      <Footer />
    </main>
  );
};

export default ArtistAdmin;