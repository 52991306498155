import React, { useState } from 'react';

interface ProfileCardProps {
  name?: string;
  date?: string;
  time?: string;
  imageSrc?: string;
  show_id: number;
  user_id: number;
  onEdit: (show_id: number, user_id: number) => void; // Pass both show_id and user_id
  deleteShow: (show_id: number) => void; // Function to delete the show
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  name = "Unknown Show",
  date = "TBA",
  time = "TBA",
  imageSrc = "default_show.webp",
  show_id,
  user_id,
  onEdit,
  deleteShow
}) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const formattedDate = date !== "TBA" ? formatDate(date) : date;
  const formattedTime = time !== "TBA" ? formatTime(time) : time;

  const handleDelete = () => {
    setDeleteModalOpen(true); // Open the custom delete confirmation modal
  };

  const confirmDelete = () => {
    deleteShow(show_id);
    setDeleteModalOpen(false); // Close the modal after deletion
  };

  return (
    <>
      <article className="flex flex-col justify-center self-stretch px-4 py-6 mb-4 rounded-md bg-zinc-950 max-w-[398px]">
        <header className="flex gap-4 items-center w-full">
          <img
            loading="lazy"
            src={imageSrc}
            className="object-contain shrink-0 self-stretch my-auto w-20 rounded-md aspect-square"
            alt={name}
          />
          <div className="flex flex-col flex-1 shrink self-stretch my-auto basis-0 min-w-[240px]">
            <div className="flex flex-col w-full">
              <h2 className="flex-1 shrink gap-2 self-stretch w-full text-3xl font-bold tracking-tighter leading-none text-white">
                {name}
              </h2>
              <div className="flex gap-6 items-start self-start mt-2 text-base font-medium text-zinc-400">
                <time>{formattedDate}</time>
                <time>{formattedTime}</time>
              </div>
            </div>
          </div>
        </header>
        <div>
          <button
            className="gap-2 px-6 py-2 rounded-md border border-solid border-zinc-700 text-sm font-medium leading-6 text-center text-white"
            onClick={() => onEdit(show_id, user_id)} // Pass both show_id and user_id
          >
            Edit Show
          </button>
          <button
            className="gap-2 px-6 py-2 ml-3 mt-3 rounded-md border border-solid border-zinc-700 text-sm font-medium leading-6 text-center text-white"
            onClick={handleDelete} // Trigger the custom delete modal
          >
            Delete Show
          </button>
        </div>
      </article>

      {isDeleteModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-md shadow-lg max-w-sm w-full">
            <h3 className="text-lg font-bold mb-4">Are you sure?</h3>
            <p className="text-sm mb-6">Do you really want to delete this show? This action cannot be undone.</p>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                onClick={confirmDelete} // Confirm deletion
              >
                Yes, Delete
              </button>
              <button
                className="px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300"
                onClick={() => setDeleteModalOpen(false)} // Cancel deletion
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// Helper functions for formatting
function formatDate(dateString: string): string {
  const [year, month, day] = dateString.split('T')[0].split('-');
  const monthNames = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];
  return `${monthNames[parseInt(month) - 1]} ${day}, ${year}`;
}

function formatTime(timeString: string): string {
  if (!timeString) {
    return ''; // Handle undefined or invalid time
  }
  const timePart = timeString.split('T')[1].split('Z')[0];
  let [hour, minute] = timePart.split(':');
  let period = 'AM';
  let hourNumber = parseInt(hour);

  if (hourNumber >= 12) {
    period = 'PM';
    if (hourNumber > 12) {
      hourNumber -= 12;
    }
  }

  if (hourNumber === 0) {
    hourNumber = 12;
  }

  return `${hourNumber.toString()}:${minute} ${period}`;
}

export default ProfileCard;