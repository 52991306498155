import React, { useState, useEffect } from 'react';
import ArtistItemSearch from './ArtistItemSearch';
import { fetchArtistVenueSearch } from '../lib/data'; // Assuming this fetches both artists and venues

interface Genre {
  id: number;
  label: string;
}

interface ArtistSelectionProps {
  onAddArtist: (artist: { name: string; genres: Genre[] }) => void;
  onCreateArtist: () => void;
  onClose: () => void;
}

const ArtistSelection: React.FC<ArtistSelectionProps> = ({ onAddArtist, onCreateArtist, onClose }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [artists, setArtists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchArtists = async () => {
      if (searchTerm.length > 0) {
        setLoading(true);
        try {
          const results = await fetchArtistVenueSearch(searchTerm);

          // Filter to only include results where the type is 'artist'
          const filteredArtists = results.filter((result: any) => result.link_type === 'artist');
          setArtists(filteredArtists.slice(0, 10)); // Limit to top 10 results
        } catch (error) {
          console.error('Error fetching artists:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setArtists([]);
      }
    };

    fetchArtists();
  }, [searchTerm]);

  const handleAddArtist = (artist: any) => {
    const genreData = artist.genres?.map((genre: { id: number; label: string }) => ({
      id: genre.id,   // genre ID
      description: genre.label, // genre label
    })) || [];

    const newArtist = {
      artist_id: artist.artist_id,
      name: artist.name,
      image_thumb: artist.image_src,
      genres: genreData, // Pass both genre IDs and labels
    };

    onAddArtist(newArtist);
    onClose();
  };

  return (
    <section className="flex overflow-hidden flex-col p-6 bg-white rounded-lg max-w-[430px] shadow-[0px_4px_24px_rgba(0,0,0,0.3)]">
      <header className="flex flex-col w-full text-base font-medium whitespace-nowrap text-neutral-400">
        <div className="flex gap-2 justify-center items-center p-2 w-full bg-white rounded-md border border-solid border-zinc-700 min-h-[40px]">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d100ebf29b0d63ddd3994497427310abe9ed6dc637b3a83bdca5e5f3d204cc26" className="object-contain w-6 aspect-square" alt="Search Icon" />
          <input
            type="search"
            placeholder="Search artists"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="flex-1 shrink self-stretch my-auto text-center border-none bg-white"
          />
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2a650b339ebb4dd6e980ad2340b226095de64e1275f8cc77dc083dc849b95dd7" className="object-contain w-6 aspect-square" alt="Settings Icon" />
        </div>
      </header>
      
      <main className="flex gap-2 mt-2 w-full">
        <div className="flex flex-col overflow-y-auto h-64">
          {loading && <p>Loading artists...</p>}
          
          {!loading && artists.length > 0 && artists.map((artist, index) => (
            <ArtistItemSearch
              key={index}
              name={artist.name}
              genre={artist.genres?.map((g: any) => g.label).join(', ') || artist.additional_data} // Display genres or other data
              image={artist.image_src}
              onClick={() => handleAddArtist(artist)}
            />
          ))}
          
          {!loading && artists.length === 0 && searchTerm.length > 0 && (
            <p>No artists found for "{searchTerm}"</p>
          )}
        </div>
      </main>

      <footer className="mt-4">
        <button onClick={onCreateArtist} className="w-full mt-4 bg-blue-500 text-white py-2 rounded-md">
          Create New Artist
        </button>
        <button onClick={onClose} className="w-full mt-2 bg-gray-500 text-white py-2 rounded-md">
          Cancel
        </button>
      </footer>
    </section>
  );
};

export default ArtistSelection;