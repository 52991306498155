import React, { useState, useEffect } from 'react';
import { Venue } from '../lib/definitions';
import axios from 'axios';

interface VenueAdminHeaderProps {
  venues: Venue[];
  selectedVenue: number;  // Change to number for venue_id
  setSelectedVenue: (venueId: number) => void; // Update to use venue_id
  user_id: number;
}

const VenueAdminHeader: React.FC<VenueAdminHeaderProps> = ({ venues, selectedVenue, setSelectedVenue, user_id }) => {
  const [venue, setVenue] = useState<Venue | null>(null); // Store selected venue data
  const [showCancelModal, setShowCancelModal] = useState(false); // Control modal visibility

  useEffect(() => {
    const fetchVenue = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/venue/by-id?venue_id=${selectedVenue}`);

        console.log(response.data);
        setVenue(response.data);
      } catch (error) {
        console.error('Failed to fetch venue:', error);
      }
    };

    if (selectedVenue) {
      fetchVenue(); // Fetch venue data when a venue is selected
    }
  }, [selectedVenue]);

  const handleCheckout = async (planId: string) => {
    try {
      const emailResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/user/${user_id}/email`);
      const email = emailResponse.data.email;

      if (!email || !venue?.venue_key) {
        throw new Error('Required data not found');
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/checkout/create-checkout`, {
        email,
        planId,
        object_key: venue.venue_key, // Venue-specific key
      });

      window.location.href = response.data.hosted_page_url;
    } catch (error) {
      console.error('Checkout failed:', error);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const subscriptionId = venue?.sub_id;

      if (!subscriptionId) {
        throw new Error('Subscription ID not found');
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/checkout/cancel-subscription`, {
        subscriptionId,
      });

      if (response.data && response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl;
      } else {
        alert('Subscription canceled successfully, but no redirect URL provided.');
      }
    } catch (error) {
      console.error('Subscription cancellation failed:', error);
      alert('Error canceling subscription: ' + error);
    }
  };

  const openCancelModal = () => {
    setShowCancelModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelModal(false);
  };

  return (
    <>
      <header className="flex gap-6 items-center px-4 py-6 w-full bg-zinc-950">
        <div className="flex flex-col flex-1 shrink items-start self-stretch my-auto w-full basis-0 min-w-[240px]">
          <a href="/">
            <img
              loading="lazy"
              src="logo.png"
              className="object-contain aspect-[1.18] w-[47px]"
              alt="Logo"
            />
          </a>
          <h1 className="flex-1 shrink gap-2 self-stretch mt-2 w-full text-3xl font-bold tracking-tighter leading-none text-white">
            Venue Profile
          </h1>
          <p className="gap-6 mt-2 max-w-full text-xs font-medium leading-4 text-zinc-400 w-[429px]">
            Configure your venue profile and how you're displayed on myVibe.live!
          </p>
        </div>

        <div className="flex flex-col items-end">
          <div className="text-white">Current Subscription: {venue?.sub_level}</div>
          {venue?.sub_level === "Active" ? (
            <button
              onClick={openCancelModal}
              className="text-white mt-2 border border-white rounded-md px-2 py-1"
            >
              Cancel Subscription
            </button>
          ) : (
            <button
              onClick={() => handleCheckout('venue_active-USD-Monthly')}
              className="text-white mt-2 border border-white rounded-md px-2 py-1"
            >
              Activate Subscription
            </button>
          )}
        </div>
      </header>

      <div className="flex flex-col mt-4 w-64 ml-4">
        <label htmlFor="venueDropdown" className="text-sm font-semibold text-gray-700 mb-2">
          Select Venue
        </label>
        {venues.length === 0 ? (
          <p className="text-gray-500 text-sm italic">No venues available</p>
        ) : (
          <div className="relative">
            <select
              id="venueDropdown"
              value={selectedVenue}
              onChange={(e) => setSelectedVenue(Number(e.target.value))}
              className="w-full p-2 bg-white text-gray-700 rounded-md border border-gray-300 shadow-sm appearance-none focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition duration-200 ease-in-out"
            >
              <option value="" disabled className="text-gray-400">
                Choose a venue...
              </option>
              {venues.map((venue) => (
                <option key={venue.venue_id} value={venue.venue_id}>
                  {venue.name}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10l5 5 5-5H7z" fill="currentColor" />
              </svg>
            </div>
          </div>
        )}
      </div>

      {showCancelModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-lg font-semibold mb-4">Are you sure you want to cancel?</h2>
            <p className="text-gray-600 mb-6">This action cannot be undone.</p>
            <div className="flex justify-end gap-4">
              <button
                onClick={closeCancelModal}
                className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 transition"
              >
                No, Keep Subscription
              </button>
              <button
                onClick={() => {
                  handleCancelSubscription();
                  closeCancelModal();
                }}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition"
              >
                Yes, Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VenueAdminHeader;