import React, { useState } from "react";
import { Show, Venue, Genre } from "../lib/definitions";
import { logEvent } from "../lib/data";

interface ShowHeaderProps {
  show: Show;
  venue: Venue | Venue[];  // Allow either a single venue or an array of venues
  genres: Genre[];
}

const ShowHeader: React.FC<ShowHeaderProps> = ({
  show,
  venue,
  genres,
}) => {
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);

  const toggleDescription = () => {
    setDescriptionExpanded((prev) => !prev);
  };

  // Function to generate Google Calendar event link
  const createGoogleCalendarLink = () => {
      // Extract the date part from the full ISO string (e.g., 2024-10-04)
    const dateStart = show.date_start ? show.date_start.split('T')[0] : '';
    
    // Extract the time part from the full ISO string (e.g., 20:00:00)
    const timeStart = show.time_start ? show.time_start.split('T')[1].split('.')[0] : '';
  
    // Check if the date is valid
    if (!dateStart || !/^\d{4}-\d{2}-\d{2}$/.test(dateStart)) {
      console.error('Invalid date format');
      return 'https://www.google.com/calendar/'; // Return base Google Calendar link if the date is invalid
    }
  
    // Construct the start and end times
    let startDate, endDate;
  
    if (timeStart && /^\d{2}:\d{2}:\d{2}$/.test(timeStart)) {
      // If time is provided, create a Date object with date and time
      startDate = new Date(`${dateStart}T${timeStart}`);
      if (isNaN(startDate.getTime())) {
        console.error('Invalid date or time value');
        return 'https://www.google.com/calendar/';
      }
  
      // Calculate the end time (2 hours after start time)
      endDate = new Date(startDate.getTime() + 2 * 60 * 60 * 1000); // 2 hours event
    } else {
      // If no time is provided, treat as an all-day event
      startDate = new Date(dateStart);
      if (isNaN(startDate.getTime())) {
        console.error('Invalid date value');
        return 'https://www.google.com/calendar/';
      }
      // End date: 1 day after start date (all-day event)
      endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
    }
  
    // Format the dates
    const startDateTime = startDate.toISOString().replace(/-|:|\.\d\d\d/g, '');
    const endDateTime = endDate.toISOString().replace(/-|:|\.\d\d\d/g, '');
  
    // Encode event details
    const eventName = encodeURIComponent(show.name || 'Event');
    const details = encodeURIComponent(`Check out this show at ${venue[0]?.name || 'the venue'}!`);
  
    // Encode the venue location with trimmed values
    const street = (venue[0]?.addr_street || '').trim();
    const city = (venue[0]?.addr_city || '').trim();
    const state = (venue[0]?.addr_state || '').trim();
    const zip = (venue[0]?.addr_zip || '').trim();
    const location = encodeURIComponent(`${street}, ${city}, ${state} ${zip}`);
  
    // Return the Google Calendar link
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${eventName}&dates=${startDateTime}/${endDateTime}&details=${details}&location=${location}`;
  };

  const renderDescription = () => {
    if (!show.description) return null;
  
    const isTruncated = show.description.length > 100;
    const truncatedDescription = isTruncated
      ? show.description.substring(0, 100) + "..."
      : show.description;
  
    return (
      <div className="mt-4 text-sm text-white">
        <p>
          {isDescriptionExpanded ? show.description : truncatedDescription}{" "}
          {isTruncated && (
            <span
              onClick={toggleDescription}
              className="text-Type-On-Dark-Secondary cursor-pointer underline"
            >
              {isDescriptionExpanded ? "less" : "more"}
            </span>
          )}
        </p>
      </div>
    );
  };

  const handleShare = async () => {
    const shareUrl = new URL(window.location.href);

    if (show.show_id !== undefined) {
      shareUrl.searchParams.set('show_key', String(show.show_key));
    }

    if (navigator.share) {
      try {
        // Modify the shareUrl as needed
        const customUrl = new URL('https://myvibelive.com/search');
        customUrl.searchParams.set('showkey', show.show_key || '');
    
        await navigator.share({
          title: 'Check out this show!',
          text: `Have a look at ${show.name}!`,
          url: customUrl.toString(), // Use the modified URL here
        });
    
        // Log the successful share event
        logEvent(null, 'Share', show.show_id ?? 0, 'Show');
      } catch (error) {
        console.error('Sharing failed', error);
    
        // Log the failed share attempt event
        logEvent(null, 'ShareFailed', show.show_id ?? 0, 'Show');
      }
    } else {
      console.warn('Web Share API is not supported in this browser.');
    
      // Log the unsupported share feature event
      logEvent(null, 'ShareUnsupported', show.show_id ?? 0, 'Show');
    }
  };

  const isWebShareSupported = typeof navigator.share === 'function';

  const formatDateMMMDD = (dateString: string | undefined) => {
    if (!dateString) return "Date not available";
  
    // Check if the date string is in ISO format or plain date (YYYY-MM-DD)
    const date = dateString.includes('T')
      ? new Date(dateString)
      : new Date(`${dateString}T00:00:00Z`); // Treat plain date as UTC
  
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }
  
    // Format as "Fri, Oct 4"
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'short', 
      month: 'short', 
      day: 'numeric', 
      timeZone: 'UTC' 
    };
  
    return date.toLocaleDateString('en-US', options);
  };

  const DateDisplay: React.FC<{ date?: string; time?: string; price?: string }> = ({ date, time, price }) => {
    if (!date) return <div>Date not available</div>;
  
    const formattedDate = formatDateMMMDD(date);
  
    if (!time) return <div>{formattedDate} {price ? ` ${price}` : ''}</div>;
  
    // Assume the time is in ISO format or similar, and extract the time part
    const timeMatch = time.match(/T(\d{2}):(\d{2}):/); // Extract the HH and MM parts
  
    let formattedTime = '';
    if (timeMatch) {
      let hours = parseInt(timeMatch[1], 10);
      const minutes = timeMatch[2];
      const ampm = hours >= 12 ? 'PM' : 'AM';
  
      hours = hours % 12;
      hours = hours ? hours : 12; // Convert hour '0' to '12' for 12 AM/PM
  
      formattedTime = `${hours}:${minutes} ${ampm}`;
    } else {
      // If the time is not in an ISO format, fallback to the original time string
      formattedTime = time;
    }
  
    return (
      <div>
        {formattedDate} {formattedTime} {price ? ` ${price}` : ''}
      </div>
    );
  };

  console.log('show', show)

  return (
<div className="relative w-full">
    {/* Content Section */}
    <div className="flex flex-col w-full">
      <div className="flex gap-2 w-full">
        <h2
          className="flex-1 shrink my-auto text-3xl font-bold tracking-tighter leading-8 text-white basis-0"
          style={{ fontFamily: 'League Spartan, sans-serif' }}
        >
          {show.name}
        </h2>
      </div>
      <div className="text-Type-On-Dark-Secondary flex gap-6 justify-between mt-2 text-base font-semibold">
        <DateDisplay date={show.date_start} time={show.time_start} price={show.price} />  
        {venue[0] && (
          <div>{venue[0].addr_city}, {venue[0].addr_state}</div>
        )}
      </div>
      <div className="text-Type-On-Dark-Secondary flex gap-6 justify-between text-base font-semibold">
        {venue[0] && (
          <a href={`venue?venuekey=${venue[0].venue_key}`}>
            <div>{venue[0]?.name}</div>
          </a>
        )}
        <div className="flex gap-2">
          {genres.map((genre, index) => (
            <span key={index}>
              {genre.description}{index < genres.length - 1 ? '' : ''}
            </span>
          ))}
        </div>
      </div>
      <div className="flex gap-2 pr-20 mt-6">
        {show.ticket_link && (
          <a
            href={show.ticket_link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            onClick={() => logEvent(null, 'Click', show.show_id ?? 0, 'Show','Ticket')}  // Log event for ticket link
          >
            <img src="icon-ticket.svg" alt="" className="w-6 aspect-square" />
          </a>
        )}
        {show.flight_link && (
          <a
            href={show.flight_link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            onClick={() => logEvent(null, 'Click', show.show_id ?? 0, 'Show','Flight')}  // Log event for flight link
          >
            <img src="icon-plane.svg" alt="" className="w-6 aspect-square" />
          </a>
        )}
        {show.official_link && (
          <a
            href={show.official_link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            onClick={() => logEvent(null, 'Click', show.show_id ?? 0, 'Show','Official')}  // Log event for official site link
          >
            <img src="icon-website.svg" alt="" className="w-6 aspect-square" />
          </a>
        )}
        {show.fb_link && (
          <a
            href={show.fb_link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            onClick={() => logEvent(null, 'Click', show.show_id ?? 0, 'Show','Facebook')}  // Log event for Facebook link
          >
            <img src="icon-facebook.svg" alt="" className="w-6 aspect-square" />
          </a>  
        )}
        {show.ig_link && (
          <a
            href={show.ig_link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            onClick={() => logEvent(null, 'Click', show.show_id ?? 0, 'Show', 'Instagram')}  // Log event for Instagram link
          >
            <img src="icon-instagram.svg" alt="" className="w-6 aspect-square" />
          </a>
        )}
        {/* Add Google Calendar Button */}
        <a
          href={createGoogleCalendarLink()}
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          onClick={() => logEvent(null, 'Click', show.show_id ?? 0, 'Show', 'GoogleCalendar')}  // Log event for Google Calendar
        >
          <img src="icon-schedule.svg" alt="Add to Calendar" className="w-6 aspect-square" />
        </a>
        {venue[0].addr_street && venue[0].addr_city && venue[0].addr_state && venue[0].addr_zip && (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${venue[0].name}, ${venue[0].addr_street}, ${venue[0].addr_city}, ${venue[0].addr_state} ${venue[0].addr_zip}`)}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            onClick={() => logEvent(null, 'Click', show.show_id ?? 0, 'Show', 'GoogleMaps')}  // Log event for Google Maps link
          >
            <img src="icon-google-maps.svg" alt="Open in Google Maps" className="w-6 aspect-square" />
          </a>
        )}   
        {isWebShareSupported && (           
          <button
            onClick={() => {
              handleShare();
            }}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-share.svg" alt="" className="w-6 aspect-square" />
          </button>
        )}                                
      </div>

      {/* Render the description here */}
      {renderDescription()}
    </div>
  </div> 
  );
};

export default ShowHeader;