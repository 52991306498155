import React, { useState, useEffect } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { useQuery } from '@tanstack/react-query';
import { fetchGenres } from "../lib/data";
import { Genre } from "../lib/definitions";

interface FilterButtonsProps {
  selectedGenre: string | null;
  setSelectedGenre: React.Dispatch<React.SetStateAction<string | null>>;
  selectedGenreId: number | null;
  setSelectedGenreId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedDistance: string | null;
  setSelectedDistance: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTimePeriod: string | null;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<string | null>>;
  userLat: string | null;
  userLon: string | null;
  setUserLat: React.Dispatch<React.SetStateAction<string | null>>;
  setUserLon: React.Dispatch<React.SetStateAction<string | null>>;
}

const FilterButtons: React.FC<FilterButtonsProps> = ({
  selectedGenre,
  setSelectedGenre,
  selectedGenreId,
  setSelectedGenreId,  
  selectedDistance,
  setSelectedDistance,
  selectedTimePeriod,
  setSelectedTimePeriod,
  userLat,
  userLon,
  setUserLat,
  setUserLon
}) => {
  const { data: genres = [], error, isLoading } = useQuery<Genre[], Error>({
    queryKey: ['genres'],
    queryFn: fetchGenres,
  });

  const [isGenrePopoverOpen, setIsGenrePopoverOpen] = useState<boolean>(false);
  const [isDistancePopoverOpen, setIsDistancePopoverOpen] = useState<boolean>(false);
  const [isTimePeriodPopoverOpen, setIsTimePeriodPopoverOpen] = useState<boolean>(false);
  const [locationInput, setLocationInput] = useState<string>('');
  const [locationError, setLocationError] = useState<string | null>(null);
  const [cityState, setCityState] = useState<string | null>(null);

  const handleGenreSelect = (description: string | null, id: number | null) => {
    setSelectedGenre(description);
    setSelectedGenreId(id);
    setIsGenrePopoverOpen(false);  // Close the popover
  };

  const handleDistanceSelect = (distance: string) => {
    setSelectedDistance(distance);
    setIsDistancePopoverOpen(false);  // Close the popover
  };

  const handleTimePeriodSelect = (period: string) => {
    setSelectedTimePeriod(period);
    setIsTimePeriodPopoverOpen(false);  // Close the popover
  };

  // Function to get the latitude and longitude of the input location using Google Geocoding API
  const handleLocationSubmit = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY; // Use your Google API key here
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(locationInput)}&key=${apiKey}`);

      if (!response.ok) {
        throw new Error('Unable to find the location. Please try again.');
      }

      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        setUserLat(location.lat.toString());
        setUserLon(location.lng.toString());
        setLocationError(null); // Clear any previous errors

        // Fetch city and state using reverse geocoding
        getCityState(location.lat, location.lng);
      } else {
        setLocationError('Unable to find the location. Please try again.');
      }
    } catch (error: unknown) {
        setLocationError('An unknown error occurred.');
    }
  };

  // Function to reverse geocode latitude and longitude to get city and state
  const getCityState = async (lat: number, lon: number) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY; // Use your Google API key here
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`);

      if (!response.ok) {
        throw new Error('Failed to reverse geocode coordinates');
      }

      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0].address_components;
        const city = addressComponents.find((comp: any) => comp.types.includes('locality'))?.long_name;
        const state = addressComponents.find((comp: any) => comp.types.includes('administrative_area_level_1'))?.long_name;

        if (city && state) {
          setCityState(`${city}, ${state}`);
        } else {
          setCityState('Location not found');
        }
      }
    } catch (error: unknown) {
      setCityState('Error retrieving city and state');
      console.error(error);
    }
  };

  // If userLat and userLon are available, fetch city and state on component load
  useEffect(() => {
    if (userLat && userLon) {
      getCityState(parseFloat(userLat), parseFloat(userLon));
    }
  }, [userLat, userLon]);

  return (
    <div className="flex gap-4 items-start mt-4 w-full text-sm text-white">
      {/* Genre Selection */}
      <Popover open={isGenrePopoverOpen} onOpenChange={setIsGenrePopoverOpen}>
        <PopoverTrigger 
          className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700"
        >
          {selectedGenre ?? "Vibe"}
        </PopoverTrigger>
        <PopoverContent className="w-[200px]">
          {isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {(error as Error).message}</div>
          ) : (
            <>
              <div 
                key={0} 
                onClick={() => handleGenreSelect('All Vibes', null)}
                className="cursor-pointer"
              >
                {'All Vibes'}
              </div>
              {genres.map((genre) => (
                <div 
                  key={genre.genre_id} 
                  onClick={() => handleGenreSelect(genre.description ?? null, genre.genre_id ?? null)}
                  className="cursor-pointer"
                >
                  {genre.description}
                </div>
              ))}
            </>
          )}
        </PopoverContent>
      </Popover>

      {/* Distance Selection */}
      <Popover open={isDistancePopoverOpen} onOpenChange={setIsDistancePopoverOpen}>
        <PopoverTrigger 
          className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700"
        >
          {selectedDistance ?? "Within 100 Miles"}
        </PopoverTrigger>
        <PopoverContent className="w-[200px]">
          {["Within 10 Miles", "Within 25 Miles", "Within 50 Miles", "Within 100 Miles"].map((distance) => (
            <div 
              key={distance} 
              onClick={() => handleDistanceSelect(distance)}
              className="cursor-pointer"
            >
              {distance}
            </div>
          ))}

          {/* Line separator */}
          <hr className="my-2 border-t border-gray-700" />

          {/* Current Location */}
          <div className="flex flex-col gap-2">
            <p className="text-gray-400">Current Location: {cityState ?? 'Fetching location...'}</p>
          </div>

          {/* Location Input */}
          <div className="flex flex-col gap-2">
            <input 
              type="text" 
              value={locationInput} 
              onChange={(e) => setLocationInput(e.target.value)} 
              placeholder="Enter a location"
              className="w-full py-1 px-2 border rounded-md border-gray-400"
            />
            <button 
              onClick={handleLocationSubmit} 
              className="px-3 py-1 bg-black text-white rounded-md"
            >
              Set Location
            </button>
            {locationError && (
              <p className="text-red-500">{locationError}</p>
            )}
          </div>
        </PopoverContent>
      </Popover>

      {/* Time Period Selection */}
      <Popover open={isTimePeriodPopoverOpen} onOpenChange={setIsTimePeriodPopoverOpen}>
        <PopoverTrigger 
          className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700"
        >
          {selectedTimePeriod ?? "Next 30 Days"}
        </PopoverTrigger>
        <PopoverContent className="w-[150px]">
          {["Today", "Tomorrow", "Next 7 Days", "Next 30 Days", "Next 90 Days"].map((period) => (
            <div 
              key={period} 
              onClick={() => handleTimePeriodSelect(period)}
              className="cursor-pointer"
            >
              {period}
            </div>
          ))}
        </PopoverContent>
      </Popover>           
    </div>
  );
};

export default FilterButtons;