import React from "react";

// Footer Component
const Footer: React.FC = () => {
  return (
    <div
      className="w-full flex flex-col items-center px-4 py-0 bg-zinc-950 md:px-16"
      style={{
        background: "linear-gradient(180deg, #0a0a0b 0%, #1a0d2e 30%, #321752 70%, #3A1D5D 100%)",
        marginTop: "0",
        paddingTop: "0",
      }}
    >
      <div className="flex flex-wrap items-center justify-between w-full px-8 py-4 md:flex-row flex-col">
        {/* Logo */}
        <div className="mb-4 md:mb-0">
          <img
            loading="lazy"
            src="myvibelive logo.png"
            alt="Company Logo"
            className="w-[100px] object-contain"
          />
        </div>

        {/* Links */}
        <div className="flex flex-wrap items-center gap-6 mb-4 md:mb-0 md:flex-row flex-col">
          <a href="/search">
            <div className="text-sm font-medium leading-6 text-white">Show Search</div>
          </a>
          <a href="/#aboutus">
            <div className="text-sm font-medium leading-6 text-white">About Us</div>
          </a>
          <a href="/workwithus">
            <div className="text-sm font-medium leading-6 text-white">Work with us</div>
          </a>
          <a href="/login">
            <div className="text-sm font-medium leading-6 text-white">Artist / Venue Login</div>
          </a>
        </div>

        {/* Social Media Icons */}
        <div className="flex items-center space-x-6 md:space-x-6 md:mt-0 mt-4">
          <a href="https://www.facebook.com/myvibelive" target="_blank" rel="noopener noreferrer">
            <img src="icon-facebook.svg" alt="Facebook Icon" className="w-6 h-6" />
          </a>
          <a href="https://www.instagram.com/myvibe.live" target="_blank" rel="noopener noreferrer">
            <img src="icon-instagram.svg" alt="Instagram Icon" className="w-6 h-6" />
          </a>
          <a href="https://www.tiktok.com/@myvibe.live" target="_blank" rel="noopener noreferrer">
            <img src="icon-tiktok.svg" alt="TikTok Icon" className="w-6 h-6" />
          </a>
        </div>
      </div>

      {/* Divider */}
      <div className="w-full h-[1px] bg-gray-500 mb-6"></div>

      {/* Footer Links */}
      <div className="text-center text-sm font-semibold text-white">
        2025 myVibe.live. All rights reserved.
      </div>
      <div className="flex flex-col items-center gap-2 mt-4 md:flex-row md:gap-6">
        <a href="/notifications" className="text-sm font-semibold text-center text-white">
          Manage Notifications
        </a>
        <a href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="text-sm font-semibold text-center text-white">
          Privacy Policy
        </a>
        <a href="/termsofservice" target="_blank" rel="noopener noreferrer" className="text-sm font-semibold text-center text-white">
          Terms of Service
        </a>
      </div>
    </div>
  );
};

export default Footer;