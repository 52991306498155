import React from 'react';

interface DescriptionFieldProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void; // Change to HTMLTextAreaElement for textarea
  placeholder?: string;
  containerClassName?: string;
}

const DescriptionField: React.FC<DescriptionFieldProps> = ({
  label,
  value,
  onChange,
  placeholder = "",
  containerClassName = "",
}) => {
  return (
    <div className={`flex flex-col mt-2 w-full ${containerClassName}`}>
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <textarea
        value={value}
        onChange={onChange} // Handles textarea input change
        placeholder={placeholder}
        className="p-2 mt-2 w-full text-base font-medium bg-white rounded-md border border-solid border-zinc-700 min-h-[240px] break-words text-left"
      />
    </div>
  );
};

export default DescriptionField;