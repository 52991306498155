import React, { useEffect, useState } from "react";
import AutoComplete from "./AutoComplete";
import { getLocation } from "../lib/location"; 

interface NoShowsProps {
  userLat: string | null;
  userLon: string | null;
  setUserLat: React.Dispatch<React.SetStateAction<string | null>>;
  setUserLon: React.Dispatch<React.SetStateAction<string | null>>;
}

const NoShows: React.FC<NoShowsProps> = ({ userLat, userLon, setUserLat, setUserLon }) => {
  const [cityState, setCityState] = useState<string>("Checking your location...");
  const [loading, setLoading] = useState<boolean>(true);
  const [locationInput, setLocationInput] = useState<string>("");
  const [locationError, setLocationError] = useState<string | null>(null);

  // Save latitude & longitude to local storage
  const saveLocationToLocalStorage = (lat: string, lon: string, city: string, state: string) => {
    localStorage.setItem("userLat", lat);
    localStorage.setItem("userLon", lon);
    localStorage.setItem("cityState", `${city}, ${state}`);
  };

  // Retrieve saved location from local storage
  useEffect(() => {
    const storedLat = localStorage.getItem("userLat");
    const storedLon = localStorage.getItem("userLon");
    const storedCityState = localStorage.getItem("cityState");

    if (storedLat && storedLon && storedCityState) {
      setUserLat(storedLat);
      setUserLon(storedLon);
      setCityState(storedCityState);
      setLocationInput(storedCityState);
      setLoading(false);
    } else if (userLat && userLon) {
      getCityState(userLat, userLon);
    }
  }, []);

  // Reverse geocode lat/lon to get city & state
  const getCityState = async (lat: string, lon: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
      );

      if (!response.ok) throw new Error("Failed to reverse geocode coordinates");

      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0]?.address_components || [];
        const city = addressComponents.find((comp: any) =>
          comp.types.includes("locality")
        )?.long_name || "";
        const state = addressComponents.find((comp: any) =>
          comp.types.includes("administrative_area_level_1")
        )?.short_name || "";

        if (city && state) {
          setCityState(`${city}, ${state}`);
          setLocationInput(`${city}, ${state}`);
          saveLocationToLocalStorage(lat, lon, city, state);
        } else {
          setCityState("Location not found");
        }
      } else {
        setCityState("Location not found");
      }
    } catch (error) {
      setCityState("Error retrieving city and state");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch lat/lon from city/state when user selects a new location
  const handleLocationSubmit = async (city: string, state: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${city}, ${state}`)}&key=${apiKey}`
      );

      if (!response.ok) throw new Error("Unable to find the location. Please try again.");

      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        const lat = location.lat.toString();
        const lon = location.lng.toString();

        setUserLat(lat);
        setUserLon(lon);
        setCityState(`${city}, ${state}`);
        setLocationInput(`${city}, ${state}`);
        saveLocationToLocalStorage(lat, lon, city, state);
      } else {
        setLocationError("Unable to find the location. Please try again.");
      }
    } catch (error) {
      setLocationError("An unknown error occurred.");
    }
  };

  const handleResetLocation = async () => {
    try {
      const { lat, lon, method } = await getLocation();
      setUserLat(lat);
      setUserLon(lon);
      localStorage.setItem("userLat", lat);
      localStorage.setItem("userLon", lon);
      console.log("Location reset using:", method);
    } catch (error) {
      console.error("Failed to reset location:", error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center text-center py-12">
      <div className="flex flex-col items-center justify-center text-center py-12">
        <img src="no_shows.webp" alt="No shows available" className="w-[200px] h-auto rounded-lg" />
        <h2 className="text-2xl font-bold text-zinc-950 mt-6 px-2 text-center">
          {loading ? "Checking your location..." : `No shows in ${cityState}`}
        </h2>

        <div className="mt-6 w-full max-w-md px-4">
          <AutoComplete
            value={locationInput}
            setValue={setLocationInput} // Ensures dropdown updates correctly
            setLocation={(city, state) => handleLocationSubmit(city, state)}
          />
          {locationError && <p className="mt-2 text-sm text-red-600">{locationError}</p>}
        </div>
        <button
          onClick={handleResetLocation}
          className="px-4 py-2 mt-4 rounded-md bg-zinc-950 text-white border border-zinc-700"
        >
          Reset Location
        </button>
      </div>
      
      <p className="text-sm text-zinc-400 mt-2 max-w-lg">
        Follow us and we'll let you know when we're in your area!
      </p>

      {/* Social links */}
      <div className="flex gap-4 mt-4">
        <a
          href="https://www.facebook.com/profile.php?id=61565715673369"
          className="flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="icon-facebook-color.png"
            alt="Facebook"
            className="w-8 h-8 mr-2 rounded-full"
          />
        </a>
        <a
          href="https://www.instagram.com/myvibelive/"
          className="flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="icon-instagram-color.jpg"
            alt="Instagram"
            className="w-8 h-8 mr-2 rounded-full"
          />
        </a>
        <a
          href="https://www.tiktok.com/@myvibe.live"
          className="flex items-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="icon-tiktok-color.png"
            alt="TikTok"
            className="w-8 h-8 mr-2 rounded-full"
          />
        </a>
      </div>
    </div>
  );
};

export default NoShows;