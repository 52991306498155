import React, { useEffect, useState } from "react";
import { Artist } from "../lib/definitions";
import { fetchPlaylistArtists } from "../lib/data"; // Adjust the import path if needed

interface ArtistLineupProps {
  artists: Artist[];
  show_image_thumb?: string;
  venue_image_thumb?: string;
  playlist: string | null;  // Allow null for playlist
  email: string | null;  // Ensure email can be string or nulls
}

const ArtistLineup: React.FC<ArtistLineupProps> = ({
  artists,
  show_image_thumb,
  venue_image_thumb,
  playlist,
  email,
}) => {
  const [fetchedArtists, setFetchedArtists] = useState<{ id: string }[]>([]);

  // Fetch the playlist artists when the component mounts or when playlist/email changes
  useEffect(() => {
    const fetchArtists = async () => {
      if (playlist && email) {
        try {
          const artistsFromPlaylist = await fetchPlaylistArtists(email, playlist);
          setFetchedArtists(artistsFromPlaylist);  // Set the fetched artists state
        } catch (error) {
          console.error('Error fetching artists from playlist:', error);
        }
      } else {
        console.error('Both playlist and email are required to fetch artists.');
      }
    };
  
    fetchArtists();
  }, [playlist, email]);

  return (
    <div className="flex flex-col px-4 py-6 w-full">
      <div className="flex gap-4 items-center w-full">
        <h3
          className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight text-black basis-0"
          style={{ fontFamily: "League Spartan, sans-serif" }}
        >
          Artist Lineup
        </h3>
      </div>
      <div className="flex flex-wrap gap-2 items-start mt-4 w-full text-xs font-semibold text-center text-zinc-950 overflow-x-auto">
        {artists.map((artist, index) => {
          let imageSrc = "default_thumb.webp"; // Default image

          // Check in order: artist.image_thumb -> show_image_thumb -> venue_image_thumb -> default
          if (artist.image_thumb && artist.sub_level !== "Opener") {
            imageSrc = artist.image_thumb;
          } else if (show_image_thumb) {
            imageSrc = show_image_thumb;
          } else if (venue_image_thumb) {
            imageSrc = venue_image_thumb;
          } else {
            imageSrc = "default_thumb.webp"; // Provide a default fallback if none of the above conditions are met
          }

          // Check if the artist matches by spotify_id only
          const isMatch = fetchedArtists.some(fetched => {
            return String(fetched) === String(artist.spotify_id);  // Ensure they are both strings
          });

          return (
            <a key={index} href={`artist?artistkey=${artist.artist_key}`}>
              <div className="flex flex-col relative">
                <img
                  loading="lazy"
                  src={imageSrc} // Use the imageSrc variable
                  alt={`${artist.name} profile`}
                  className={`object-contain self-center rounded-[50%] aspect-[1] w-[80px] ${
                    isMatch ? "glow" : ""
                  }`} // Add glow class if matched
                />
                {/* Heart icon positioned at the top-right corner of the image */}
                {isMatch && (
                  <span className="absolute top-0 right-0 text-xl text-red-500">
                    ❤️
                  </span>
                )}
                <div className="mt-2 w-[80px]">{artist.name}</div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default ArtistLineup;