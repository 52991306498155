import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-container mx-auto max-w-4xl p-8 bg-white rounded-lg shadow-md">
      <h1 className="text-4xl font-bold mb-6 text-center text-black">Privacy Policy</h1>
      <p className="text-lg mb-6 text-black">Effective Date: 10/01/2024</p>

      <h2 className="text-2xl font-semibold mb-4 text-black">At myVibe.live, your privacy is important to us.</h2>
      <p className="mb-4 text-black">
        This policy explains how we collect, use, and share information about you.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">1. Information We Collect</h2>
      <p className="mb-4 text-black">
        <strong>Personal Information:</strong> When creating an account, we collect your name, email address, and payment details.
      </p>
      <p className="mb-4 text-black">
        <strong>Usage Data:</strong> We collect information about how you interact with the platform, such as your IP address, browser type, and activity on the platform.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">2. How We Use Your Information</h2>
      <p className="mb-4 text-black">
        - To provide and improve our services.<br />
        - To send updates, newsletters, or promotional materials if you opt-in.<br />
        - To process payments for subscription plans.<br />
        - To personalize content and recommendations based on your preferences.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">3. Sharing Your Information</h2>
      <p className="mb-4 text-black">
        We may share your data with third-party vendors, such as payment processors (e.g., Stripe) and analytics providers (e.g., Google Analytics).<br />
        We will not sell your personal data to third parties.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">4. Your Rights</h2>
      <p className="mb-4 text-black">
        You can request access to or deletion of your personal data by contacting admin@myvibelive.com.<br />
        You may opt out of marketing communications at any time.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">5. Data Security</h2>
      <p className="mb-4 text-black">
        We use industry-standard encryption and security measures to protect your data.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">6. Changes to This Policy</h2>
      <p className="mb-4 text-black">
        We may update this policy from time to time. You will be notified of significant changes via email or through our platform.
      </p>

      <h2 className="text-xl font-semibold mb-4 text-black">Contact Us</h2>
      <p className="text-black">For questions or concerns, please contact us at admin@myvibelive.com.</p>
    </div>
  );
};

export default PrivacyPolicy;