import React, { useEffect } from "react";
import MainHeader from "../components/MainHeader";
import Footer from "../components/Footer";
import { sendContactMessage } from "../lib/data";

// Mock Data for Feature Cards
const featureCards = [
  { title: "Live Concerts", description: "Discover live performances near you." },
  { title: "Festivals", description: "Find the best local festivals happening now." },
  { title: "Hidden Gems", description: "Explore unique venues and hidden music gems." },
];

// FeatureCard Component
const FeatureCard: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <div className="flex flex-col flex-1 shrink justify-center p-6 border border-solid border-zinc-700 min-w-[240px] max-md:px-5">
    <div className="text-3xl font-bold text-white">{title}</div>
    <div className="mt-4 text-sm font-semibold text-zinc-400">{description}</div>
  </div>
);

// SectionHeader Component for H1 and H2 styling
const SectionHeader: React.FC<{ title: string }> = ({ title }) => (
  <h2
    className="text-6xl font-bold text-white max-md:text-4xl"
    style={{ fontFamily: "League Spartan, sans-serif" }}
  >
    {title}
  </h2>
);

// SectionParagraph Component for paragraphs
const SectionParagraph: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <div className="mt-6 text-lg leading-7 text-zinc-400 max-md:text-base">{children}</div>
);

export const SubmitButton: React.FC<SubmitButtonProps> = ({ text }) => {
  return (
    <div className="flex gap-4 items-start self-center pt-4 mt-6 text-base font-medium text-center text-white whitespace-nowrap">
      <div className="flex gap-4 justify-center items-center">
        <button
          type="submit"
          className="gap-2 self-stretch px-6 py-4 my-auto rounded-md border border-solid bg-zinc-950 border-zinc-700 max-md:px-5"
        >
          {text}
        </button>
      </div>
    </div>
  );
}

export const InputField: React.FC<{
  label: string;
  name: string;
  type?: string;
  minHeight?: string;
  placeholder?: string;
}> = ({ label, name, type = "text", minHeight = "40px", placeholder }) => {
  const inputId = `${label.toLowerCase().replace(/\s/g, "-")}-input`;

  return (
    <div className="flex flex-col w-full text-xs font-semibold text-center text-neutral-400 max-md:max-w-full">
      <label htmlFor={inputId} className="self-start">{label}</label>
      {type === "textarea" ? (
        <textarea
          id={inputId}
          name={name}
          className="flex-1 shrink gap-2 p-2 mt-2 text-base font-medium rounded-md border border-solid bg-zinc-700 border-zinc-700 size-full max-md:max-w-full"
          style={{ minHeight }}
          placeholder={placeholder}
        />
      ) : (
        <input
          id={inputId}
          name={name}
          type={type}
          className="flex gap-2 mt-2 w-full rounded-md border border-solid bg-zinc-700 border-zinc-700 max-md:max-w-full"
          style={{ minHeight }}
        />
      )}
    </div>
  );
};

export interface InputFieldProps {
  label: string;
  type?: string;
  minHeight?: string;
  placeholder?: string;
}

export interface SubmitButtonProps {
  text: string;
}


export const Landing: React.FC = () => {
  const [statusMessage, setStatusMessage] = React.useState<string | null>(null);

  // Scroll to the correct section when the hash in the URL changes
  useEffect(() => {
    const hash = window.location.hash; // Get the current hash from the URL
    if (hash) {
      const targetElement = document.querySelector(hash); // Select the element using the hash
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" }); // Smoothly scroll to the element
      }
    }
  }, []); // Run only on initial render
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
  
    const formData = new FormData(e.target as HTMLFormElement);
    const data = {
      name: formData.get("name") as string,
      email: formData.get("email") as string,
      phone: formData.get("phone") as string,
      bandname: "",
      venuename: "",
      message: formData.get("message") as string,
    };
  
    try {
      await sendContactMessage(data);
      setStatusMessage("Thanks for your message! We'll get back to you shortly.");
    } catch (error) {
      setStatusMessage("Failed to send your message. Please try again later.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-zinc-950">
      <MainHeader />
      <main>
        {/* Hero Section */}
        <section className="relative flex flex-col gap-10 px-16 min-h-[900px] w-full max-md:px-5 overflow-hidden">
          <img
            loading="lazy"
            src="landing-header.png"
            alt="Hero Background"
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="relative z-10 flex flex-col my-auto max-w-[560px] text-white w-full px-5">
            <h1
              className="text-6xl font-bold leading-[56px] max-md:text-4xl max-md:leading-tight max-sm:text-3xl max-sm:leading-snug"
              style={{ fontFamily: "League Spartan, sans-serif" }}
            >
              Find local music to fit your vibe
            </h1>
            <p className="mt-6 text-lg max-md:text-base max-md:mt-4">
              Love live music? myVibe.live makes it easy to find concerts, gigs, and
              festivals happening in your area.
            </p>
            <a href="/search">
              <button className="rounded-md px-6 py-4 mt-10 text-base font-medium text-white border border-solid bg-zinc-950 border-zinc-700 max-md:px-5 max-md:py-3 max-md:mt-6">
                Search Shows Now
              </button>
            </a>
          </div>
        </section>

        {/* Features Section */}
        <section className="flex flex-col px-16 py-28 bg-zinc-950 min-h-[530px] max-md:px-5 max-md:py-24">
          <SectionHeader title="Local Shows and Festivals" />
          <div className="flex flex-wrap gap-10 mt-20">
            {featureCards.map((card, index) => (
              <FeatureCard key={index} {...card} />
            ))}
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="flex flex-col items-center px-16 py-28 text-center text-white min-h-[490px] max-md:px-5 max-md:py-24">
          <div className="flex flex-col items-center">
            <SectionHeader title="Ready to find your vibe?" />
            <p className="mt-6 text-sm font-semibold text-zinc-400 w-[350px]">
              Connecting you with your favorite local artists and venues.
            </p>
            <a href="/search">
              <button className="px-6 py-4 mt-10 text-base font-medium border border-solid bg-zinc-950 border-zinc-700">
                Search Shows Now
              </button>
            </a>
          </div>
        </section>

        {/* About Section */}
        <section id="aboutus" className="flex flex-col px-16 py-28 bg-zinc-950 max-md:px-5 max-md:py-24">
          <div className="flex flex-row gap-10 items-center w-full max-md:flex-col max-md:max-w-full">
            {/* Text Section */}
            <div className="flex flex-col flex-1 basis-0 min-w-[240px] max-md:max-w-full">
              <SectionHeader title="How myVibe.live Got Started" />
              <SectionParagraph>
                <p className="mt-4">
                  myVibe.live was born out of a friendship between two music fans, Mike and Matt,
                  who met while working at the same company after college. We're part of a close-knit
                  group of friends who share countless passions—playing poker, cheering for the Packers,
                  Brewers, Bucks, and Badgers, annual canoe trips, and even family vacations. But one
                  passion unites us all: our love of music.
                </p>
                <p className="mt-4">
                  Our group's musical tastes are as diverse as they come, from mellow vibes to
                  head-banging rock. Music is music, and we love it all. We have group chats dedicated
                  to new Spotify finds, shared playlists we all contribute to, and most importantly, we
                  jump at every chance to see our favorite artists live.
                </p>
                <p className="mt-4">
                  For us, the Mile of Music festival in Appleton, WI, is a highlight of the year.
                  Over four unforgettable days, hundreds of original artists perform across venues
                  along a one-mile stretch of College Avenue. Every year, we find ourselves digging
                  through Spotify, trying to figure out which bands we've saved and when they're playing.
                  One night, while talking in Matt's man cave, we realized: finding live music should be
                  easier. And that's when myVibe.live was born.
                </p>
                <p className="mt-4">
                  We envisioned a platform where fans could search for live music by genre and location.
                  Then the ideas started rolling: sharing events with friends, discovering new artists,
                  and more. Inspired by the vision, Matt dove headfirst into coding to bring it to life—a
                  true passion project built from the ground up.
                </p>
              </SectionParagraph>
            </div>

            {/* Image Section */}
            <div className="flex flex-1 justify-center self-center">
              <img
                loading="lazy"
                src="mike-and-matt.png"
                alt="MyVibeLive founders sharing their musical journey"
                className="object-contain w-full max-w-[500px] aspect-[0.96]"
              />
            </div>
          </div>
        </section>

        {/* Mission Section */}
        <section className="flex flex-col px-16 py-28 bg-zinc-950 max-md:px-5 max-md:py-24">
          <div className="flex flex-col max-w-full mx-auto text-white">
            <SectionHeader title="Our Mission" />
            <SectionParagraph>
              We want myVibe.live to help you discover incredible live music and create memories that
              last a lifetime. Whether it's a Friday night show at a local venue or a festival
              performance you'll never forget, we aim to make finding live music seamless and exciting.
              There's so much talent out there—artists playing in the next town over or just down the
              block. myVibe.live wants to connect you to them.
            </SectionParagraph>
            <SectionParagraph>
              Thank you for checking us out! Follow us on social media, share your favorite shows, and
              let us know who rocked your world. We're always looking for the next great live
              performance—and we hope myVibe.live becomes your go-to guide for discovering it.
            </SectionParagraph>
          </div>
        </section>

        {/* Contact Us Section */}
        <section className="flex flex-col px-16 py-28 bg-zinc-950 max-md:px-5 max-md:py-24">
          <div className="flex overflow-hidden flex-col items-center px-16 py-28 max-md:px-5 max-md:py-24">
            <div className="flex flex-col max-w-full text-center text-white w-[768px]">
              <div className="flex flex-col w-full max-md:max-w-full">
                <h1 className="text-6xl font-bold tracking-tighter leading-none max-md:max-w-full max-md:text-4xl">
                  We want to hear from you!
                </h1>
                <p className="mt-6 text-lg leading-loose max-md:max-w-full">
                  Please fill out the form below to get in touch with us.
                </p>
              </div>
            </div>

            {/* Conditionally render the form */}
            {!statusMessage?.includes("Thanks") ? (
              <form onSubmit={handleSubmit} className="flex flex-col mt-20 max-w-full w-[560px] max-md:mt-10">
                <InputField label="Name" name="name" type="text" />
                <div className="mt-6">
                  <InputField label="Email" name="email" type="email" />
                </div>
                <div className="mt-6">
                  <InputField label="Phone Number" name="phone" type="tel" />
                </div>
                <div className="mt-6">
                  <InputField label="Message" name="message" type="textarea" minHeight="126px" />
                </div>
                <SubmitButton text="Submit" />
              </form>
            ) : (
              <div
                className={`mt-6 px-6 py-4 rounded-md text-white text-center max-w-[560px]`}
              >
                {statusMessage}
              </div>
            )}
          </div>
        </section>

      </main>
      <Footer />
    </div>
  );
};

export default Landing;