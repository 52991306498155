import React, { useState, useEffect } from 'react';
import { fetchArtistVenueSearch } from '../lib/data';
import { ArtistVenueSearchResults } from '../lib/definitions';


const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const SearchBar: React.FC<{}> = () => {
  const [inputValue, setInputValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<ArtistVenueSearchResults[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedInputValue = useDebounce(inputValue, 500);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedInputValue) {
        setLoading(true);
        try {
          const data = await fetchArtistVenueSearch(debouncedInputValue);
          if (data) {
            setSuggestions(data);
          } else {
            setSuggestions([]);
          }
        } catch (error) {
          console.error('Error fetching suggestions:', error);
          setSuggestions([]);
        } finally {
          setLoading(false);
        }
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [debouncedInputValue]);


  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInputValue(suggestion);
    setSuggestions([]);
  };

  return (
<div className="relative w-full">
  <div className="flex gap-4 items-start w-full text-base bg-white rounded-full text-neutral-400">
    <a href="/">
      <img
        loading="lazy"
        src="logo.png"
        alt="Logo"
        className="object-contain shrink-0 aspect-square w-[58px]"
      />
    </a>
    <input
      type="search"
      value={inputValue}
      onChange={handleInputChange}
      placeholder="Search Venues & Artists"
      aria-label="Search Venues & Artists"
      className="overflow-visible flex-1 shrink self-stretch rounded-full min-w-[240px] bg-white text-center"
    />
  </div>
  {loading && <div className="absolute top-full left-0 w-full p-2 bg-white text-center z-50">Loading...</div>}
  {suggestions.length > 0 && (
    <div className="absolute top-full left-0 w-full bg-white border border-gray-200 rounded-md mt-2 shadow-lg z-50">
      <ul className="divide-y divide-gray-100">
        {suggestions.slice(0, 6).map((suggestion, index) => (
          <li key={index} className="p-2 hover:bg-gray-100">
            <a href={`${suggestion.link_type === 'artist' ? 'artist' : 'venue'}?${suggestion.link_type}key=${suggestion.key}`} className="flex items-center">
              {suggestion.image_src && (
                <img 
                  src={suggestion.image_src} 
                  alt={suggestion.name} 
                  className="w-8 h-8 object-cover rounded-full mr-3" 
                />
              )}
              <div>
                <div className="text-sm font-medium text-gray-900">{suggestion.name}</div>
                <div className="text-xs text-gray-500">{suggestion.additional_data}</div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  )}
</div>
  );
};


export default SearchBar;