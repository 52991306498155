import React from "react";
import { FaLock, FaLockOpen, FaTrash, FaRecycle, FaMapMarkerAlt } from "react-icons/fa";
import { Show } from "../lib/definitions";

interface ItineraryDisplayShowProps {
  time: {
    hour: string;
    period: string;
  };
  venue: {
    name: string;
    detail: string;
  };
  show: Show;
  locked: boolean;
  onLockToggle: () => void;
  onDelete: () => void;
  onChange: () => void;
}

export const ItineraryDisplayShow: React.FC<ItineraryDisplayShowProps> = ({
  time,
  venue,
  show,
  locked,
  onLockToggle,
  onDelete,
  onChange,
}) => {
  return (
    <article className="flex flex-col p-3 text-white rounded-lg border border-solid bg-zinc-950 border-neutral-400 max-sm:gap-2">
      <div className="flex items-center gap-4">
        {/* Time */}
        <div className="flex flex-col items-center justify-center text-lg font-bold w-12">
          <time className="leading-none">{time.hour}</time>
          <span className="text-xs font-medium text-gray-400">{time.period}</span>
        </div>

        {/* Venue Info */}
        <div className="flex flex-col justify-center h-full">
          <a 
            target="_blank"
            rel="noopener noreferrer"
            href={`/search?showkey=${show?.show_key}`} 
          >
            <h2 className="text-base font-bold leading-none">{show.name}</h2>
          </a>
          
          <a 
            target="_blank"
            rel="noopener noreferrer"
            href={`/venue?venuekey=${show?.venues?.[0]?.venue_key}`} 
          >
            <p className="text-xs text-Type-On-Dark-Secondary">
              {show?.venues?.[0]?.name}
            </p>
          </a>
        </div>

        {/* Action Buttons */}
        <div className="flex gap-2 ml-auto">
          {/* Delete Button (Replacing Lock Button) */}
          <button
            className="flex justify-center items-center w-7 h-7 text-white rounded-md border border-solid cursor-pointer border-zinc-700 hover:bg-red-500"
            aria-label="Delete show"
            onClick={(e) => {
              e.stopPropagation(); // Prevent navigation
              onDelete();
            }}
          >
            <FaTrash size={14} />
          </button>

          {/* Lock Toggle Button (Commented Out) */}
          {/*
          <button
            className="flex justify-center items-center w-7 h-7 text-white rounded-md border border-solid cursor-pointer border-zinc-700"
            aria-label="Lock show"
            onClick={(e) => {
              e.stopPropagation(); // Prevent navigation
              onLockToggle();
            }}
          >
            {locked ? <FaLock size={14} /> : <FaLockOpen size={14} />}
          </button>
          */}

          {/* Change Show Button */}
          <button
            className="flex justify-center items-center w-7 h-7 text-white rounded-md border border-solid cursor-pointer border-zinc-700"
            aria-label="Change show"
            onClick={(e) => {
              e.stopPropagation(); // Prevent navigation
              onChange();
            }}
          >
            <FaRecycle size={14} />
          </button>

          {/* Google Maps Button */}
          {show?.venues?.[0]?.addr_lat && show?.venues?.[0]?.addr_lon && (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${show.venues[0].addr_lat},${show.venues[0].addr_lon}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex justify-center items-center w-7 h-7 text-white rounded-md border border-solid cursor-pointer border-zinc-700"
              aria-label="Open in Google Maps"
              onClick={(e) => e.stopPropagation()} // Prevent accidental event bubbling
            >
              <FaMapMarkerAlt size={14} />
            </a>
          )}
        </div>
      </div>
    </article>
  );
};