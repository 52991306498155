import './app.css';
import Search from './components/Search';
import ShowCard from './components/ShowCard';
import Footer from './components/Footer';
import NoShows from './components/NoShows';
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchShows, logEvent } from './lib/data';
import { Show, Venue, Artist, Genre } from "./lib/definitions";
import SearchBar from './components/SearchBar';
import MainHeader from './components/MainHeader';
import { getLocation } from './lib/location';
import ShowFestivalCard from './components/ShowFestivalCard';

function App() {
  const defaultDistance = 'Within 25 Miles'; 
  const defaultTimePeriod = 'Next 7 Days'; 

  const [selectedGenre, setSelectedGenre] = useState<string | null>(null);
  const [selectedGenreId, setSelectedGenreId] = useState<number | null>(null);
  const [selectedPlaylistId, setSelectedPlaylistId] = useState<string | null>(null);
  const [selectedPlaylistName, setSelectedPlaylistName] = useState<string | null>(null);
  const [selectedDistance, setSelectedDistance] = useState<string | null>(defaultDistance);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<string | null>(defaultTimePeriod);
  const [selectedVibe, setSelectedVibe] = useState<string | null>(null);

  const [userLat, setUserLat] = useState<string | null>(null);
  const [userLon, setUserLon] = useState<string | null>(null);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [locationMethod, setLocationMethod] = useState<string | null>(null);

  const [email, setEmail] = useState<string | null>(null);

  const searchParams = new URLSearchParams(window.location.search);
  const showkeyFromUrl = searchParams.get('showkey') || '';

  const [showkey, setShowkey] = useState<string>(showkeyFromUrl);

  useEffect(() => {
    if (!showkeyFromUrl) {
      setShowkey(''); // Reset the showkey if not in the URL
    }
  }, [showkeyFromUrl]);

  // Load stored location from localStorage on mount
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const { lat, lon, method } = await getLocation(); // Ensure getLocation returns data
        setUserLat(lat);
        setUserLon(lon);
      } catch (error) {
        console.error("Error fetching location:", error);
        setLocationError("Failed to retrieve location.");
      }
    };

    fetchLocation(); // Run function to update state
  }, []);

  // Fetch email from localStorage
  useEffect(() => {
    const savedEmail = localStorage.getItem('email');
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);



  const { data: shows, error, isLoading } = useQuery<Show[], Error>({
    queryKey: [
      'shows', 
      showkey, 
      selectedGenreId,
      selectedDistance, 
      selectedTimePeriod, 
      userLat, 
      userLon,
      selectedPlaylistId,
      email,
    ],
    queryFn: () => fetchShows(
      showkey, 
      null,
      null,
      selectedGenreId,
      selectedDistance, 
      selectedTimePeriod, 
      userLat, 
      userLon,
      null, // show_id
      null, // venue_id
      null, // artist_id
      selectedPlaylistId, // Pass playlist_id to fetchShows
      null,
      null,
      email // Pass email to fetchShows
    ),
    enabled: !!userLat && !!userLon,  // Only fetch shows when location is available
  });

  if (locationError) return <div>Error: {locationError}</div>;

  if (isLoading || !userLat || !userLon) return (
    <>
      <MainHeader />
      <div className="flex flex-col items-center justify-center space-y-4">
        <img 
          src="logo-animate.gif" 
          alt="Animated Logo" 
          className="max-w-[20rem] md:max-w-[25rem] lg:max-w-[30rem] object-contain" 
        />
      </div>
      <Footer />
    </>
  );

  if (error) return <div>Error: {error.message}</div>;

  return (
    <main className="flex overflow-visible flex-col justify-end mx-auto w-full bg-neutral-200">
      <MainHeader />
      {
        showkey ? (
          <header className="flex overflow-visible flex-col px-4 pt-8 pb-4 w-full font-medium leading-6 text-center bg-zinc-950">
            <SearchBar />
          </header>
        ) : (
          <Search
            selectedGenre={selectedGenre}
            setSelectedGenre={setSelectedGenre}
            selectedGenreId={selectedGenreId}
            setSelectedGenreId={setSelectedGenreId}
            selectedDistance={selectedDistance}
            setSelectedDistance={setSelectedDistance}
            selectedTimePeriod={selectedTimePeriod}
            setSelectedTimePeriod={setSelectedTimePeriod}
            userLat={userLat}           
            userLon={userLon}           
            setUserLat={setUserLat}     
            setUserLon={setUserLon}     
            selectedPlaylistId={selectedPlaylistId}       
            setSelectedPlaylistId={setSelectedPlaylistId} 
            selectedPlaylistName={selectedPlaylistName}   
            setSelectedPlaylistName={setSelectedPlaylistName}
            selectedVibe={selectedVibe}
            setSelectedVibe={setSelectedVibe}
            email={email}  // Pass email to Search component
            setEmail={setEmail}              
          />
        )
      }
      <div className="flex flex-col self-center pt-8 w-full max-w-[750px]">
        {shows && shows.length > 0 ? (
          shows.map((show) => {
            const venues: Venue[] = Array.isArray(show.venues) ? show.venues : [];
            const genres: Genre[] = Array.isArray(show.genres) ? show.genres : [];
            const artists: Artist[] = Array.isArray(show.artists) ? show.artists : [];

            return show.festival && !showkey ? (
              <ShowFestivalCard
                show={show}
                venue={venues}
                genres={genres}
                artists={artists}
                playlist={selectedPlaylistId}
                email={email}  // Pass email to ShowFestivalCard
              />
            ) : (
              <ShowCard
                show={show}
                venue={venues}
                genres={genres}
                artists={artists}
                playlist={selectedPlaylistId}
                email={email}  // Pass email to ShowCard
              />
            );
          })
        ) : (
          <NoShows 
            userLat={userLat}
            userLon={userLon}
            setUserLat={setUserLat} // Pass the setter function
            setUserLon={setUserLon} // Pass the setter function
          />  
        )}
      </div>
      <Footer />
    </main>
  );
}

export default App;