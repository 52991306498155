interface DateFieldProps {
  label: string;
  value: string; // Accepts a date value as a string (formatted as YYYY-MM-DD)
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; // Handles the date change
  required?: boolean;
}

const DateField: React.FC<DateFieldProps> = ({ label, value, onChange, required = false }) => {
  const id = label.toLowerCase().replace(/\s+/g, "-");

  return (
    <div className="flex flex-col flex-1 shrink basis-0">
      <label
        htmlFor={id}
        className="self-start text-xs font-semibold text-neutral-400"
      >
        {label}
        {required && "*"}
      </label>
      <input
        type="date"
        id={id}
        value={value} // Bind the value
        onChange={onChange} // Handle the change event
        className="flex gap-2 mt-2 w-full bg-white rounded-md border border-solid border-zinc-700 min-h-[40px]"
        required={required}
      />
    </div>
  );
};

export default DateField;

