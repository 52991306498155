import React, { useEffect, useState } from "react";
import VenueAdminHeader from "./VenueAdminHeader";
import VenueAdminNav from "./VenueAdminNav";
import VenueAdminProfile from "./VenueAdminProfile";
import VenueAdminShows from "./VenueAdminShows";
import VenueAdminReports from "./VenueAdminReports";
import Footer from "./Footer";
import { fetchVenuesByUserId } from "../lib/data";
import { Venue } from "../lib/definitions";
import * as jwt_decode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
};

const VenueAdmin: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('Shows');
  const [venues, setVenues] = useState<Venue[]>([]);
  const [selectedVenue, setSelectedVenue] = useState<number | null>(null); // Use number for venue_id
  const [loading, setLoading] = useState<boolean>(true); // Track loading state
  const [error, setError] = useState<string | null>(null); // Track errors

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  let user_id: number = 0;

  // Redirect to login if token is missing
  useEffect(() => {
    if (!token) {
      navigate("/login?message=Invalid Login"); // Redirect to login if token is missing
    }
  }, [token, navigate]);

  if (token) {
    // Decode the token to get user_id
    const decoded: any = parseJwt(token);
    user_id = decoded.user_id;
  } 

  // Fetch the list of venues based on the user ID when the component mounts
  useEffect(() => {
    const fetchVenues = async () => {
      setLoading(true);
      try {
        const response = await fetchVenuesByUserId(user_id);
        setVenues(response);
  
        // Automatically select the first venue if available
        if (response.length > 0) {
          setSelectedVenue(response[0].venue_id ?? null); // Use venue_id, fallback to null if undefined
        } else {
          setSelectedVenue(null); // Ensure it's cleared if no venues
        }
      } catch (error) {
        console.error("Failed to fetch venues", error);
        setError("Failed to fetch venues. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    if (user_id) {
      fetchVenues();
    }
  }, [user_id]);

  // Handle loading state
  if (loading) {
    return <p>Loading venues...</p>;
  }

  // Handle error state
  if (error) {
    return <p>{error}</p>;
  }

  // Handle no venues case
  if (venues.length === 0) {
    return <p>No venues available for this user.</p>;
  }

  return (
    <main className="flex overflow-hidden flex-col pb-8 mx-auto w-full bg-white">
      <VenueAdminHeader
        venues={venues}
        selectedVenue={selectedVenue ?? 0} // Ensure it's a number or 0
        setSelectedVenue={(venueId: number) => setSelectedVenue(venueId ?? null)} // Ensure the fallback to null
        user_id={user_id}
      />
      <VenueAdminNav activeTab={activeTab} setActiveTab={setActiveTab} />
      <section className="flex flex-col self-center px-4 pb-4 mt-6 w-full max-w-[430px]">
        {activeTab === 'Shows' ? (
          <VenueAdminShows selectedVenue={selectedVenue ?? 0} user_id={user_id} />
        ) : activeTab === 'Reports' ? (
          <VenueAdminReports selectedVenue={selectedVenue ?? 0} />
        ) : (
          <VenueAdminProfile selectedVenue={selectedVenue ?? 0} user_id={user_id} />
        )}
      </section>
      <Footer />
    </main>
  );
};

export default VenueAdmin;