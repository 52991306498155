import React, { useState } from "react";
import InputField from "./ui/InputField";
import DateField from "./ui/DateField";
import TimeField from "./ui/TimeField";
import DescriptionField from "./ui/DescriptionField";
import { addShow, uploadImage } from "../lib/data";
import Notification from "./ui/Notification";
import ArtistSelection from "./ArtistSelection";
import ArtistCreateModal from "./ArtistCreateModal";
import ArtistItem from "./ArtistItem";
import { format } from 'date-fns';

interface ShowAdminAddProps {
  user_id: number;
  venue_id: number;
  onClose: () => void;
}

const ShowAdminAdd: React.FC<ShowAdminAddProps> = ({ user_id, venue_id, onClose }) => {
  const [isArtistSelectionOpen, setIsArtistSelectionOpen] = useState(false);
  const [isArtistCreateOpen, setIsArtistCreateOpen] = useState(false);
  const [artists, setArtists] = useState<any[]>([]);

  const [showName, setShowName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timeStart, setTime] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(""); 
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [ticketLink, setTicketLink] = useState("");
  const [flightLink, setFlightLink] = useState("");
  const [officialLink, setOfficialLink] = useState("");
  const [coverPhotoUrl, setCoverPhotoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isCoverUploading, setIsCoverUploading] = useState(false);
  const [isThumbUploading, setIsThumbUploading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // State to manage submission loading
  const [notification, setNotificationState] = useState<{ message: string, type: 'success' | 'error' } | null>(null);

  const defaultImageKey = new Date().toISOString().replace(/[-:.]/g, '');

  const handleAddArtist = (newArtist: any) => {
    setArtists(prevArtists => [...prevArtists, newArtist]);
    setIsArtistCreateOpen(false);
  };

  const handleSelectArtist = (artist: any) => {
    setArtists(prevArtists => [...prevArtists, artist]);
    setIsArtistSelectionOpen(false);
  };

  // Function to ensure the time is in "HH:mm" format
  const formatTime = (datetime: string): string => {
    // Extract only the time part from the datetime string (assumes the format is "YYYY-MM-DDTHH:mm:ss")
    const timePart = datetime.split('T')[1]; // Extract the time part after the "T"
  
    if (timePart) {
      const [hours, minutes] = timePart.split(':'); // Split into hours and minutes
      return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`; // Ensure HH:mm format
    }
  
    return ''; // Return an empty string if the format is invalid
  };

  const handleImageUpload = async (file: File, uploadPreset: string, imageKey: string) => {
    try {
      if (uploadPreset === 'cover_photo') {
        setIsCoverUploading(true);
      } else {
        setIsThumbUploading(true);
      }
      const imageUrl = await uploadImage(file, imageKey, uploadPreset);
      if (uploadPreset === 'cover_photo') {
        setCoverPhotoUrl(imageUrl);
      } else {
        setThumbnailUrl(imageUrl);
      }
      triggerNotification("Image uploaded successfully!", 'success');
    } catch (error) {
      console.error("Failed to upload image", error);
      triggerNotification("Failed to upload image.", 'error');
    } finally {
      setIsCoverUploading(false);
      setIsThumbUploading(false);
    }
  };

  const triggerNotification = (message: string, type: 'success' | 'error') => {
    setNotificationState({ message, type });
    setTimeout(() => {
      setNotificationState(null);
    }, 3000);
  };

  const handleRemoveArtist = (index: number) => {
    setArtists((prevArtists) => prevArtists.filter((_, i) => i !== index));
  };

  const handleAddShow = async () => {
    setIsSubmitting(true); // Disable the button during submission

    try {
      // Convert the time to the correct 24-hour format
      const formattedTime = timeStart 
        ? format(new Date(`1970-01-01T${timeStart}`), 'HH:mm:ss') 
        : undefined;

      const formattedArtists = artists.map((artist) => {
        const formattedGenres = artist.genres?.map((genre) => ({
          genre_id: genre.genre_id,
          description: genre.description,
        })) || [];

        return {
          ...(artist.artist_id && { artist_id: artist.artist_id }),
          name: artist.name,
          genres: formattedGenres,
          contact_info: artist.contact_info || null,  // Include artist contact_info
        };
      });
  
      const newShow = {
        name: showName,
        date_start: startDate,
        date_end: endDate,
        time_start: formattedTime, // Ensure it's in HH:mm:ss format
        description: description,
        price: price,
        fb_link: facebook,
        ig_link: instagram,
        ticket_link: ticketLink,
        flight_link: flightLink,
        official_link: officialLink,
        image_full: coverPhotoUrl,
        image_thumb: thumbnailUrl,
        created_by: user_id,
        artists: formattedArtists,
        venue_id: venue_id,
      };
  
      await addShow(newShow);
      triggerNotification("Show added successfully!", "success");

      setTimeout(() => {
        setIsSubmitting(false); // Re-enable the button after success
        onClose(); // Close the modal
      }, 1500); // Add a slight delay before closing the modal
    } catch (error) {
      triggerNotification("Failed to add show.", "error");
      setIsSubmitting(false); // Re-enable the button on error
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <main className="flex overflow-hidden flex-col px-4 py-6 mx-auto w-full bg-white rounded-2xl max-w-[480px]">
      <section className="flex flex-col pb-6 w-full">
        <h1 className="text-3xl font-bold">Add Show</h1>
  
        <InputField label="Show Name*" value={showName} onChange={(e) => setShowName(e.target.value)} />
  
        <div className="flex gap-4 items-start mt-2 w-full">
          <DateField
            label="Start Date*"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
          <DateField
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
  
        {/* Artists List */}
        {Array.isArray(artists) && artists.length > 0 ? (
          artists.map((artist, index) => (
            <ArtistItem
              key={index}
              name={artist.name}
              genre={Array.isArray(artist.genres) ? artist.genres.map((genre) => genre.description).join(', ') : ''}
              image={artist.image_thumb || "/default_thumb.webp"}
              onRemove={() => handleRemoveArtist(index)}
              onClick={() => console.log(`Selected artist: ${artist.name}`)}
            />
          ))
        ) : (
          <p className="mt-4">No artists assigned</p>
        )}
  
        {/* Button to Add Artist */}
        <button
          className="mt-4 bg-black text-white border border-black p-2 rounded"
          onClick={() => {
            setIsArtistSelectionOpen(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          Add Artist to Show
        </button>
  
        {/* Artist Selection Modal */}
        {isArtistSelectionOpen && (
          <ArtistSelection
            onAddArtist={handleSelectArtist}
            onCreateArtist={() => {
              setIsArtistSelectionOpen(false);
              setIsArtistCreateOpen(true);
            }}
            onClose={() => setIsArtistSelectionOpen(false)}
          />
        )}
  
        {/* Artist Create Modal */}
        {isArtistCreateOpen && (
          <ArtistCreateModal
            onAddArtist={handleAddArtist}
            onClose={() => setIsArtistCreateOpen(false)}
          />
        )}
  
        <div className="flex gap-4 items-start mt-2 w-full">
          <TimeField
            label="Select Time"
            value={timeStart || ''} // Only pass HH:mm to TimeField
            onChange={(newTime: string | null) => {
              if (newTime) {
                // Add seconds if needed
                const updatedTimeWithSeconds = `${newTime}:00`;

                // Compare the new formatted value with the existing one to prevent unnecessary updates
                if (updatedTimeWithSeconds !== timeStart) {
                  setTime(updatedTimeWithSeconds);
                }
              }
            }}
          />

          <InputField label="Price (ie $10, $10-20, Free)" value={price} onChange={(e) => setPrice(e.target.value)} />
        </div>
  
        <DescriptionField label="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
      </section>
  
      <section className="flex flex-col w-full">
      <div className="flex relative flex-col w-full">
        <div className="flex z-0 items-center w-full text-zinc-950">
          <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Cover Photo</h3>
        </div>
        <p className="z-0 mt-2 text-xs font-semibold text-neutral-400">
          We recommend an image of at least 1920x1080 for your cover photo
        </p>
        <div className="flex z-0 mt-2 w-full rounded-md border-2 border-solid bg-zinc-300 bg-opacity-0 border-neutral-200 min-h-[212px]">
        <img
          loading="lazy"
          src={coverPhotoUrl || "upload_default_cover.png"}
          className=""
          alt="Event Photo"
        />
        </div>
        {/* Hidden input for selecting the cover photo */}
        <input
          type="file"
          accept="image/*"
          className="hidden"
          id="coverPhotoInput"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target.files[0];
              const venueId = "yourVenueIdHere"; // You need to dynamically pass your venue ID
              handleImageUpload(file, 'cover_photo', defaultImageKey); // Specify 'cover' as the image type
            }
          }}
        />
        
        {/* Button to trigger file selection for cover photo */}
        <button
          className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
          onClick={() => document.getElementById('coverPhotoInput')?.click()}
        >
          Select Cover Photo
        </button>

        {isCoverUploading && <p className="mt-2 text-sm text-indigo-500">Uploading cover photo...</p>}
      </div>

      <div className="flex flex-col mt-2 w-full">
        <div className="flex items-center pb-2 w-full text-zinc-950">
          <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Thumbnail</h3>
        </div>
        <div className="flex gap-2 items-center mt-2 w-full text-xs font-semibold text-neutral-400">
          <img
            loading="lazy"
            src={thumbnailUrl || "upload_default_thumb.png"}
            className="object-contain shrink-0 self-stretch my-auto w-20 aspect-square rounded-[50%]"
            alt="Event Thumbnail"
          />
          <p className="self-stretch my-auto w-[242px]">We recommend an image of at least 512x512 for your venue thumbnail</p>
        </div>

        {/* Hidden input for selecting the thumbnail */}
        <input
          type="file"
          accept="image/*"
          className="hidden"
          id="thumbnailInput"
          onChange={(e) => {
            if (e.target.files && e.target.files[0]) {
              const file = e.target.files[0];
              const venueId = "yourVenueIdHere"; // You need to dynamically pass your venue ID
              handleImageUpload(file, 'thumbnail_photo', defaultImageKey); 
            }
          }}
        />

        {/* Button to trigger file selection for thumbnail */}
        <button
          className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
          onClick={() => document.getElementById('thumbnailInput')?.click()}
        >
          Select Thumbnail
        </button>

        {isThumbUploading && <p className="mt-2 text-sm text-indigo-500">Uploading thumbnail...</p>}
      </div>
      </section>
  
      <section className="mt-2 w-full">
        <h2 className="text-lg font-bold">Links</h2>
        <InputField label="Facebook" value={facebook} onChange={(e) => setFacebook(e.target.value)} />
        <InputField label="Instagram" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
        <InputField label="Ticket Link" value={ticketLink} onChange={(e) => setTicketLink(e.target.value)} />
        <InputField label="Flight Link" value={flightLink} onChange={(e) => setFlightLink(e.target.value)} />
        <InputField label="Official Link" value={officialLink} onChange={(e) => setOfficialLink(e.target.value)} />
      </section>
  
      <div className="flex gap-10 justify-between items-center mt-6 w-full">
        <button className="px-6 py-2 bg-gray-300 rounded-md" onClick={handleCancel}>
          Cancel
        </button>
        <button
          className={`px-6 py-2 rounded-md ${
            artists.length > 0 && showName.trim() !== '' && startDate.trim() !== '' && !isSubmitting
              ? 'bg-black text-white'
              : 'bg-gray-400 text-gray-200 cursor-not-allowed'
          }`}
          onClick={handleAddShow}
          disabled={artists.length === 0 || showName.trim() === '' || startDate.trim() === '' || isSubmitting}
        >
          {isSubmitting ? 'Saving...' : 'Add Show'}
        </button>
      </div>
        
      {notification && (
        <Notification message={notification.message} type={notification.type} onClose={() => setNotificationState(null)} />
      )}
    </main>
  );
}

export default ShowAdminAdd;
