import * as React from "react";
import { Artist, Genre } from "../lib/definitions";
import { logEvent } from "../lib/data";  // Import the logEvent function

type ArtistInfoProps = {
  artist: Artist; 
};

const ArtistInfo: React.FC<ArtistInfoProps> = ({ artist }) => {

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this artist!',
          text: `Have a look at ${artist.name}!`,
          url: window.location.href,
        });
        // Log the share event
        logEvent(null, 'Share', artist.artist_id ?? 0, 'Artist');
      } catch (error) {
        console.error('Sharing failed', error);
      }
    } else {
      console.warn('Web Share API is not supported in this browser.');
    }
  };

  const handleLinkClick = async (url: string, linkType: string) => {
    logEvent(null, 'Click', artist.artist_id ?? 0, 'Artist', linkType); // Log the click event with additional_info
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const isWebShareSupported = typeof navigator.share === 'function';

  const genres: Genre[] = typeof artist.genres === 'string'
    ? JSON.parse(artist.genres)
    : [];

  return (
    <section className="flex flex-col w-full bg-white">
      <div className="flex flex-col px-4 py-6 w-full bg-zinc-950">
        <div className="flex flex-col w-full">
          <div className="flex gap-2 items-center w-full text-3xl font-bold tracking-tighter leading-none text-white">
            <h1 className="flex-1 shrink my-auto">{artist.name}</h1>
          </div>
          <div className="mt-1 text-base font-semibold text-zinc-400">
            {genres?.length ? (
              genres.map((genre, index) => (
                <span key={index} className="mt-1 text-base mr-2 font-semibold text-zinc-400">
                  {genre.description}
                </span>
              ))
            ) : (
              <span>No genres available</span>
            )}
          </div>
        </div>
        {artist.sub_level !== 'Opener' && (
          <>
            {artist.image_full && (
              <img
                loading="lazy"
                src={artist.image_full}
                className="w-full aspect-[1.46] rounded-2xl object-contain"
                alt={`Image of ${artist.name}`}
              />
            )}
            <div className="text-base font-medium leading-6 text-neutral-400">
              <p>{artist.bio}</p>
            </div>
            <div className="flex gap-4 items-start mt-6 w-full text-sm font-medium leading-6 text-center text-white whitespace-nowrap">
              {artist.fb_link && (
                <a onClick={() => handleLinkClick(artist.fb_link || '', 'Facebook')} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                  <img src="icon-facebook.svg" alt="fb icon" className="w-6 aspect-square" />
                </a>
              )}
              {artist.ig_link && (
                <a onClick={() => handleLinkClick(artist.ig_link || '', 'Instagram')} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                  <img src="icon-instagram.svg" alt="ig icon" className="w-6 aspect-square" />
                </a>
              )}
              {artist.twitter_link && (
                <a onClick={() => handleLinkClick(artist.twitter_link || '', 'Twitter')} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                  <img src="icon-twitter.svg" alt="twitter icon" className="w-6 aspect-square" />
                </a>
              )}
              {artist.tiktok_link && (
                <a onClick={() => handleLinkClick(artist.tiktok_link || '', 'TikTok')} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                  <img src="icon-tiktok.svg" alt="tiktok icon" className="w-6 aspect-square" />
                </a>
              )}
              {artist.official_link && (
                <a onClick={() => handleLinkClick(artist.official_link || '', 'Official')} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                  <img src="icon-website.svg" alt="website icon" className="w-6 aspect-square" />
                </a>
              )}
              {artist.spotify_link && (
                <a onClick={() => handleLinkClick(artist.spotify_link || '', 'Spotify')} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                  <img src="icon-spotify.svg" alt="spotify icon" className="w-6 aspect-square" />
                </a>
              )}
              {artist.merch_link && (
                <a onClick={() => handleLinkClick(artist.merch_link || '', 'Merch')} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                  <img src="icon-merch.svg" alt="merch icon" className="w-6 aspect-square" />
                </a>
              )}

            </div>
          </>
        )}
        <div>
        {isWebShareSupported ? (
              <button onClick={handleShare} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
                <img src="icon-share.svg" alt="share icon" className="w-6 aspect-square" />
              </button>
            ) : (
              <></>
            )}
        </div>
      </div>
    </section>
  );
};

export default ArtistInfo;