import React from "react";

const Footer: React.FC = () => {

  return (
    <footer className="mt-4 flex overflow-hidden flex-col justify-center px-60 py-7 text-xs font-semibold text-white bg-zinc-950 max-md:px-5">
      <div className="flex flex-col w-full max-md:max-w-full">
        <img
          loading="lazy"
          src="logo.png"
          alt="myVibeLive Logo"
          className="object-contain self-center max-w-full aspect-[0.79] w-[136px]"
        />
        <div className="flex justify-center">
          <a href='https://www.facebook.com/profile.php?id=61565715673369' target="_blank" rel="noopener noreferrer">
            <img src="icon-facebook.svg" alt="fb icon" />
          </a>
          <a href='https://www.instagram.com/myvibelive/' target="_blank" rel="noopener noreferrer">
            <img src="icon-instagram.svg" alt="ig icon" className="ml-8 mr-8" />
          </a>
          <a href='https://www.tiktok.com/@myvibe.live' target="_blank" rel="noopener noreferrer">
            <img src="icon-tiktok.svg" alt="tiktok icon" />
          </a>          
        </div>
        <div className="flex flex-wrap justify-center gap-4 items-center mt-4 w-full max-md:mt-4 max-md:max-w-full">
          <a href="/login" className="text-center">
            Artist / Venue Login
          </a>
          <a href="/contactus" className="text-center">
            Contact Us
          </a>
          <a href="/aboutus" className="text-center">
            About Us
          </a>          
          <a href="/termsofservice" target="_blank" rel="noopener noreferrer" className="text-center">
            Terms & Service
          </a>
          <a href="/privacypolicy" target="_blank" rel="noopener noreferrer" className="text-center">
            Privacy Policy
          </a>
        </div>
        <div className="flex justify-center items-center mt-4 text-neutral-400 max-md:max-w-full">
          Copyright © 2024 myVibeLive
        </div>     
      </div>
    </footer>
  );
};

export default Footer;
