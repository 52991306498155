import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import InputField from "./ui/InputField";
import { fetchArtistById, updateArtist, uploadImage, fetchGenres } from "../lib/data"; // Updated functions
import { Artist } from "../lib/definitions"; // Use Artist instead of Venue
import Notification from "./ui/Notification"
import axios from 'axios';

interface GenreOption {
  genre_id: number;
  description: string;
}

interface ArtistAdminProfileProps {
  selectedArtist: number;
  user_id: number; 
}

const ArtistAdminProfile: React.FC<ArtistAdminProfileProps> = ({ selectedArtist, user_id }) => {
  const [artist, setArtist] = useState<Artist | null>(null); // Use Artist
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [initialArtist, setInitialArtist] = useState<Artist | null>(null); // Use Artist

  const [active, setActive] = useState(1);
  const [artistName, setArtistName] = useState("");
  const [bio, setBio] = useState("");
  const [selectedTab, setSelectedTab] = useState<"write" | "preview">("write");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYouTube] = useState("");
  const [tiktok, setTikTok] = useState("");
  const [twitter, setTwitter] = useState("");
  const [spotify, setSpotify] = useState("");
  const [merch, setMerch] = useState("");
  const [officialLink, setOfficialLink] = useState("");
  const [coverPhotoUrl, setCoverPhotoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [notification, setNotificationState] = useState<{ message: string, type: 'success' | 'error' } | null>(null);
  const [genres, setGenres] = useState<GenreOption[]>([]);
  const [selectedGenres, setSelectedGenres] = useState<GenreOption[]>([]);
  
  const triggerNotification = (message: string, type: 'success' | 'error') => {
    setNotificationState({ message, type });
    setTimeout(() => {
      setNotificationState(null);
    }, 3000);
  };

  const handleGenreSelection = (genres: any[]): GenreOption[] => {
    return genres
      .filter((genre) => genre.genre_id !== undefined) // Filter out undefined genre_ids
      .map((genre) => ({
        genre_id: genre.genre_id!, // Use the non-null assertion operator
        description: genre.description || "", // Ensure description is always a string
      }));
  };

  useEffect(() => {
    const fetchArtist = async () => {
      if (selectedArtist) {
        setIsLoading(true);
        try {
          const artistData = await fetchArtistById(selectedArtist);
          setArtist(artistData);
          setInitialArtist(artistData);
          setActive(artistData.active || 1);
          setArtistName(artistData.name || "");
          setBio(artistData.bio || "");
          setFacebook(artistData.fb_link || "");
          setInstagram(artistData.ig_link || "");
          setYouTube(artistData.yt_link || "");
          setTikTok(artistData.tiktok_link || "");
          setTwitter(artistData.twitter_link || "");
          setSpotify(artistData.spotify_link || "");
          setMerch(artistData.merch_link || "");
          setOfficialLink(artistData.official_link || "");
          setCoverPhotoUrl(artistData.image_full || "");
          setThumbnailUrl(artistData.image_thumb || "");

        // Fetch genres for selection options
        const genreData = await fetchGenres();
        const availableGenres = handleGenreSelection(genreData);
        setGenres(availableGenres);

        // Set selected genres based on artist data
        if (artistData.genres) {
          const selectedGenresList = handleGenreSelection(artistData.genres);
          setSelectedGenres(selectedGenresList);
        }


        } catch (error) {
          console.error("Failed to fetch artist data", error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchArtist();
  }, [selectedArtist]);

  const defaultImageKey = artist?.artist_key || new Date().toISOString().replace(/[-:.]/g, '');

  const handleImageUpload = async (file: File, uploadPreset: string, imageKey: string) => {
    try {
      setIsUploading(true);
      const imageUrl = await uploadImage(file, imageKey, uploadPreset);

      if (uploadPreset === 'cover_photo') {
        setCoverPhotoUrl(imageUrl);
      } else if (uploadPreset === 'thumbnail_photo') {
        setThumbnailUrl(imageUrl);
      }

      triggerNotification("Image uploaded successfully!", 'success');
    } catch (error) {
      console.error("Failed to upload image", error);
      triggerNotification("Failed to upload image.", 'error');
    } finally {
      setIsUploading(false);
    }
  };

  const handleGenreClick = (genre: GenreOption) => {
    setSelectedGenres((prevSelected) =>
      prevSelected.some((g) => g.genre_id === genre.genre_id)
        ? prevSelected.filter((g) => g.genre_id !== genre.genre_id)
        : [...prevSelected, genre]
    );
  };

  const handleSaveChanges = async () => {
    if (selectedGenres.length === 0) {
      triggerNotification("Please select at least one genre.", 'error');
      return;
    }

    setIsSaving(true);
    try {
      const updatedArtist = {
        artist_id: artist?.artist_id || selectedArtist,
        active: 1,
        name: artistName,
        bio: bio,
        fb_link: facebook,
        ig_link: instagram,
        yt_link: youtube,
        tiktok_link: tiktok,
        twitter_link: twitter,
        spotify_link: spotify,
        merch_link: merch,
        official_link: officialLink,
        image_full: coverPhotoUrl,
        image_thumb: thumbnailUrl,
        changed_by: user_id,
        genres: selectedGenres,
      };

      await updateArtist(updatedArtist); // Update artist function
      triggerNotification("Artist profile updated successfully!", 'success');
    } catch (error) {
      console.error("Failed to update artist", error);
      triggerNotification("Failed to update artist profile.", 'error');
    } finally {
      setIsSaving(false);  // Re-enable button after save process is complete
    }
  };

  const handleCancel = () => {
    if (initialArtist) {
      setArtistName(initialArtist.name || "");
      setBio(initialArtist.bio || "");
      setFacebook(initialArtist.fb_link || "");
      setInstagram(initialArtist.ig_link || "");
      setYouTube(initialArtist.yt_link || "");
      setTikTok(initialArtist.tiktok_link || "");
      setTwitter(initialArtist.twitter_link || "");
      setSpotify(initialArtist.spotify_link || "");
      setMerch(initialArtist.merch_link || "");
      setOfficialLink(initialArtist.official_link || "");
      setCoverPhotoUrl(initialArtist.image_full || "");
      setThumbnailUrl(initialArtist.image_thumb || "");
    }
  };

  if (isLoading) {
    return <p>Loading artist data...</p>;
  }

  if (!artist) {
    return <p>Select an artist to view their details</p>;
  }

  return (
    <>
      <section className="flex flex-col w-full">
        <h2 className="flex-1 shrink gap-2 pt-2.5 w-full text-lg font-bold tracking-tight min-h-[56px] text-zinc-950">
          About You
        </h2>
        <InputField
          label="Artist Name*"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          readOnly
        />
      </section>

      <section className="mt-6 w-full">
        <label htmlFor="artistBio" className="text-xs font-semibold text-zinc-950">
          Genres*
        </label>
        <div className="flex flex-wrap gap-2 mt-2">
          {genres.map((genre) => (
            <span
              key={genre.genre_id}
              className={`cursor-pointer px-3 py-1 border rounded-full ${
                selectedGenres.some((g) => g.genre_id === genre.genre_id)
                  ? "bg-blue-600 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
              onClick={() => handleGenreClick(genre)}
            >
              {genre.description}
            </span>
          ))}
        </div>
      </section>

      <section className="flex flex-col mt-6 w-full">
      <>
      <section className="flex flex-col w-full">
        <h2 className="text-lg font-bold tracking-tight text-zinc-950">About You</h2>
        <InputField
          label="Artist Name*"
          value={artistName}
          onChange={(e) => setArtistName(e.target.value)}
          readOnly
        />
      </section>

      {/* Artist Bio with Markdown Editor */}
      <section className="mt-6 w-full">
        <div>
          <h2>Artist Bio</h2>
          <ReactQuill value={bio} onChange={setBio} />
        </div>
      </section>
    </>
  
        <div className="flex relative flex-col w-full">
          <div className="flex z-0 items-center mt-4 w-full text-zinc-950">
            <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Cover Photo</h3>
          </div>
          <p className="z-0 mt-2 text-xs font-semibold text-neutral-400">
            We recommend an image of at least 1920x1080 for your cover photo
          </p>
          <div className="flex z-0 mt-2 w-full rounded-md border-2 border-solid bg-zinc-300 bg-opacity-0 border-neutral-200 min-h-[212px]">
            <img
              loading="lazy"
              src={coverPhotoUrl || "upload_default_cover.png"}
              className=""
              alt="Artist Photo"
            />
          </div>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="coverPhotoInput"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                handleImageUpload(file, 'cover_photo', defaultImageKey);
              }
            }}
          />
          <button
            className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
            onClick={() => document.getElementById('coverPhotoInput')?.click()}
          >
            Select Cover Photo
          </button>
          {isUploading && <p className="mt-2 text-sm text-indigo-500">Uploading cover photo...</p>}
        </div>
  
        <div className="flex flex-col mt-2 w-full">
          <div className="flex items-center pb-2 w-full text-zinc-950">
            <h3 className="flex-1 shrink self-stretch my-auto text-lg font-bold tracking-tight basis-4">Thumbnail</h3>
          </div>
          <div className="flex gap-2 items-center mt-2 w-full text-xs font-semibold text-neutral-400">
            <img
              loading="lazy"
              src={thumbnailUrl || "upload_default_thumb.png"}
              className="object-contain shrink-0 self-stretch my-auto w-20 aspect-square rounded-[50%]"
              alt="Artist Thumbnail"
            />
            <p className="self-stretch my-auto w-[242px]">We recommend an image of at least 512x512 for your artist thumbnail</p>
          </div>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            id="thumbnailInput"
            onChange={(e) => {
              if (e.target.files && e.target.files[0]) {
                const file = e.target.files[0];
                handleImageUpload(file, 'thumbnail_photo', defaultImageKey);
              }
            }}
          />
          <button
            className="gap-2 self-stretch p-2 mt-4 my-auto text-sm font-medium leading-6 text-center rounded-md border border-solid opacity-50 border-zinc-700"
            onClick={() => document.getElementById('thumbnailInput')?.click()}
          >
            Select Thumbnail
          </button>
          {isUploading && <p className="mt-2 text-sm text-indigo-500">Uploading thumbnail...</p>}
        </div>
      </section>
  
      <section className="mt-2 w-full">
        <h2 className="flex-1 shrink self-stretch pb-4 mt-2 w-full text-lg font-bold tracking-tight text-zinc-950">Social Links</h2>
        <InputField label='Facebook' value={facebook} onChange={(e) => setFacebook(e.target.value)} />
        <InputField label='Instagram' value={instagram} onChange={(e) => setInstagram(e.target.value)} />
        <InputField label='TikTok' value={tiktok} onChange={(e) => setTikTok(e.target.value)} />
        <InputField label='YouTube' value={youtube} onChange={(e) => setYouTube(e.target.value)} />
        <InputField label='Twitter' value={twitter} onChange={(e) => setTwitter(e.target.value)} />
        <InputField label='Spotify' value={spotify} onChange={(e) => setSpotify(e.target.value)} />
      </section>
  
      <section className="flex flex-col mt-6 w-full text-xs font-semibold">
        <h2 className="flex-1 shrink gap-2 pt-2.5 w-full text-lg font-bold tracking-tight min-h-[56px] text-zinc-950">Additional Links</h2>
        <InputField label='Official' value={officialLink} onChange={(e) => setOfficialLink(e.target.value)} />
        <InputField label='Merchandise' value={merch} onChange={(e) => setMerch(e.target.value)} />
      </section>
  
      <div className="flex gap-10 justify-between items-center mt-6 w-full text-sm font-medium leading-6 text-center">
        <button
          className="gap-2 self-stretch px-6 py-2 my-auto rounded-md border border-solid border-zinc-700 text-zinc-950"
          onClick={handleCancel}
        >
          Cancel Changes
        </button>
        <button
          onClick={handleSaveChanges}
          className={`gap-2 self-stretch px-6 py-2 my-auto text-white rounded-md border border-solid bg-zinc-950 border-zinc-700 ${
            isSaving ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          disabled={isSaving}  // Disable button while saving
        >
          {isSaving ? "Saving..." : "Save Changes"}
        </button>
      </div>
      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={() => setNotificationState(null)}
        />
      )}
    </>
  );
}

export default ArtistAdminProfile;