"use client";
import * as React from "react";
import { Show } from "../lib/definitions";
import { FaCheckCircle } from "react-icons/fa"; // ✅ Green checkmark for added shows

interface FestivalShowCardProps {
  show: Show;
  onClick: (showKey: string | null) => void; // ✅ Ensure onClick can accept null
  isSelected: boolean; // ✅ Tracks if the show is in the itinerary
}

const FestivalShowCard: React.FC<FestivalShowCardProps> = ({ show, onClick, isSelected }) => {
  // ✅ Get User's Timezone Offset
  const userTimeZoneOffsetMinutes = new Date().getTimezoneOffset();

  // ✅ Convert UTC Date to User's Local Time
  const formatDateTime = (dateTime: string | null) => {
    if (!dateTime) return "TBD";

    const utcDate = new Date(dateTime);
    if (isNaN(utcDate.getTime())) return "TBD"; // Handle invalid dates

    // ✅ Adjust for user's timezone offset
    const localDate = new Date(utcDate.getTime() + userTimeZoneOffsetMinutes * 60 * 1000);

    return localDate.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
  };

  return (
    <article className="flex flex-col p-4 bg-zinc-950 rounded-lg shadow-lg w-full mx-auto space-y-4">
      {/* ✅ Ensures card is left-aligned */}
      <div className="w-full text-left">
        {/* ✅ Artist Name & Genre */}
        <header className="flex justify-between items-center">
        <a
        href={`/search?showkey=${show?.show_key ?? ""}`}
        target="_blank"
        rel="noopener noreferrer"
        >   
            <h1 className="text-xl font-bold text-white">{show?.name ?? "Unknown Artist"}</h1>
          </a>
          <p className="text-sm text-zinc-400">
            {show.genres?.length ? show.genres.map((genre) => genre.description).join(", ") : "No Genre"}
          </p>
        </header>

        {/* ✅ Date & Venue */}
        <p className="mt-1 text-sm text-Type-On-Dark-Secondary">
        <time dateTime={show?.time_start ?? "00:00"}>
            {formatDateTime(show?.time_start ?? "00:00")}
        </time>{" "}
        @{" "}
        <a
            href={`/venue?venuekey=${show?.venues?.[0]?.venue_key ?? ""}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            {show?.venues?.[0]?.name ?? "Unknown Venue"}
        , {show?.venues?.[0]?.addr_city}, {show?.venues?.[0]?.addr_state}
        </a>
        </p>
      </div>

      {/* ✅ Left-Aligned Button */}
      <div className="mt-2 flex self-start">
        <button
          onClick={() => onClick(show.show_key !== undefined ? show.show_key : null)} // ✅ Fix: Ensure string or null
          className={`flex items-center justify-start gap-2 px-4 py-2 text-sm font-medium rounded-md transition-all border ${
            isSelected
              ? "border-green-500 text-green-400 bg-green-900 hover:bg-green-800"
              : "border-gray-500 text-white hover:bg-gray-800"
          }`}
        >
          {isSelected ? (
            <>
              <FaCheckCircle className="text-green-400" /> Added
            </>
          ) : (
            "Add To Itinerary"
          )}
        </button>
      </div>
    </article>
  );
};

export default FestivalShowCard;