import React from "react";
import SearchBar from "./SearchBar";
import FilterButtons from "./FilterButtons";

interface FilterButtonsProps {
  selectedGenre: string | null;
  setSelectedGenre: React.Dispatch<React.SetStateAction<string | null>>;
  selectedGenreId: number | null;
  setSelectedGenreId: React.Dispatch<React.SetStateAction<number | null>>;  
  selectedDistance: string | null;
  setSelectedDistance: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTimePeriod: string | null;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<string | null>>;
  userLat: string | null;
  userLon: string | null;
  setUserLat: React.Dispatch<React.SetStateAction<string | null>>;
  setUserLon: React.Dispatch<React.SetStateAction<string | null>>;
  selectedPlaylistId: string | null;
  setSelectedPlaylistId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedPlaylistName: string | null;  // Added to display playlist name
  setSelectedPlaylistName: React.Dispatch<React.SetStateAction<string | null>>;  // Setter for playlist name
  selectedVibe: string | null;
  setSelectedVibe: React.Dispatch<React.SetStateAction<string | null>>;
  email: string | null; // Added email prop
  setEmail: React.Dispatch<React.SetStateAction<string | null>>; // Added setEmail prop
}

const Search: React.FC<FilterButtonsProps> = ({
  selectedGenre,
  setSelectedGenre,
  selectedGenreId,
  setSelectedGenreId,
  selectedDistance,
  setSelectedDistance,
  selectedTimePeriod,
  setSelectedTimePeriod,
  userLat,
  userLon,
  setUserLat,
  setUserLon,
  selectedPlaylistId,
  setSelectedPlaylistId,
  selectedPlaylistName,  
  setSelectedPlaylistName,
  selectedVibe,
  setSelectedVibe,
  email,
  setEmail // Receive email and setEmail
}) => {
  return (
    <header className="flex overflow-visible flex-col px-4 pt-2 pb-4 w-full font-medium leading-6 text-center bg-zinc-950">
      <SearchBar />
      <FilterButtons 
        selectedGenre={selectedGenre}
        setSelectedGenre={setSelectedGenre}
        selectedGenreId={selectedGenreId}
        setSelectedGenreId={setSelectedGenreId}        
        selectedDistance={selectedDistance}
        setSelectedDistance={setSelectedDistance}
        selectedTimePeriod={selectedTimePeriod}
        setSelectedTimePeriod={setSelectedTimePeriod}
        userLat={userLat}
        userLon={userLon}
        setUserLat={setUserLat}
        setUserLon={setUserLon}
        selectedPlaylistId={selectedPlaylistId}
        setSelectedPlaylistId={setSelectedPlaylistId}
        selectedPlaylistName={selectedPlaylistName}  
        setSelectedPlaylistName={setSelectedPlaylistName} 
        selectedVibe={selectedVibe}
        setSelectedVibe={setSelectedVibe}
        email={email} // Pass down email prop
        setEmail={setEmail} // Pass down setEmail prop
      />
    </header>
  );
};

export default Search;