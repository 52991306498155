import React from "react";
import { Venue } from "../lib/definitions";
import { logEvent } from "../lib/data"; // Import your logEvent function

interface VenueInfoProps {
  venue: Venue;
}

const VenueInfo: React.FC<VenueInfoProps> = ({ venue }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check out this venue!',
          text: `Have a look at ${venue.name}!`,
          url: window.location.href,
        });
        // Log the share event
        logEvent(null, 'Share', venue.venue_id || 0, 'Venue');
      } catch (error) {
        console.error('Sharing failed', error);
      }
    } else {
      console.warn('Web Share API is not supported in this browser.');
    }
  };

  const handleLinkClick = async (url: string, linkType: string) => {
    logEvent(null, 'Click', venue.venue_id || 0, 'Venue', linkType);
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const isWebShareSupported = typeof navigator.share === 'function';

  return (
    <section className="w-full flex flex-col bg-white">
      <div className="flex flex-col px-4 py-6 bg-zinc-950">
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center gap-2">
            <h2 className="flex-1 text-3xl font-bold tracking-tighter text-white">
              {venue.name}
            </h2>
          </div>
          <p className="mt-1 text-base font-semibold text-zinc-400">
            {venue.addr_street},&nbsp;
            {venue.addr_city},&nbsp;  
            {venue.addr_state}
          </p>
        </div>
        {venue.image_full && (
          <img
            src={venue.image_full}
            alt={venue.name}
            className="w-full aspect-[1.46] rounded-2xl object-contain"
          />
        )}
        <p className="mt-6 text-base font-medium leading-6 text-neutral-400">
          {venue.description}
        </p>
        <div className="flex gap-4 items-start mt-6 w-full text-sm font-medium leading-6 text-center text-white whitespace-nowrap">
          {venue.fb_link && (
            <a 
              onClick={() => handleLinkClick(venue.fb_link || '', 'Facebook')} 
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-facebook.svg" alt="fb icon" className="w-6 aspect-square" />
            </a>
          )}
          {venue.ig_link && (
            <a 
              onClick={() => handleLinkClick(venue.ig_link || '', 'Instagram')} 
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-instagram.svg" alt="ig icon" className="w-6 aspect-square" />
            </a>
          )}
          {venue.twitter_link && (
            <a 
              onClick={() => handleLinkClick(venue.twitter_link || '', 'Twitter')} 
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-twitter.svg" alt="twitter icon" className="w-6 aspect-square" />
            </a>
          )}
          {venue.tiktok_link && (
            <a 
              onClick={() => handleLinkClick(venue.tiktok_link || '', 'TikTok')} 
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-tiktok.svg" alt="tiktok icon" className="w-6 aspect-square" />
            </a>
          )}
          {venue.yt_link && (
            <a 
              onClick={() => handleLinkClick(venue.yt_link || '', 'YouTube')} 
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-website.svg" alt="yt icon" className="w-6 aspect-square" />
            </a>
          )}          
          {venue.official_link && (
            <a 
              onClick={() => handleLinkClick(venue.official_link || '', 'Official')} 
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-website.svg" alt="website icon" className="w-6 aspect-square" />
            </a>
          )}
          {venue.merch_link && (
            <a 
              onClick={() => handleLinkClick(venue.merch_link || '', 'Merch')} 
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-merch.svg" alt="merch icon" className="w-6 aspect-square" />
            </a>
          )}
          {isWebShareSupported ? (
            <button onClick={handleShare} className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700">
              <img src="icon-share.svg" alt="share icon" className="w-6 aspect-square" />
            </button>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default VenueInfo;