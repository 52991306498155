import React from 'react';

interface NotificationProps {
  message: string;
  type: 'success' | 'error';
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, type, onClose }) => {
  // Gradient color inspired by the myVibe.live logo
  const gradientBackground =
    type === 'success'
      ? 'bg-gradient-to-r from-blue-500 to-purple-500'
      : 'bg-gradient-to-r from-red-500 to-pink-500';

  return (
    <div
      className={`fixed bottom-4 right-4 max-w-xs w-full p-4 rounded-md shadow-lg transition-transform duration-300 transform ${gradientBackground} text-white flex items-center justify-between`}
    >
      <span className="text-sm font-medium">{message}</span>
      <button
        onClick={onClose}
        className="ml-4 bg-white bg-opacity-25 hover:bg-opacity-50 rounded-full h-8 w-8 flex items-center justify-center focus:outline-none"
      >
        &times;
      </button>
    </div>
  );
};

export default Notification;