import React, { useState, useEffect } from "react";
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchGenres, fetchSpotifyPlaylists } from "../lib/data";
import { Popover, PopoverTrigger, PopoverContent } from "./ui/popover";
import { Genre } from "../lib/definitions";
import AutoComplete from "./AutoComplete";
import { getLocation } from "../lib/location"; 
import { jwtDecode } from 'jwt-decode';

interface CustomJwtPayload {
  email: string;
  vibe: string;
}

interface FilterButtonsProps {
  selectedGenre: string | null;
  setSelectedGenre: React.Dispatch<React.SetStateAction<string | null>>;
  selectedGenreId: number | null;
  setSelectedGenreId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedDistance: string | null;
  setSelectedDistance: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTimePeriod: string | null;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<string | null>>;
  userLat: string | null;
  userLon: string | null;
  setUserLat: React.Dispatch<React.SetStateAction<string | null>>;
  setUserLon: React.Dispatch<React.SetStateAction<string | null>>;
  selectedPlaylistId: string | null;
  setSelectedPlaylistId: React.Dispatch<React.SetStateAction<string | null>>;
  selectedPlaylistName: string | null;
  setSelectedPlaylistName: React.Dispatch<React.SetStateAction<string | null>>;
  selectedVibe: string | null; 
  setSelectedVibe: React.Dispatch<React.SetStateAction<string | null>>;
  email: string | null; 
  setEmail: React.Dispatch<React.SetStateAction<string | null>>;   
}

const FilterButtons: React.FC<FilterButtonsProps> = ({
  selectedGenre,
  setSelectedGenre,
  selectedGenreId,
  setSelectedGenreId,  
  selectedDistance,
  setSelectedDistance,
  selectedTimePeriod,
  setSelectedTimePeriod,
  userLat,
  userLon,
  setUserLat,
  setUserLon,
  selectedPlaylistId,
  setSelectedPlaylistId,
  selectedPlaylistName, 
  setSelectedPlaylistName, 
  selectedVibe,
  setSelectedVibe,
  email,
  setEmail,
}) => {
  const { data: genres = [], error, isLoading } = useQuery<Genre[], Error>({
    queryKey: ['genres'],
    queryFn: fetchGenres,
  });

  const [playlists, setPlaylists] = useState<{ id: string; name: string }[]>([]);
  const [isGenrePopoverOpen, setIsGenrePopoverOpen] = useState<boolean>(false);
  const [isDistancePopoverOpen, setIsDistancePopoverOpen] = useState<boolean>(false);
  const [isTimePeriodPopoverOpen, setIsTimePeriodPopoverOpen] = useState<boolean>(false);
  const [locationInput, setLocationInput] = useState<string>('');
  const [locationError, setLocationError] = useState<string | null>(null);
  const [cityStateZip, setCityStateZip] = useState<string | null>(null);

  // Location and Navigation Hooks
  const location = useLocation();
  const navigate = useNavigate();

  // Fetch JWT Token from URL and set email
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (token) {
      sessionStorage.setItem('authToken', token); // Save token in sessionStorage
      try {
        const decoded = jwtDecode<CustomJwtPayload>(token);
        setEmail(decoded.email); // Set email from decoded JWT
        setSelectedVibe(decoded.vibe); // Set the vibe from decoded JWT
      } catch (error) {
        console.error("Failed to decode JWT:", error);
      }
    }
  }, [location]);

  // Restore vibe and email from sessionStorage/localStorage
  useEffect(() => {
    const storedVibe = localStorage.getItem('vibe');
    const storedEmail = localStorage.getItem('email');

    if (storedVibe) {
      setSelectedVibe(storedVibe);  // Set selectedVibe based on stored value
    }
  
    if (storedEmail) {
      setEmail(storedEmail); // Set email from sessionStorage/localStorage
    }
  }, []); // Runs once on component mount
  
  useEffect(() => {
    if (selectedVibe === "spotify" && email && playlists.length === 0) {
      fetchPlaylists(email); // Fetch playlists only if no playlists exist yet
    }
  }, [selectedVibe, email, playlists.length]); // Trigger fetch when vibe or email changes
  
  const handlePlaylistSelect = (playlistId: string, playlistName: string) => {
    // Save vibe and email to localStorage to persist across page reloads
    localStorage.setItem('vibe', 'spotify');
    localStorage.setItem('email', email || ''); // Save the email in localStorage
    
    setSelectedVibe("spotify");  // Set the vibe to 'spotify'
    setSelectedPlaylistId(playlistId);
    setSelectedPlaylistName(playlistName);
    setIsGenrePopoverOpen(false); // Close the popover
  };

  const fetchPlaylists = async (email: string) => {
    try {
      const playlists = await fetchSpotifyPlaylists(email);
  
      if (playlists && playlists.length > 0) {
        setPlaylists(playlists); // Set playlists in state
      } else {
        console.error("No playlists found in response");
      }
    } catch (error) {
      console.error("Failed to fetch Spotify playlists:", error);
    }
  };

  // Handle Vibe Selection
  const handleGenreSelect = (description: string | null, id: number | null) => {
    localStorage.setItem("vibe", "vibe");
    setSelectedVibe("vibe");
    setSelectedGenre(description);
    setSelectedGenreId(id);
    setIsGenrePopoverOpen(false);
  };

  const handleDistanceSelect = (distance: string) => {
    setSelectedDistance(distance);
    setIsDistancePopoverOpen(false);  // Close the popover
  };

  const handleTimePeriodSelect = (period: string) => {
    setSelectedTimePeriod(period);
    setIsTimePeriodPopoverOpen(false);  // Close the popover
  };

  const handleSpotifyRedirect = () => {
    localStorage.setItem("vibe", "spotify");
    // External redirect to Spotify authentication
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/api/auth/spotify?redirect=${encodeURIComponent('search')}`;
  };


  // Function to get the latitude and longitude of the input location using Google Geocoding API
  // ✅ Load saved location from localStorage when the component mounts
  useEffect(() => {
    const storedLat = localStorage.getItem("userLat");
    const storedLon = localStorage.getItem("userLon");
    const storedCityStateZip = localStorage.getItem("cityStateZip");

    if (storedLat && storedLon && storedCityStateZip) {
      setUserLat(storedLat);
      setUserLon(storedLon);
      setCityStateZip(storedCityStateZip);
      setLocationInput(storedCityStateZip);
    } else if (userLat && userLon) {
      getCityStateZip(userLat, userLon);
    }
  }, []);


  // ✅ Save location to localStorage
  const saveLocationToLocalStorage = (lat: string, lon: string, city: string, state: string, zip?: string) => {
    localStorage.setItem("userLat", lat);
    localStorage.setItem("userLon", lon);
    localStorage.setItem("cityStateZip", zip ? `${city}, ${state} ${zip}` : `${city}, ${state}`);
  };

  // ✅ Reverse Geocode: Get city, state, and ZIP from lat/lon
  const getCityStateZip = async (lat: string, lon: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
      );

      if (!response.ok) throw new Error("Failed to reverse geocode coordinates");

      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0]?.address_components || [];
        const city = addressComponents.find((comp: any) =>
          comp.types.includes("locality")
        )?.long_name || "";
        const state = addressComponents.find((comp: any) =>
          comp.types.includes("administrative_area_level_1")
        )?.short_name || "";
        const zip = addressComponents.find((comp: any) =>
          comp.types.includes("postal_code")
        )?.long_name || "";

        if (city && state) {
          const locationText = zip ? `${city}, ${state} ${zip}` : `${city}, ${state}`;
          setCityStateZip(locationText);
          setLocationInput(locationText);
          saveLocationToLocalStorage(lat, lon, city, state, zip);
        } else {
          setCityStateZip("Location not found");
        }
      } else {
        setCityStateZip("Location not found");
      }
    } catch (error) {
      setCityStateZip("Error retrieving location");
      console.error(error);
    }
  };

  // ✅ Get lat/lon from city, state, or ZIP input
  const handleLocationSubmit = async (city: string, state: string, zip?: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const query = zip ? zip : `${city}, ${state}`;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(query)}&key=${apiKey}`
      );

      if (!response.ok) throw new Error("Unable to find the location. Please try again.");

      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        const lat = location.lat.toString();
        const lon = location.lng.toString();

        setUserLat(lat);
        setUserLon(lon);
        setCityStateZip(zip ? `${city}, ${state} ${zip}` : `${city}, ${state}`);
        setLocationInput(zip ? `${city}, ${state} ${zip}` : `${city}, ${state}`);
        saveLocationToLocalStorage(lat, lon, city, state, zip);
      } else {
        setLocationError("Unable to find the location. Please try again.");
      }
    } catch (error) {
      setLocationError("An unknown error occurred.");
    }
  };

  const handleResetLocation = async () => {
    try {
      const { lat, lon, method } = await getLocation();
      setUserLat(lat);
      setUserLon(lon);
      localStorage.setItem("userLat", lat);
      localStorage.setItem("userLon", lon);
  
      console.log("Location reset using:", method);
  
      // Fetch new city/state and update dropdown
      await getCityStateZip(lat, lon);
    } catch (error) {
      console.error("Failed to reset location:", error);
    }
  };
  
  return (
    <div className="flex gap-4 items-start mt-4 w-full text-sm text-white">
      {/* Genre / Playlist Selection */}
      <Popover open={isGenrePopoverOpen} onOpenChange={setIsGenrePopoverOpen}>
        <PopoverTrigger className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700">
          {/* selectedVibe === "spotify" ? selectedPlaylistName || "Select a Playlist" : */} 
          {selectedGenre || "Vibe"}
        </PopoverTrigger>

        <PopoverContent className="w-[225px]">
          {/* {selectedVibe === "spotify" ? (
            playlists.length > 0 ? (
              <>
                // Back to Vibe Mode
                <div
                  onClick={() => {
                    setSelectedVibe("vibe");
                    setSelectedPlaylistId(null);
                    setSelectedPlaylistName(null);
                  }}
                  className="cursor-pointer text-blue-500 hover:underline mb-2"
                >
                  Search by Vibe
                </div>

                // List of Playlists
                {playlists.map((playlist) => (
                  <div
                    key={playlist.id}
                    onClick={() => handlePlaylistSelect(playlist.id, playlist.name)}
                    className="cursor-pointer"
                  >
                    {playlist.name || 'Unnamed Playlist'}
                  </div>
                ))}
              </>
            ) : isLoading ? (
              <div>Loading Playlists...</div>
            ) : (
              <div>No playlists found.</div>
            )
          ) : */ isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {(error as Error).message}</div>
          ) : (
            <>
              {/* Spotify Connect */}
              {/* <div
                onClick={handleSpotifyRedirect}
                className="cursor-pointer flex items-center gap-2 mb-2"
              >
                <img src="icon-spotify.png" alt="Spotify Logo" className="w-1/2 h-auto" />
              </div> */}

              {/* All Vibes Option */}
              <div key={0} onClick={() => handleGenreSelect("All Vibes", null)} className="cursor-pointer">
                {"All Vibes"}
              </div>

              {/* List of Genres */}
              {genres.map((genre) => (
                <div key={genre.genre_id} onClick={() => handleGenreSelect(genre.description ?? null, genre.genre_id ?? null)} className="cursor-pointer">
                  {genre.description}
                </div>
              ))}
            </>
          )}
        </PopoverContent>
      </Popover>
      
      {/* Distance Selection */}
      <Popover open={isDistancePopoverOpen} onOpenChange={setIsDistancePopoverOpen}>
        <PopoverTrigger 
          className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700"
        >
          {selectedDistance ?? "Within 100 Miles"}
        </PopoverTrigger>
        <PopoverContent className="w-[200px]">
          {["Within 10 Miles", "Within 25 Miles", "Within 50 Miles", "Within 100 Miles"].map((distance) => (
            <div 
              key={distance} 
              onClick={() => handleDistanceSelect(distance)}
              className="cursor-pointer"
            >
              {distance}
            </div>
          ))}

          {/* Line separator */}
          <hr className="my-2 border-t border-gray-700" />

          {/* Current Location */}
          <div className="flex flex-col gap-2">
            <p className="text-gray-400">Current Location: {cityStateZip ?? 'Fetching location...'}</p>
          </div>

          {/* Location Input */}
          <div className="flex flex-col gap-2">
            <AutoComplete
              value={locationInput}
              setValue={setLocationInput}
              setLocation={(city, state, zip) => handleLocationSubmit(city, state, zip)} // ✅ Support ZIP input
            />
            {locationError && <p className="text-red-500">{locationError}</p>}
          </div>
          <button
            onClick={handleResetLocation}
            className="px-4 py-2 mt-4 rounded-md bg-zinc-950 text-white border border-zinc-700"
          >
            Reset Location
          </button>
        </PopoverContent>
      </Popover>

      {/* Time Period Selection */}
      <Popover open={isTimePeriodPopoverOpen} onOpenChange={setIsTimePeriodPopoverOpen}>
        <PopoverTrigger 
          className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700"
        >
          {selectedTimePeriod ?? "Next 30 Days"}
        </PopoverTrigger>
        <PopoverContent className="w-[150px]">
          {["Today", "Tomorrow", "Next 7 Days", "Next 30 Days", "Next 90 Days"].map((period) => (
            <div 
              key={period} 
              onClick={() => handleTimePeriodSelect(period)}
              className="cursor-pointer"
            >
              {period}
            </div>
          ))}
        </PopoverContent>
      </Popover>           
    </div>
  );
};

export default FilterButtons;