import React from "react";

interface ItineraryAddShowProps {
  onClick: () => void;
}

export const ItineraryAddShow: React.FC<ItineraryAddShowProps> = ({ onClick }) => {
  return (
    <article className="flex justify-between items-center p-2 rounded-lg border border-solid border-neutral-400">
      <p className="text-base font-medium text-zinc-950">No Show</p>
      <button
        className="p-2 text-sm font-medium rounded-md border border-solid cursor-pointer border-zinc-700 text-zinc-950"
        onClick={onClick} // Handle show addition
      >
        Add Show
      </button>
    </article>
  );
};