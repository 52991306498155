import React, { useEffect, useState, useRef } from "react";
import { addNotification } from "../lib/data";
import AutoComplete from "./AutoComplete";
import { getLocation } from "../lib/location";

interface NotificationArtistProps {
  artist_id: number;
  artist_name: string;
  closeModal: () => void;
}

const NotificationArtist: React.FC<NotificationArtistProps> = ({
  artist_id,
  artist_name,
  closeModal,
}) => {
  const [email, setEmail] = useState("");
  const [distance, setDistance] = useState("10");
  const [cityState, setCityState] = useState<string>("Determining location...");
  const [agree, setAgree] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [userLat, setUserLat] = useState<string | null>(null);
  const [userLon, setUserLon] = useState<string | null>(null);
  const [locationInput, setLocationInput] = useState<string>("");
  const [locationError, setLocationError] = useState<string | null>(null);
  const formRef = useRef<HTMLFormElement>(null);

  // Load saved location from localStorage when the component mounts
  useEffect(() => {
    const storedLat = localStorage.getItem("userLat");
    const storedLon = localStorage.getItem("userLon");
    const storedCityState = localStorage.getItem("cityState");

    if (storedLat && storedLon && storedCityState) {
      setUserLat(storedLat);
      setUserLon(storedLon);
      setCityState(storedCityState);
      setLocationInput(storedCityState);
    } else {
      getLocation();
    }
  }, []);

  // Save location to localStorage
  const saveLocationToLocalStorage = (lat: string, lon: string, city: string, state: string) => {
    localStorage.setItem("userLat", lat);
    localStorage.setItem("userLon", lon);
    localStorage.setItem("cityState", `${city}, ${state}`);
  };

  // Update location state & save it
  const updateLocation = (lat: number, lon: number) => {
    const latStr = lat.toString();
    const lonStr = lon.toString();
    setUserLat(latStr);
    setUserLon(lonStr);
    getCityState(latStr, lonStr);
  };

  const handleResetLocation = async () => {
    try {
      const { lat, lon, method } = await getLocation();
      setUserLat(lat);
      setUserLon(lon);
      localStorage.setItem("userLat", lat);
      localStorage.setItem("userLon", lon);
  
      console.log("Location reset using:", method);
  
      // Fetch new city/state and update dropdown
      await getCityState(lat, lon);
    } catch (error) {
      console.error("Failed to reset location:", error);
    }
  };

  const getCityState = async (lat: string, lon: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
      );

      if (!response.ok) throw new Error("Failed to reverse geocode coordinates");

      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0]?.address_components || [];
        const city = addressComponents.find((comp: any) =>
          comp.types.includes("locality")
        )?.long_name || "";
        const state = addressComponents.find((comp: any) =>
          comp.types.includes("administrative_area_level_1")
        )?.short_name || "";

        if (city && state) {
          setCityState(`${city}, ${state}`);
          setLocationInput(`${city}, ${state}`);
          saveLocationToLocalStorage(lat, lon, city, state);
        } else {
          setCityState("Location not found");
        }
      } else {
        setCityState("Location not found");
      }
    } catch (error) {
      setCityState("Error retrieving city and state");
      console.error(error);
    }
  };

  // ✅ Handle manually entered location and persist it
  const handleLocationSubmit = async (city: string, state: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${city}, ${state}`)}&key=${apiKey}`
      );

      if (!response.ok) throw new Error("Unable to find the location. Please try again.");

      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        const lat = location.lat.toString();
        const lon = location.lng.toString();

        setUserLat(lat);
        setUserLon(lon);
        setCityState(`${city}, ${state}`);
        setLocationInput(`${city}, ${state}`);
        saveLocationToLocalStorage(lat, lon, city, state);
      } else {
        setLocationError("Unable to find the location. Please try again.");
      }
    } catch (error) {
      setLocationError("An unknown error occurred.");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!email || !agree) {
      setError("Please provide a valid email and agree to the terms.");
      return;
    }

    try {
      await addNotification({
        email,
        contact_method: "email",
        notification_type: "artist",
        notification_type_id: artist_id,
        location_lat: userLat || undefined,
        location_lon: userLon || undefined,
        distance: parseInt(distance),
      });

      setSuccess(true);
      setTimeout(closeModal, 3000);
    } catch (error) {
      console.error("Failed to submit notification:", error);
      setError("Something went wrong. Please try again later.");
    }
  };

  return (
    <div className="flex overflow-hidden relative flex-col items-center py-8 pr-5 pl-5 rounded-2xl border border-solid bg-zinc-950 border-zinc-700 max-w-[809px] max-md:pl-5">
      <img
        loading="lazy"
        src="close-window.png"
        alt="Close"
        className="object-contain absolute top-2 right-2 z-10 w-6 h-6 cursor-pointer"
        onClick={closeModal}
      />
      {success ? (
        <div className="text-white text-lg font-medium mt-4 text-center w-full max-w-xs mx-auto">
          You're signed up to receive notifications from {artist_name}.
        </div>
      ) : (
        <>
          <div className="flex z-0 flex-col w-full font-bold text-center text-white">
            <div className="text-3xl">Sign up for new show alerts for</div>
            <div className="mt-6 text-5xl">{artist_name}</div>
          </div>
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="flex z-0 flex-col items-center mt-8 max-w-full w-[560px]"
          >
            <div className="flex flex-col w-full gap-4">
            {/* Distance Selection */}
            <div className="flex w-full gap-4 items-center">
                <div className="flex flex-col w-1/4">
                <label
                    htmlFor="distance"
                    className="flex flex-col self-stretch w-full text-xs font-semibold whitespace-nowrap text-neutral-400 max-md:max-w-full"
                >
                    Within
                </label>
                <select
                    id="distance"
                    value={distance}
                    onChange={(e) => setDistance(e.target.value)}
                    className="px-2 py-2 rounded-md bg-zinc-700 text-white focus:ring-2 focus:ring-zinc-500"
                >
                    <option value="10">10 mi</option>
                    <option value="25">25 mi</option>
                    <option value="50">50 mi</option>
                    <option value="100">100 mi</option>
                </select>
                </div>
                {/* City, State */}
                <div className="flex flex-col flex-grow">
                <label
                    htmlFor="cityState"
                    className="flex flex-col self-stretch w-full text-xs font-semibold whitespace-nowrap text-neutral-400 max-md:max-w-full"
                >
                    Location
                </label>
                <AutoComplete
                  value={locationInput}
                  setValue={setLocationInput} // ✅ Pass setValue to update input field
                  setLocation={(city, state) => handleLocationSubmit(city, state)}
                />
                {locationError && <p className="mt-2 text-red-500">{locationError}</p>}
                </div>
               
            </div>

            <div className="flex justify-center mt-4">
              <button
                onClick={handleResetLocation}
                className="px-4 py-2 w-1/2 rounded-md bg-zinc-950 text-white border border-zinc-700"
              >
                Reset Location
              </button>
            </div>

            </div>
            {/* Email Input */}
            <div className="flex flex-col w-full mt-4">
              <label
                htmlFor="email"
                className="lex flex-col self-stretch w-full text-xs font-semibold whitespace-nowrap text-neutral-400 max-md:max-w-full"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="px-4 py-2 rounded-md bg-zinc-700 text-white border-none"
              />
            </div>
            {/* Agreement Checkbox */}
            <div className="flex items-center mt-4">
              <input
                id="agree"
                type="checkbox"
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="agree" className="text-sm text-white">
                I agree to receive email notifications from myVibe.live
              </label>
            </div>
            {error && <p className="text-red-500 mt-2">{error}</p>}
            {/* Submit Button */}
            <button
              type="submit"
              className="mt-6 px-6 py-4 rounded-md bg-zinc-950 text-white border border-zinc-700"
            >
              Sign Up
            </button>
          </form>


        </>
      )}
    </div>
  );
};

export default NotificationArtist;