import React, { useState, useEffect } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./ui/popover";
import { useQuery } from '@tanstack/react-query';
import { fetchGenres, fetchSpotifyPlaylists } from "../lib/data";
import { Genre } from "../lib/definitions";
import AutoComplete from "./AutoComplete";
import { getLocation } from "../lib/location"; 

interface FilterButtonsProps {
  selectedGenre: string | null;
  setSelectedGenre: React.Dispatch<React.SetStateAction<string | null>>;
  selectedGenreId: number | null;
  setSelectedGenreId: React.Dispatch<React.SetStateAction<number | null>>;
  selectedDistance: string | null;
  setSelectedDistance: React.Dispatch<React.SetStateAction<string | null>>;
  selectedTimePeriod: string | null;
  setSelectedTimePeriod: React.Dispatch<React.SetStateAction<string | null>>;
  userLat: string | null;
  userLon: string | null;
  setUserLat: React.Dispatch<React.SetStateAction<string | null>>;
  setUserLon: React.Dispatch<React.SetStateAction<string | null>>;
  selectedPlaylistId: string | null; // Added for playlist selection
  setSelectedPlaylistId: React.Dispatch<React.SetStateAction<string | null>>; // Setter for playlist selection
  selectedPlaylistName: string | null; // Added for playlist name display
  setSelectedPlaylistName: React.Dispatch<React.SetStateAction<string | null>>; // Setter for playlist name
  selectedVibe: string | null; 
  setSelectedVibe: React.Dispatch<React.SetStateAction<string | null>>;  
}

const FilterButtons: React.FC<FilterButtonsProps> = ({
  selectedGenre,
  setSelectedGenre,
  selectedGenreId,
  setSelectedGenreId,  
  selectedDistance,
  setSelectedDistance,
  selectedTimePeriod,
  setSelectedTimePeriod,
  userLat,
  userLon,
  setUserLat,
  setUserLon,
  selectedPlaylistId,
  setSelectedPlaylistId,
  selectedPlaylistName, 
  setSelectedPlaylistName, 
  selectedVibe,
  setSelectedVibe,
}) => {
  const { data: genres = [], error, isLoading } = useQuery<Genre[], Error>({
    queryKey: ['genres'],
    queryFn: fetchGenres,
  });

  const [playlists, setPlaylists] = useState<{ id: string; name: string }[]>([]);
  const [isSpotifyMode, setIsSpotifyMode] = useState(false);
  const [isGenrePopoverOpen, setIsGenrePopoverOpen] = useState<boolean>(false);
  const [isDistancePopoverOpen, setIsDistancePopoverOpen] = useState<boolean>(false);
  const [isTimePeriodPopoverOpen, setIsTimePeriodPopoverOpen] = useState<boolean>(false);
  const [locationInput, setLocationInput] = useState<string>('');
  const [locationError, setLocationError] = useState<string | null>(null);
  const [cityState, setCityState] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);

  // Handle Vibe Selection
  const handleGenreSelect = (description: string | null, id: number | null) => {
    sessionStorage.setItem("vibe", "vibe");
    setSelectedVibe("vibe");
    setSelectedGenre(description);
    setSelectedGenreId(id);
    setIsGenrePopoverOpen(false);
  };

  // Handle Spotify Playlist Selection
  const handlePlaylistSelect = (playlistId: string, playlistName: string) => {
    sessionStorage.setItem("vibe", "spotify");
    setSelectedVibe("spotify");
    setSelectedPlaylistId(playlistId);
    setSelectedPlaylistName(playlistName);
    setIsGenrePopoverOpen(false);
  };

  const handleDistanceSelect = (distance: string) => {
    setSelectedDistance(distance);
    setIsDistancePopoverOpen(false);  // Close the popover
  };

  const handleTimePeriodSelect = (period: string) => {
    setSelectedTimePeriod(period);
    setIsTimePeriodPopoverOpen(false);  // Close the popover
  };


  // Function to get the latitude and longitude of the input location using Google Geocoding API
  // ✅ Load saved location from localStorage when the component mounts
  useEffect(() => {
    const storedLat = localStorage.getItem("userLat");
    const storedLon = localStorage.getItem("userLon");
    const storedCityState = localStorage.getItem("cityState");

    if (storedLat && storedLon && storedCityState) {
      setUserLat(storedLat);
      setUserLon(storedLon);
      setCityState(storedCityState);
      setLocationInput(storedCityState);
    } else if (userLat && userLon) {
      getCityState(userLat, userLon);
    }
  }, []);

  // ✅ Save location to localStorage
  const saveLocationToLocalStorage = (lat: string, lon: string, city: string, state: string) => {
    localStorage.setItem("userLat", lat);
    localStorage.setItem("userLon", lon);
    localStorage.setItem("cityState", `${city}, ${state}`);
  };

  const getCityState = async (lat: string, lon: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${apiKey}`
      );
  
      if (!response.ok) throw new Error("Failed to reverse geocode coordinates");
  
      const data = await response.json();
      if (data.status === "OK") {
        const addressComponents = data.results[0]?.address_components || [];
        const city = addressComponents.find((comp: any) =>
          comp.types.includes("locality")
        )?.long_name || "";
        const state = addressComponents.find((comp: any) =>
          comp.types.includes("administrative_area_level_1")
        )?.short_name || "";
  
        if (city && state) {
          const newLocation = `${city}, ${state}`;
          setCityState(newLocation);
          setLocationInput(newLocation); // 🔹 Ensure dropdown updates
          saveLocationToLocalStorage(lat, lon, city, state);
        } else {
          setCityState("Location not found");
        }
      } else {
        setCityState("Location not found");
      }
    } catch (error) {
      setCityState("Error retrieving city and state");
      console.error(error);
    }
  };

  const handleLocationSubmit = async (city: string, state: string) => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_GEOLOCATION_KEY;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(`${city}, ${state}`)}&key=${apiKey}`
      );

      if (!response.ok) throw new Error("Unable to find the location. Please try again.");

      const data = await response.json();
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        const lat = location.lat.toString();
        const lon = location.lng.toString();

        setUserLat(lat);
        setUserLon(lon);
        setCityState(`${city}, ${state}`);
        setLocationInput(`${city}, ${state}`);
        saveLocationToLocalStorage(lat, lon, city, state);
      } else {
        setLocationError("Unable to find the location. Please try again.");
      }
    } catch (error) {
      setLocationError("An unknown error occurred.");
    }
  };

  const handleResetLocation = async () => {
    try {
      const { lat, lon, method } = await getLocation();
      setUserLat(lat);
      setUserLon(lon);
      localStorage.setItem("userLat", lat);
      localStorage.setItem("userLon", lon);
  
      console.log("Location reset using:", method);
  
      // Fetch new city/state and update dropdown
      await getCityState(lat, lon);
    } catch (error) {
      console.error("Failed to reset location:", error);
    }
  };

  // Fetch Spotify Playlists
  const fetchPlaylists = async (email?: string | null) => {
    if (!email) return;
    try {
      const fetchedPlaylists = await fetchSpotifyPlaylists(email);
      setPlaylists(fetchedPlaylists);
    } catch (error) {
      console.error("Failed to fetch Spotify playlists:", error);
    }
  };

  // Fetch User Session Data (Email & Vibe) from API
  /*useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await fetchSessionUser();
        if (data?.email) {
          sessionStorage.setItem("email", data.email);
          sessionStorage.setItem("vibe", data.vibe || ""); // Default to empty if null
          setEmail(data.email);
          setSelectedVibe(data.vibe);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchUserData();
  }, []);

  // Restore Session for Vibe Selection & Fetch Playlists
  useEffect(() => {
    const storedVibe = sessionStorage.getItem("vibe");
    if (storedVibe === "spotify" && email) {
      setSelectedVibe("spotify");
      fetchPlaylists(email);
    } else {
      setSelectedVibe("vibe");
    }
  }, [email]);*/


  return (
    <div className="flex gap-4 items-start mt-4 w-full text-sm text-white">
      {/* Genre / Playlist Selection */}
      <Popover open={isGenrePopoverOpen} onOpenChange={setIsGenrePopoverOpen}>
        <PopoverTrigger className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700">
          {selectedVibe === "spotify"
            ? selectedPlaylistName || "Select a Playlist"
            : selectedGenre || "Vibe"}
        </PopoverTrigger>

        <PopoverContent className="w-[225px]">
          {selectedVibe === "spotify" ? (
            playlists.length > 0 ? (
              <>
                {/* Back to Vibe Mode */}
                <div
                  onClick={() => {
                    setSelectedVibe("vibe");
                    setIsSpotifyMode(false);
                    setSelectedPlaylistId(null);
                    setSelectedPlaylistName(null);
                  }}
                  className="cursor-pointer text-blue-500 hover:underline mb-2"
                >
                  Search by Vibe
                </div>

                {/* List of Playlists */}
                {playlists.map((playlist) => (
                  <div
                    key={playlist.id}
                    onClick={() => handlePlaylistSelect(playlist.id, playlist.name)}
                    className="cursor-pointer"
                  >
                    {playlist.name}
                  </div>
                ))}
              </>
            ) : (
              <div>Loading Playlists...</div>
            )
          ) : isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {(error as Error).message}</div>
          ) : (
            <>
              {/* Spotify Connect */}
              {/*<div
                onClick={() => {
                  setSelectedVibe("spotify");
                  setIsSpotifyMode(true);

                  // Fetch Spotify playlists without page refresh
                  fetchPlaylists(email);

                  // Redirect to Spotify login if no playlists found
                  if (playlists.length === 0) {
                    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/api/auth/spotify`;
                  }
                }}
                className="cursor-pointer flex items-center gap-2 mb-2"
              >
                <img src="icon-spotify.png" alt="Spotify Logo" className="w-1/2 h-auto" />
              </div>*/}

              {/* All Vibes Option */}
              <div
                key={0}
                onClick={() => handleGenreSelect("All Vibes", null)}
                className="cursor-pointer"
              >
                {"All Vibes"}
              </div>

              {/* List of Genres */}
              {genres.map((genre) => (
                <div
                  key={genre.genre_id}
                  onClick={() => handleGenreSelect(genre.description ?? null, genre.genre_id ?? null)}
                  className="cursor-pointer"
                >
                  {genre.description}
                </div>
              ))}
            </>
          )}
        </PopoverContent>
      </Popover>

      {/* Distance Selection */}
      <Popover open={isDistancePopoverOpen} onOpenChange={setIsDistancePopoverOpen}>
        <PopoverTrigger 
          className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700"
        >
          {selectedDistance ?? "Within 100 Miles"}
        </PopoverTrigger>
        <PopoverContent className="w-[200px]">
          {["Within 10 Miles", "Within 25 Miles", "Within 50 Miles", "Within 100 Miles"].map((distance) => (
            <div 
              key={distance} 
              onClick={() => handleDistanceSelect(distance)}
              className="cursor-pointer"
            >
              {distance}
            </div>
          ))}

          {/* Line separator */}
          <hr className="my-2 border-t border-gray-700" />

          {/* Current Location */}
          <div className="flex flex-col gap-2">
            <p className="text-gray-400">Current Location: {cityState ?? 'Fetching location...'}</p>
          </div>

          {/* Location Input */}
          <div className="flex flex-col gap-2">
            <AutoComplete
              value={locationInput}
              setValue={setLocationInput}
              setLocation={(city, state) => handleLocationSubmit(city, state)}
            />
            {locationError && <p className="text-red-500">{locationError}</p>}
          </div>
          <button
            onClick={handleResetLocation}
            className="px-4 py-2 mt-4 rounded-md bg-zinc-950 text-white border border-zinc-700"
          >
            Reset Location
          </button>
        </PopoverContent>
      </Popover>

      {/* Time Period Selection */}
      <Popover open={isTimePeriodPopoverOpen} onOpenChange={setIsTimePeriodPopoverOpen}>
        <PopoverTrigger 
          className="flex-1 shrink gap-2 self-stretch p-2 rounded-md border border-solid border-zinc-700"
        >
          {selectedTimePeriod ?? "Next 30 Days"}
        </PopoverTrigger>
        <PopoverContent className="w-[150px]">
          {["Today", "Tomorrow", "Next 7 Days", "Next 30 Days", "Next 90 Days"].map((period) => (
            <div 
              key={period} 
              onClick={() => handleTimePeriodSelect(period)}
              className="cursor-pointer"
            >
              {period}
            </div>
          ))}
        </PopoverContent>
      </Popover>           
    </div>
  );
};

export default FilterButtons;