import React, { useState } from "react";
import { Venue } from "../lib/definitions";
import { logEvent } from "../lib/data";
import NotificationVenue from "./NotificationVenue"; // Import the NotificationVenue component

interface VenueInfoProps {
  venue: Venue;
}

const VenueInfo: React.FC<VenueInfoProps> = ({ venue }) => {
  const [showModal, setShowModal] = useState(false); // State to handle modal visibility

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out this venue!",
          text: `Have a look at ${venue.name}!`,
          url: window.location.href,
        });
        logEvent(null, "Share", venue.venue_id || 0, "Venue");
      } catch (error) {
        console.error("Sharing failed", error);
      }
    } else {
      console.warn("Web Share API is not supported in this browser.");
    }
  };

  const handleLinkClick = async (url: string, linkType: string) => {
    logEvent(null, "Click", venue.venue_id || 0, "Venue", linkType);
    window.open(url, "_blank", "noopener noreferrer");
  };

  const isWebShareSupported = typeof navigator.share === "function";

  return (
    <section className="w-full flex flex-col bg-white">
      <div className="flex flex-col px-4 py-6 bg-zinc-950">
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center gap-2">
            <h2
              className="flex-1 text-3xl font-bold tracking-tighter text-white"
              style={{ fontFamily: "League Spartan, sans-serif" }}
            >
              {venue.name}
            </h2>
          </div>
          <p className="mt-1 text-base font-semibold text-zinc-400">
            {venue.addr_street},&nbsp;{venue.addr_city},&nbsp;{venue.addr_state}
          </p>
        </div>
        {venue.image_full && (
          <img
            src={venue.image_full}
            alt={venue.name}
            className="w-full aspect-[1.46] rounded-2xl object-contain"
          />
        )}
        <p className="mt-6 text-base font-medium leading-6 text-neutral-400">
          <div dangerouslySetInnerHTML={{ __html: venue.description ?? "" }} />
        </p>
        <div className="flex gap-4 items-start mt-6 w-full text-sm font-medium leading-6 text-center text-white whitespace-nowrap">
        {venue.fb_link && (
          <button
            onClick={() => handleLinkClick(venue.fb_link || "", "Facebook")}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-facebook.svg" alt="Facebook" className="w-6 aspect-square" />
          </button>
        )}
        {venue.ig_link && (
          <button
            onClick={() => handleLinkClick(venue.ig_link || "", "Instagram")}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-instagram.svg" alt="Instagram" className="w-6 aspect-square" />
          </button>
        )}
        {venue.twitter_link && (
          <button
            onClick={() => handleLinkClick(venue.twitter_link || "", "Twitter")}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-twitter.svg" alt="Twitter" className="w-6 aspect-square" />
          </button>
        )}
        {venue.tiktok_link && (
          <button
            onClick={() => handleLinkClick(venue.tiktok_link || "", "TikTok")}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-tiktok.svg" alt="TikTok" className="w-6 aspect-square" />
          </button>
        )}
        {venue.yt_link && (
          <button
            onClick={() => handleLinkClick(venue.yt_link || "", "YouTube")}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-youtube.svg" alt="YouTube" className="w-6 aspect-square" />
          </button>
        )}
        {venue.official_link && (
          <button
            onClick={() => handleLinkClick(venue.official_link || "", "Official")}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-website.svg" alt="Website" className="w-6 aspect-square" />
          </button>
        )}
        {venue.merch_link && (
          <button
            onClick={() => handleLinkClick(venue.merch_link || "", "Merch")}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-merch.svg" alt="Merch" className="w-6 aspect-square" />
          </button>
        )}
          {isWebShareSupported && (
            <button
              onClick={handleShare}
              className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
            >
              <img src="icon-share.svg" alt="Share" className="w-6 aspect-square" />
            </button>
          )}

          {/* New Button for NotificationVenue Modal */}
          <button
            onClick={() => setShowModal(true)}
            className="flex justify-center items-center p-2 rounded-md border border-solid border-zinc-700"
          >
            <img src="icon-notification.png" alt="Notifications" className="w-6 aspect-square" />
          </button>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-zinc-950 bg-opacity-50 flex justify-center items-center z-50"
        style={{ backdropFilter: "blur(5px)" }}
        >
          {/* Modal Content */}
          <NotificationVenue
            venue_id={venue.venue_id || 0}
            venue_name={venue.name || ""}
            closeModal={() => setShowModal(false)} // Pass the function to close the modal
          />
        </div>
      )}
    </section>
  );
};

export default VenueInfo;